import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosapi from "../utilities/axiosApi";

export const fetchCustomers = createAsyncThunk(
  "customer/fetchCustomers",
  async ({ offset, limit, search, sort_by_key, sort_by_value }, { rejectWithValue }) => {
    try {
      const response = await axiosapi.get("/api/reseller/customers", {
        params: { offset, limit, search, sort_by_key, sort_by_value },
      });
      return {
        data: response.data.data.data,
        total: response.data.data.total,
        currentPages: response.data.data.current_page,
        totalPages: response.data.data.total_pages,
      };
    } catch (err) {
      console.error("GET API Error:", err); 
      return rejectWithValue(err.response.data);
    }
  }
);

export const addCreditCustomer = createAsyncThunk(
  'reseller/addCreditCustomer',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosapi.post(`/api/reseller/customer-credit`, data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchCustomerCreditDetail = createAsyncThunk(
  "reseller/fetchCustomerCreditDetail",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axiosapi.get("/api/reseller/customer-credit-details/" + id);
      return response.data.data;
    }
    catch (err) {
      console.error("GET API Error:", err);
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchCustomerDetail = createAsyncThunk(
  "reseller/fetchCustomerDetail",
  async (id, {rejectWithValue}) => {
    try {
      const response = await axiosapi.get("/api/reseller/customer/" + id);
      return response.data.data;
    }
    catch (err) {
      console.error("GET API Error:", err);
      return rejectWithValue(err.response.data);
    }
  }
)

export const fetchCustomerCredits = createAsyncThunk(
  "customer/fetchCustomerCredits",
  async ({ id, offset, limit, search, sort_by_key, sort_by_value }, { rejectWithValue }) => {
    try {
      const response = await axiosapi.get("/api/reseller/customer-credits/" + id, {
        params: { id, offset, limit, search, sort_by_key, sort_by_value },
      });      
      return {
        data: response.data.data.data,
        total: response.data.data.total,
        currentPages: response.data.data.current_page,
        totalPages: response.data.data.total_pages,
      };
    }
    catch (err) {
      console.error("GET API Error:", err);
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchCustomerEvents = createAsyncThunk(
  "customer/fetchCustomerEvents",
  async ({ id, offset, limit, search, sort_by_key, sort_by_value }, { rejectWithValue }) => {
    try {
      const response = await axiosapi.get("/api/reseller/customer-events/" + id, {
        params: { id, offset, limit, search, sort_by_key, sort_by_value },
      });

      return {
        data: response.data.data.data,
        total: response.data.data.total,
        currentPages: response.data.data.current_page,
        totalPages: response.data.data.total_pages,
      };
    }
    catch (err) {
      console.error("GET API Error:", err);
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchClientDetail = createAsyncThunk(
  "reseller/fetchClientDetail",
  async (id, {rejectWithValue}) => {
    try {
      const response = await axiosapi.get("/api/reseller/client-event/" + id);
      return response.data.data;
    }
    catch (err) {
      console.error("GET API Error:", err);
      return rejectWithValue(err.response.data);
    }
  }
)

export const fetchCampaignDetails = createAsyncThunk(
  "campaign/fetchCampaignDetails",
  async ({ id, offset, limit, search, sort_by_key, sort_by_value, status }, { rejectWithValue }) => {
    try {
      const response = await axiosapi.get("/api/reseller/customer-campaigns/" + id, {
        params: { id, offset, limit, search, sort_by_key, sort_by_value, status: status === "all"? "": status },
      });
      return {
        data: response.data.data.data,
        total: response.data.data.total,
        currentPages: response.data.data.current_page,
        totalPages: response.data.data.total_pages,
      };
    }
    catch (err) {
      console.error("GET API Error:", err);
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchCampaignDetail = createAsyncThunk(
  "campaign/fetchCampaignDetail",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axiosapi.get("/api/reseller/campaign/" + id);
      return response.data.data;
    }
    catch (err) {
      console.error("GET API Error:", err);
      return rejectWithValue(err.response.data);
    }
  }
)

export const fetchGuestDetails = createAsyncThunk( 
  "guest/fetchGuestDetails",
  async ({ id, offset, limit, search, sort_by_key, sort_by_value, is_test, status }, { rejectWithValue }) => {
    try {
      const response = await axiosapi.get("/api/reseller/campaign-guests/" + id, {
        params: { id, offset, limit, search, sort_by_key, sort_by_value, is_test, status },
      });
      return {
        data: response.data,
        total: response.data.data.total,
        currentPages: response.data.data.current_page,
        totalPages: response.data.data.total_pages,
      };
    }
    catch (err) {
      console.error("GET API Error:", err);
      return rejectWithValue(err.response.data);
    }
  }
);

export const addCustomer = createAsyncThunk(
  "customer/addCustomer",
  async (customerData, { rejectWithValue }) => {
    try {
      const response = await axiosapi.post("/api/reseller/customer", customerData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateCustomer = createAsyncThunk(
  "customer/updateCustomer",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosapi.post(`/api/reseller/customer/${data.get('id')}`, data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteCustomer = createAsyncThunk(
  "customer/deleteCustomer",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axiosapi.delete(`/api/reseller/customer/${id}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
    }
);

export const fetchCountry = createAsyncThunk(
  "reseller/fetchCountry",
  async () => {
    try {
      const response = await axiosapi.get("/api/countries");
      return response.data.data;
    } catch (err) {
      console.error("GET API Error:", err);
      return err.response.data;
    }
  }
);

export const fetchCountryByCode = createAsyncThunk(
  "reseller/fetchCountryByCode",
  async (countryCode, { rejectWithValue }) => {
    try {
      const response = await axiosapi.post("/api/countries-data", {
        search: countryCode
      });
      return response.data.data;
    } catch (err) {
      console.error("POST API Error:", err);
      return rejectWithValue(err.response.data);
    }
  }
);

export const toggleCustomerActiveStatus = createAsyncThunk(
  "reseller/toggleCustomerActiveStatus",
  async ({ id, isActive }, { rejectWithValue }) => {
    try {
      const response = await axiosapi.post(`/api/reseller/change-active-status/${id}`, {
        is_active: isActive
      });
      return response.data;  
    } catch (err) {
      console.error("POST API Error:", err);
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchCreditLogBooks = createAsyncThunk(
  "reseller/fetchCreditLogBooks",
  async ({ sort_by_key, offset, limit, sort_by_value, start_date, end_date }, { rejectWithValue }) => {
    try {
      const response = await axiosapi.get("/api/reseller/credit-logbooks", {
        params: { sort_by_key, offset, limit, sort_by_value, start_date, end_date },
      });
      return {
        data: response.data.data,
        total: response.data.data.total,
        currentPages: response.data.data.current_page,
        totalPages: response.data.data.total_pages
      };
    }
    catch (err) {
      console.error("GET API Error:", err);
      return rejectWithValue(err.response.data);
      }
    }
  )
  
export const fetchState = createAsyncThunk(
  "reseller/state",
  async ()=>{
    try {
      const response = await axiosapi.get("/api/states");
      return response.data.data;
    } catch (err) {
      console.error("GET API Error:", err);
      return err.response.data;
    }
  }
)

export const fetchCitiesByStateId = createAsyncThunk(
  "reseller/fetchCitiesByStateId",
  async (state_id, { rejectWithValue }) => {
    try {
      const response = await axiosapi.post("/api/cities", { state_id: state_id });
      return response.data.data;
    } catch (err) {
      console.error("POST API Error:", err);
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchDashboardData = createAsyncThunk(
  "reseller/fetchDashboardData",
  async ({sort_by_key},{ rejectWithValue }) => {
    try {
      const response = await axiosapi.get("/api/reseller/dashboard-counts", {
        params: { sort_by_key },
      });
      return {
        data: response.data.data,
        // total: response.data.data.total,
        // currentPages: response.data.data.current_page,
        // totalPages: response.data.data.total_pages
      };
    }
    catch (err) {
      console.error("GET API Error:", err);
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchCustomerCreditLogBooks = createAsyncThunk(
  "reseller/fetchCustomerCreditLogBooks",
  async ({ customer_id, offset, limit, sort_by_key, sort_by_value, start_date, end_date }, { rejectWithValue }) => {
    try {
      const response = await axiosapi.get("/api/reseller/customer-credit-logbooks", {
        params: { customer_id, offset, limit, sort_by_key, sort_by_value, start_date, end_date },
      });
      return {
        data: response.data.data,
        total: response.data.data.total,
        currentPages: response.data.data.current_page,
        totalPages: response.data.data.total_pages
      };
    }
    catch (err) {
      console.error("GET API Error:", err);
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchOrganization = createAsyncThunk(
  "reseller/fetchOrganization",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosapi.get("/api/reseller/organizations");
      return response.data;
    } catch (err) {
      console.error("GET API Error:", err);
      return rejectWithValue(err.response.data);
    }
  }
);

export const addCreditRequest = createAsyncThunk(
  'reseller/addCreditRequest',
  async (creditData, { rejectWithValue }) => {
    try {
      const response = await axiosapi.post('/api/reseller/credit-request', creditData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchRequestedCredits = createAsyncThunk(
  'reseller/fetchRequestedCredits',
  async ({ offset, limit, search, sort_by_key, sort_by_value, status }, { rejectWithValue }) => {
    try {
      const response = await axiosapi.get("/api/reseller/customer-credit", {
        params: { offset, limit, search, sort_by_key, sort_by_value, status: status },
      });
      
      return {
        data: response.data.data.data,
        total: response.data.data.total,
        currentPages: response.data.data.current_page,
        totalPages: response.data.data.total_pages,
      };
    } catch (err) {
      console.error("GET API Error:", err); 
      return rejectWithValue(err.response.data);
    }
  }
);

export const addCreditRequestAction= createAsyncThunk(
  "reseller/addCreditRequestAction",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosapi.post("/api/reseller/customer-credit-request", data);
      return response.data;
    } catch (err) {
      console.error("POST API Error:", err);
      return rejectWithValue(err.response.data);
    }
  }
);

const customerSlice = createSlice({
  name: "customer",
  initialState: {
    customers: [],
    countries: [],
    states: [],
    organizations: [],
    currentCustomer: {},
    customerEvents: [],
    customerCredits: [],
    currentClient:{},
    campaigns: [],
    currentCampaign:{},
    guests:[],
    creditCustomers:[],
    currentCreditDetail:{},
    creditLogBooks:{},
    customerCreditLogBooks:{},
    dashboardData: {},
    total: 0,
    page: 1,
    limit: 10,
    status: 'idle',
    error: null,
    totalPages: 0,
    requestedCredits: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCustomers.fulfilled, (state, action) => {
        state.customers = action.payload.data || []; 
        state.total = action.payload.total || 0; 
        state.page = action.payload.offset;
        state.limit = action.payload.limit;
        state.totalPages = action.payload.totalPages;
        state.search = action.payload.search;
      })
      .addCase(fetchCustomerDetail.fulfilled, (state, action) => {
        state.currentCustomer = action.payload;
      })
      .addCase(fetchCustomerEvents.fulfilled, (state, action) => {
        state.customerEvents = action.payload;
        state.total = action.payload.total || 0;
        state.page = action.payload.offset;
        state.limit = action.payload.limit;
        state.totalPages = action.payload.totalPages;
        state.search = action.payload.search;
      })
      .addCase(fetchCustomerCredits.fulfilled, (state, action) => {
        state.customerCredits = action.payload;
        state.total = action.payload.total || 0;
        state.page = action.payload.offset;
        state.limit = action.payload.limit;
        state.totalPages = action.payload.totalPages;
        state.search = action.payload.search;
      })
      .addCase(fetchClientDetail.fulfilled, (state, action) => {
        state.currentClient = action.payload;
      })
      .addCase(fetchCampaignDetails.fulfilled, (state, action) => {
        state.campaigns = action.payload;
        state.total = action.payload.total || 0;
        state.page = action.payload.offset;
        state.limit = action.payload.limit;
        state.totalPages = action.payload.totalPages;
        state.search = action.payload.search;
      })
      .addCase(fetchCampaignDetail.fulfilled, (state, action) => {
        state.currentCampaign = action.payload;
      })
      .addCase(fetchGuestDetails.fulfilled, (state, action) => {
        state.guests = action.payload.data.data;
        state.total = action.payload.total || 0;
        state.page = action.payload.offset;
        state.limit = action.payload.limit;
        state.totalPages = action.payload.totalPages;
        state.search = action.payload.search;
      })
      .addCase(fetchCustomerCreditDetail.fulfilled, (state, action) => {
        state.currentCreditDetail = action.payload;
      })
      .addCase(addCreditCustomer.fulfilled, (state, action) => {
        if (action.payload && action.payload.success) {
          state.creditCustomers.push(action.payload.data);
          state.total += 1;
        }
      })
       .addCase(toggleCustomerActiveStatus.fulfilled, (state, action) => {
        const index = state.customers.findIndex((customer) => customer.id === action.payload.id);
        if (index !== -1) {
          state.customers[index] = {...state.customers[index], ...action.payload};
        }
      })
      .addCase(addCustomer.fulfilled, (state, action) => {
        if (action.payload && action.payload.success){
          state.customers.push(action.payload);
          state.total += 1;
        }
      })      
      .addCase(updateCustomer.fulfilled, (state, action) => {
        const index = state.customers.findIndex((customer) => customer.id === action.payload.id);
        if (index !== -1) {
          state.customers[index] = {...state.customers[index], ...action.payload};
        }
      })
      .addCase(deleteCustomer.fulfilled, (state, action) => {
        state.customers = state.customers.filter(
          (r) => r.id !== action.payload.id
        );
        state.total -= 1;
      })
      .addCase(fetchCountry.fulfilled, (state, action) => {
        state.countries = action.payload;
      })
      .addCase(fetchCreditLogBooks.fulfilled, (state, action) => {
        state.creditLogBooks = action.payload.data;
      })
      .addCase(fetchCustomerCreditLogBooks.fulfilled, (state, action) => {
        state.customerCreditLogBooks = action.payload.data;
      })
      .addCase(fetchDashboardData.fulfilled, (state, action) => {
        state.dashboardData = action.payload.data;
      })   
      .addCase(fetchState.fulfilled, (state, action) => {
        state.states = action.payload;
      })  
      .addCase(fetchOrganization.fulfilled, (state, action) => {
        state.organizations = action.payload;
      })
      .addCase(addCreditRequest.fulfilled, (state, action) => {
        if (action.payload && action.payload.success) {
          state.creditCustomers.push(action.payload.data);
          state.total += 1; 
        }
      })
      .addCase(fetchRequestedCredits.fulfilled, (state, action) => {
        state.requestedCredits = action.payload;
      })
      .addCase(addCreditRequestAction.fulfilled, (state, action) => {
        state.requestedCredits = action.payload.data;
      });
  },
});

export default customerSlice.reducer;
