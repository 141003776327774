import React, { useState, useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button,Spinner,Tabs, Tab } from 'react-bootstrap';
import { Formik, Form, Field, ErrorMessage } from "formik";
import { updateCustomer, fetchCustomers, fetchState, fetchCitiesByStateId, fetchCustomerDetail } from "../../../store/customerSlice";
import { fetchAllOrganizationTypes } from "../../../store/organizationTypeSlice";
import { editCustomerSchema } from '../../../schema/validationSchemas';
import './style.css';
import moment from "moment";
// 
export default function Edit({ show, handleClose, customerId }) {
  const dispatch = useDispatch();
  const itemsPerPage = 10;
  const firstRender = useRef(true);
  const [key, setKey] = useState("organization");
  const [cities, setCities] = useState([]);
  const [isCityDropdownEnabled, setIsCityDropdownEnabled] = useState(false);

  const states = useSelector((state) => state.customer.states);
  const allOrganizationTypes = useSelector((state) => state.organizationType.allOrganizationTypes);

  const handleModalClose = useCallback(() => {
    handleClose();
    setKey("organization");
  }, [handleClose]);

  // const countries = useSelector(state => state.customer.countries);

  const [customer, setCustomer] = useState({
    organization_name: "",
    mobile_number: "",
    email: "",
    organization_id: "",
    city_id:"",
    state_id:"",
    expiry_date:"",

    name: "",
    username: "",
    is_active: true,
  });

  const customerDetails = useSelector((state) =>
    state.customer.customers.find((r) => r.id === customerId)
  );

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false; 
    } 
    else{
      dispatch(fetchState());
      dispatch(fetchAllOrganizationTypes());
    }
  }, [dispatch]);

//   const handleCountryCodeChange = async (code, setFieldValue) => {
//     const action = await dispatch(fetchCountryByCode(code));
//     if (action.payload && action.payload.length > 0) {
//         const country = action.payload.find(country => country.phone_code === code);
//         const countryId = country ? country.id : null;
//         if (countryId) {
//             setFieldValue("country_id", countryId);
//         } else {
//             setFieldValue("country_id", "");
//         }
//     } else {
//         setFieldValue("country_id", "");
//     }
// };

//   const handleCountryNameChange = (selectedCountryId, setFieldValue) => {
//     const country = countries.find(country => Number(country.id) === Number(selectedCountryId));
//     const phoneCode = country ? country?.phone_code : null;
//     if(phoneCode){
//       setFieldValue("country_code", phoneCode);
//     }
//   }

  useEffect(() => {
    if (show && customerId && customerDetails) {
      const updatedCustomer = {
        organization_name: customerDetails.organization_name || "",
        mobile_number: customerDetails.mobile_number || "",
        email: customerDetails.email || "",
        organization_id: customerDetails.organization_id || "",
        city_id: customerDetails.city_id || "",
        state_id: customerDetails.state_id || "",
        expiry_date: moment(customerDetails.expiry_date).format("YYYY-MM-DD") || "",
        is_active: customerDetails.is_active || false,

        name: customerDetails.name || "",
        username: customerDetails.username || "",

      };
      setCustomer(updatedCustomer);
    }
  }, [show, customerId, customerDetails]);

  const isOrganizationFormValid = (values, errors) => {
    const fields = [
    "organization_name",
    "mobile_number",
    "email",
    "organization_id",
    "city_id",
    "state_id",
    "expiry_date"
    ];
    return fields.every((field) => values[field] && !errors[field]);
  };

  const handleStateChange = async (stateId, setFieldValue) => {
    setFieldValue("state_id", stateId);
    setFieldValue("city_id", ""); 
    if (stateId) {
        try {
            const action = await dispatch(fetchCitiesByStateId(stateId));
            if (action.payload && action.payload.length > 0) {
                setCities(action.payload);
                setIsCityDropdownEnabled(true);
            } else {
                setCities([]);
                setIsCityDropdownEnabled(false);
            }
        } catch (error) {
            console.error("Failed to fetch cities:", error); 
            setCities([]);
            setIsCityDropdownEnabled(false);
        }
    } else {
        setCities([]);
        setIsCityDropdownEnabled(false);
    }
};



  useEffect(() => {
    if (customerDetails && customerDetails.state_id) {
      dispatch(fetchCitiesByStateId(customerDetails.state_id))
        .then(action => {
          if (action.payload) {
            setCities(action.payload);
            setIsCityDropdownEnabled(true); 
          }
        });
    }
  }, [dispatch, customerDetails]);

  return (
    <>
      <Modal show={show} onHide={handleModalClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Edit Organization</Modal.Title>
        </Modal.Header>
        <Formik
          enableReinitialize
          initialValues={customer}
          validationSchema={editCustomerSchema}
          onSubmit={async (values, { setSubmitting, resetForm, setErrors }) => {
            const formData = new FormData();
            formData.append('id', customerId);
            Object.keys(values).forEach(key => {
              if (key === "is_active") {
                formData.append(key, values[key] ? "true" : "false");
              }
              else {
                  formData.append(key, values[key] || '');
              }
            });

            formData.append('_method', 'PUT');

            dispatch(updateCustomer(formData))
            .then((res) => {
              if (res.payload.success) {
                dispatch(fetchCustomers({
                    offset: 0,
                    limit: itemsPerPage,
                    search: '',
                }));
                dispatch(fetchCustomerDetail(customerId));
                resetForm();
                handleClose();
                localStorage.setItem("toastMessage", res.payload.message || "Organization updated successfully");
                localStorage.setItem("toastVariant", "success");
            }
             else {
                  setErrors(res.payload.data);
                  localStorage.setItem("toastMessage", res.payload.message || "Failed to update reseller");
                  localStorage.setItem("toastVariant", "danger");
              }
          })
          .catch((error) => {
              console.error('Error:', error); 
              localStorage.setItem("toastMessage", "Failed to update reseller");
              localStorage.setItem("toastVariant", "danger");
          })
              .finally(() => {
                setSubmitting(false);
              });
          }}
        >
          {({ errors, touched, handleChange, setFieldValue, values, isSubmitting }) => (
            <Form encType="multipart/form-data">
              <Tabs
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3"
              >
                <Tab
                  eventKey="organization"
                  title="Organization"
                  tabClassName={
                    key === "organization" ? "navLinkActive" : "navLink"
                  }
                ></Tab>
                <Tab
                  eventKey="userDetails"
                  title="User Details"
                  disabled={!isOrganizationFormValid(values, errors)}
                  tabClassName={
                    key === "userDetails" ? "navLinkActive" : "navLink"
                  }
                ></Tab>
              </Tabs>
              <Modal.Body>
              {key === "organization" && (
                <>
                <label className="form-label"><b>Name</b></label>
                <Field
                      name="organization_name"
                      type="text"
                      className={`form-control ${
                        errors.organization_name && touched.organization_name
                          ? "is-invalid"
                          : ""
                      }`}
                      placeholder="Enter Organization Name"
                      onChange={handleChange}
                    />
                    {errors.organization_name && touched.organization_name ? (
                      <div className="invalid-feedback">
                        {errors.organization_name}
                      </div>
                    ) : null}
                    <br />
                
                    <div className="row">
                        <div className="col-md-5">
                        <label className="form-label"><b>State</b></label>
                        <Field
                          as="select"
                          name="state_id"
                          className={`form-control ${
                            errors.state_id && touched.state_id
                              ? "is-invalid"
                              : ""
                          }`}
                          onChange={e=>{
                            handleChange(e);
                            handleStateChange(e.target.value, setFieldValue)
                          }}
                        >
                          <option value="">Select State</option>
                          {states.map((state) => (
                            <option key={state?.id} value={state?.id}>
                              {state.name}
                            </option>
                          ))}
                        </Field>
                        </div>
                        <ErrorMessage component="div" className="text-danger" name="state_id" />

                        <div className="col-md-7">
                        <label className="form-label"><b>City</b></label>
                        <Field
                          as="select"
                          name="city_id"
                          className={`form-control ${
                            errors.city_id && touched?.city_id
                              ? "is-invalid"
                              : ""
                          }`}
                          disabled={!isCityDropdownEnabled} 
                        >
                          <option value="">Select City</option>
                          {cities.map((city) => (
                            <option key={city.id} value={city.id}>
                              {city.name}
                            </option>
                          ))}
                        </Field>

                    </div>
                    <ErrorMessage component="div" className="text-danger ml-6" name="city_id" style= {{marginLeft:"200px"}} />
                    </div>

                  <label className="form-label mt-4"><b>Mobile Number</b></label>
                  <Field
                      name="mobile_number"
                      type="text"
                      className={`form-control ${
                        errors.mobile_number && touched.mobile_number
                          ? "is-invalid"
                          : ""
                      }`}
                      placeholder="Enter mobile number"
                      onChange={handleChange}
                    />
                    {errors.mobile_number && touched.mobile_number ? (
                      <div className="invalid-feedback">
                        {errors.mobile_number}
                      </div>
                    ) : null}
                    <br />

                    <label className="form-label"><b>Email</b></label>
                    <Field
                      name="email"
                      type="email"
                      className={`form-control ${
                        errors.email && touched.email ? "is-invalid" : ""
                      }`}
                      placeholder="Enter email"
                      onChange={handleChange}
                    />
                    {errors.email && touched.email ? (
                      <div className="invalid-feedback">{errors.email}</div>
                    ) : null}
                    <br />
                    
                    <label className="form-label"><b>Organization Type</b></label>
                        <Field
                          as="select"
                          name="organization_id"
                          className={`form-control ${
                            errors.organization_id && touched.organization_id ? "is-invalid" : ""
                          }`}
                          onChange={handleChange}
                        >
                          <option value="">Select Organization</option>
                          {allOrganizationTypes.map((orgType) => (
                            <option key={orgType.id} value={orgType.id}>
                              {orgType.name}
                            </option>
                          ))}
                        </Field>
                      <ErrorMessage component="div" className="text-danger" name="organization_id" />
                    
                      <label className="form-label mt-5"><b>Expiry Date</b></label>
                      <Field
                            type="date"
                            id="expiry-date"
                            onChange={handleChange}
                            name="expiry_date"
                            min={moment().add(1, 'days').format("YYYY-MM-DD")} 
                            className={`form-control`}
                        />
                      <ErrorMessage component="div" className="text-danger" name="expiry_date" />
                      <br />

                  {/* <Switch
                    id="is_active"
                    type="switch"
                    className="mt-6"
                    label={values.is_active ? "Active" : "Inactive"}
                    checked={values.is_active}
                    onChange={e => setFieldValue('is_active', e.target.checked)}
                  /> */}
              </>
              )}
              {key === "userDetails" && (
                  <>
                  
                  <label className="form-label"><b>Person Name</b></label>
                    <Field
                      name="name"
                      type="text"
                      className={`form-control ${
                        errors.name && touched.name
                          ? "is-invalid"
                          : ""
                      }`}
                      placeholder="Enter Person Name"
                      onChange={handleChange}
                    />
                    {errors.name && touched.name ? (
                      <div className="invalid-feedback">
                        {errors.name}
                      </div>
                    ) : null}
                    <br />
                  {/* <label className="form-label"><b>Login ID</b></label>
                    <Field
                      name="username"
                      type="text"
                      className={`form-control ${
                        errors.username && touched.username
                          ? "is-invalid"
                          : ""
                      }`}
                      placeholder="Enter User Name"
                      onChange={handleChange}
                    />
                    {errors.username && touched.username ? (
                      <div className="invalid-feedback">
                        {errors.username}
                      </div>
                    ) : null}
                    <br />       */}
                  
                  </>)}
              </Modal.Body>
              <Modal.Footer>
              <Button variant="secondary" onClick={handleModalClose}>
                  Close
                </Button>
                {key === "organization" && (
                  <Button
                    variant="primary"
                    onClick={() => setKey("userDetails")}
                    disabled={
                      !isOrganizationFormValid(values, errors) || isSubmitting
                    }
                  >
                    Next
                  </Button>
                )}
                {key === "userDetails" && (
                <Button
                    type="submit"
                    variant="primary"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? (
                      <>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        Updating...
                      </>
                    ) : (
                      "Update"
                    )}
                  </Button>
                )}
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
}