import React from "react";
import CommonLayout from "../../layouts/master/index";
import List from "./List";


export default function CreditsLog() {



  return (
    <>
      <CommonLayout>    
        <div>
          <List
          />
        </div>
      </CommonLayout>
    </>
  );
}
