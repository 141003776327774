import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosapi from "../utilities/axiosApi";

export const userLogin = createAsyncThunk(
    'user/login',
    async (loginDetails, { rejectWithValue }) => {
      try {
        const response = await axiosapi.post('/api/reseller/login', loginDetails);
        return response.data; 
      } catch (err) {
        return rejectWithValue(err.response.data);
      }
    }
  );

export const userDetails = createAsyncThunk(
  'user/details',
  async () => {
      const response = await axiosapi.get('/api/reseller/me');
      return response;
  }
)

export const userLogout = createAsyncThunk(
  'user/logout',
  async () => {
      const response = await axiosapi.get('/api/reseller/logout');
      return response.data;
  }
)

export const userProfile  = createAsyncThunk(
  'user/profile',
  async (formData, { rejectWithValue }) => {
    try{
      const response = await axiosapi.post('/api/reseller/profile-update', formData);
      return response;
  }
  catch (err) {
    return rejectWithValue(err.response.data);
  }
})

export const userUpdatePassword = createAsyncThunk(
  'user/update-password',
  async (formData, { rejectWithValue }) => {
    try{
      const response = await axiosapi.post('/api/reseller/change-password', formData);
      return response;
  }
  catch (err) {
    return rejectWithValue(err.response.data);
  }
}
)

export const userForgotPassword = createAsyncThunk(
  'user/forgot-password',
  async (forgotPasswordDetails, { rejectWithValue }) => {
    try {
      const response = await axiosapi.post('/api/reseller/forgot-password', forgotPasswordDetails);
      return response.data; 
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const userResetPassword = createAsyncThunk(
  'user/reset-password',
  async (resetPasswordDetails, { rejectWithValue }) => {
    try {
      const response = await axiosapi.post('/api/reseller/reset-password', resetPasswordDetails);
      return response.data; 
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const userDeleteLogo = createAsyncThunk(
  'auth/userDeleteLogo',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosapi.post('/api/reseller/delete-company-logo');
      return response.data; 
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ message: 'Network error' });
      }
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: null,
    isAuthenticated: false,
    resellerId: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(userLogin.fulfilled, (state, { payload }) => {
      if (payload.success === true) {
        state.isAuthenticated = true;
        state.user = payload.data;
      }
    });
    builder.addCase(userDetails.fulfilled, (state, { payload }) => {
      if (payload.data.success === true) {
        state.isAuthenticated = true;
        state.user = payload.data.data;
        state.resellerId = payload.data.data.id;
      }
    });
    builder.addCase(userDetails.rejected, (state, { payload }) => {
      if (payload === undefined) {
        state.isAuthenticated = false;
        state.user = null;
      }
    });
    builder.addCase(userLogout.fulfilled, (state, { payload }) => {
      if (payload.data.success === true) {
        state.isAuthenticated = false;
        state.user = null;
      }
    });
    builder.addCase(userProfile.fulfilled, (state, { payload }) => {
      if (payload.data.success === true) {
        state.isAuthenticated = true;
        state.user = payload.data.data;
      }
    });
    builder.addCase(userUpdatePassword.fulfilled, (state, { payload }) => {
      if (payload.data.success === true) {
        state.isAuthenticated = true;
        state.user = payload.data.data;
      }
    });
    builder.addCase(userDeleteLogo.fulfilled, (state, { payload }) => {
      if (payload.success === true) {
        state.isAuthenticated = true;
        state.user = payload.data.data;
      }
    });
  },
});

export default authSlice.reducer;
