import React, { useCallback, useEffect, useState } from "react";
import CommonLayout from "../../../container/layouts/master/index";
import { Col, Row } from "react-bootstrap";

import { Bar } from "react-chartjs-2";
// import { Pie } from "react-chartjs-2";
import { Doughnut } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { useLoader } from "../../../context/LoaderContext";
import { useDispatch, useSelector } from "react-redux";
import { fetchDashboardData } from "../../../store/customerSlice";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

const Dashboard = () => {
  const { setLoading } = useLoader();
  const dispatch = useDispatch();

  const dashboardData = useSelector((state) => state.customer.dashboardData);
  const [timePeriod, setTimePeriod] = useState("This Week");

  const handleTimePeriodChange = (value) => {
    setTimePeriod(value);
    // Here you can add logic to update chart data based on the selected time period
  };

  const chartData = {
    labels: ["January", "February", "March", "April", "May", "June", "July"],
    datasets: [
      {
        label: "New Customers",
        data: [65, 59, 80, 81, 56, 55, 40], // Replace this with your data
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 2,
      },
    ],
  };

  // const chartOptions = {
  //   responsive: true,
  //   plugins: {
  //     legend: {
  //       position: 'top',
  //     },
  //     title: {
  //       display: true,
  //       text: 'New Customers Over Time',
  //     },
  //   },
  // };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false, // Hide legend
      },
      // tooltip: {
      //   enabled: false, // Disable tooltip
      // },
    },
    scales: {
      x: {
        display: false, // Hide x-axis
      },
      y: {
        display: false, // Hide y-axis
      },
    },
  };

  // const pieData = {
  //   labels: ["Failed", "In Process", "Pending", "Completed"],
  //   datasets: [
  //     {
  //       data: [
  //         dashboardData?.total_failed_campaigns || 0,
  //         dashboardData?.total_in_process_campaigns || 0,
  //         dashboardData?.total_pending_campaigns || 0,
  //         dashboardData?.total_completed_campaigns || 0,
  //       ],
  //       backgroundColor: [
  //         "rgba(255, 99, 132, 0.6)", // Red
  //         "rgba(54, 162, 235, 0.6)", // Blue
  //         "rgba(255, 206, 86, 0.6)", // Yellow
  //         "rgba(75, 192, 192, 0.6)", // Green
  //       ],
  //       borderColor: [
  //         "rgba(255, 99, 132, 1)",
  //         "rgba(54, 162, 235, 1)",
  //         "rgba(255, 206, 86, 1)",
  //         "rgba(75, 192, 192, 1)",
  //       ],
  //       borderWidth: 1,
  //     },
  //   ],
  // };

  const doughnutData = {
    labels: ["Failed", "In Process", "Pending", "Completed"],
    datasets: [
      {
        data: [
          dashboardData?.total_failed_campaigns || 0,
          dashboardData?.total_in_process_campaigns || 0,
          dashboardData?.total_pending_campaigns || 0,
          dashboardData?.total_completed_campaigns || 0,
        ],
        backgroundColor: [
          "rgba(255, 99, 132, 0.6)", // Red for Small Business
          "rgba(54, 162, 235, 0.6)", // Blue
          "rgba(255, 206, 86, 0.6)", // Yellow for Enterprise
          "rgba(75, 192, 192, 0.6)", // Green for Individuals
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
        ],
        borderWidth: 1,
        cutout: "70%", // Creates the inner cutout for the doughnut chart
      },
    ],
  };

  // const pieOptions = {
  //   responsive: true,
  //   maintainAspectRatio: false,
  //   plugins: {
  //     legend: {
  //       position: "top",
  //     },
  //   },
  // };
  const doughnutOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false, // Hide default legend
      },
      tooltip: {
        enabled: false, // Disable tooltip to match the style
      },
    },
  };

  const getDashboardData = useCallback(() => {
    setLoading(true);

    dispatch(fetchDashboardData({}))
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch, setLoading]);

  useEffect(() => {
    getDashboardData();
  }, [getDashboardData]);

  return (
    <>
      <CommonLayout>
        <div className="p-4">
          {/* My Credits */}
          <Row className="my-5 g-4 cerdit-history-content">
            <h3>My Credits</h3>

            <Col xxl={3} xl={4} lg={4} md={6} xs={12}>
              <div className="p-4 rounded-3 cedit-h-card h-100">
                <div className="d-flex align-items-start justify-content-between gap-3">
                  <div>
                    <p className="mb-0 text-capitalize">Total Credits</p>
                    <span>
                      {dashboardData?.total_credits}
                      {/* {creditLogBooks.opening_balance || 0} */}
                    </span>
                  </div>
                  <div className="credit-h-icon d-flex align-items-center justify-content-center rounded-1"></div>
                </div>
              </div>
            </Col>

            <Col xxl={3} xl={4} lg={4} md={6} xs={12}>
              <div className="p-4 rounded-3 cedit-h-card  h-100">
                <div className="d-flex align-items-start justify-content-between gap-3">
                  <div>
                    <p className="text-capitalize">Total Used Credits</p>
                    <span>
                      {dashboardData?.total_credits -
                        dashboardData?.available_credits}
                    </span>
                  </div>
                  <div className="credit-h-icon d-flex align-items-center justify-content-center rounded-1"></div>
                </div>
              </div>
            </Col>

            <Col xxl={3} xl={4} lg={4} md={6} xs={12}>
              <div className="p-4 rounded-3 cedit-h-card h-100">
                <div className="d-flex align-items-start justify-content-between gap-3">
                  <div>
                    <p className="mb-0 text-capitalize">Available Credits</p>
                    <span>
                      {dashboardData?.available_credits}
                      {/* {creditLogBooks.opening_balance || 0} */}
                    </span>
                  </div>
                  <div className="credit-h-icon d-flex align-items-center justify-content-center rounded-1"></div>
                </div>
              </div>
            </Col>
          </Row>

          {/* Organizations */}
          <Row className="my-5 g-4 cerdit-history-content">
            <h3>Organizations</h3>
            <Col xxl={3} xl={4} lg={4} md={6} xs={12}>
              <div className="p-4 rounded-3 cedit-h-card h-100">
                <div className="d-flex align-items-start justify-content-between gap-3">
                  <div>
                    <p className="mb-0 text-capitalize">Total</p>
                    <span>
                      {dashboardData?.total_organizations}
                      {/* {creditLogBooks.opening_balance || 0} */}
                    </span>
                  </div>
                  <div className="credit-h-icon d-flex align-items-center justify-content-center rounded-1"></div>
                </div>
              </div>
            </Col>
            <Col xxl={3} xl={4} lg={4} md={6} xs={12}>
              <div className="p-4 rounded-3 cedit-h-card  h-100">
                <div className="d-flex align-items-start justify-content-between gap-3">
                  <div>
                    <p className="text-capitalize">Active</p>
                    <span>
                      {dashboardData?.total_active_organizations}
                    </span>{" "}
                  </div>
                  <div className="credit-h-icon d-flex align-items-center justify-content-center rounded-1"></div>
                </div>
              </div>
            </Col>
            <Col xxl={3} xl={4} lg={4} md={6} xs={12}>
              <div className="p-4 rounded-3 cedit-h-card  h-100">
                <div className="d-flex align-items-start justify-content-between gap-3">
                  <div>
                    <p className="text-capitalize">Inactive</p>
                    <span>
                      {dashboardData?.total_inactive_organizations}
                    </span>{" "}
                  </div>
                  <div className="credit-h-icon d-flex align-items-center justify-content-center rounded-1"></div>
                </div>
              </div>
            </Col>      
          </Row>

          {/* Customers */}

          <Row className="my-5 g-4 cerdit-history-content gap-4">
            <h3>Customers</h3>
            <Row>
              <Col xxl={3} xl={4} lg={4} md={6} xs={12}>
                <div className="p-4 rounded-3 cedit-h-card h-100">
                  <div className="d-flex align-items-start justify-content-between gap-3">
                    <div>
                      <p className="mb-0 text-capitalize">Total</p>
                      <span>
                        {dashboardData?.total_customers}
                        {/* {creditLogBooks.opening_balance || 0} */}
                      </span>
                    </div>
                    <div className="credit-h-icon d-flex align-items-center justify-content-center rounded-1"></div>
                  </div>
                </div>
              </Col>
              <Col xxl={3} xl={4} lg={4} md={6} xs={12}>
                <div className="p-4 rounded-3 cedit-h-card  h-100">
                  <div className="d-flex align-items-start justify-content-between gap-3">
                    <div>
                      <p className="text-capitalize">Connected</p>
                      <span>
                        {dashboardData?.total_active_customers}
                        {/* {creditLogBooks.opening_balance || 0} */}
                      </span>{" "}
                    </div>
                    <div className="credit-h-icon d-flex align-items-center justify-content-center rounded-1"></div>
                  </div>
                </div>
              </Col>
              <Col xxl={3} xl={4} lg={4} md={6} xs={12}>
                <div className="p-4 rounded-3 cedit-h-card  h-100">
                  <div className="d-flex align-items-start justify-content-between gap-3">
                    <div>
                      <p className="text-capitalize">Not Connected</p>
                      <span>{dashboardData?.total_inactive_customers}</span>
                    </div>
                    <div className="credit-h-icon d-flex align-items-center justify-content-center rounded-1"></div>
                  </div>
                </div>
              </Col>
            </Row>

            <Row>
              <Col xxl={3} xl={6} lg={4} md={6} xs={12}>
                <div className="p-4 rounded-3 cedit-h-card  h-100">
                  <div className="d-flex align-items-start justify-content-between">
                    <div className="d-flex align-items-start justify-content-between gap-3">
                      <div>
                        <p className="text-capitalize">Customers Campaigns</p>
                        <span>{dashboardData?.total_campaigns}</span>
                      </div>
                      <div className="credit-h-icon d-flex align-items-center justify-content-center rounded-1"></div>
                    </div>
                  </div>
                  {dashboardData?.total_campaigns ? (
                    <>
                      <div style={{ height: "200px", position: "relative" }}>
                        <Doughnut data={doughnutData} options={doughnutOptions} />
                        <div
                          style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            textAlign: "center",
                          }}
                        >
                          <p style={{ margin: 0, fontSize: "24px" }}>{dashboardData?.total_campaigns || 2758}</p>
                          <p style={{ margin: 0, fontSize: "12px" }}>Total</p>
                        </div>
                      </div>
                      <div className="mt-3">
                        <ul style={{ listStyle: "none", padding: 0 }}>
                          <li className="d-flex align-items-center">
                            <div
                              style={{
                                width: "4px",
                                height: "20px",
                                backgroundColor: "rgba(255, 99, 132, 1)",
                                marginRight: "8px",
                              }}
                            ></div>
                            Failed: {dashboardData?.total_failed_campaigns || 0}
                          </li>
                          <li className="d-flex align-items-center">
                            <div
                              style={{
                                width: "4px",
                                height: "20px",
                                backgroundColor: "rgba(255, 206, 86, 1)",
                                marginRight: "8px",
                              }}
                            ></div>
                            Inprogress: {dashboardData?.total_in_process_campaigns || 0}
                          </li>
                          <li className="d-flex align-items-center">
                            <div
                              style={{
                                width: "4px",
                                height: "20px",
                                backgroundColor: "rgba(75, 192, 192, 1)",
                                marginRight: "8px",
                              }}
                            ></div>
                            Pending: {dashboardData?.total_pending_campaigns || 0}
                          </li>
                          <li className="d-flex align-items-center">
                            <div
                              style={{
                                width: "4px",
                                height: "20px",
                                backgroundColor: "rgba(54, 162, 235, 1)",
                                marginRight: "8px",
                              }}
                            ></div>
                            Completed: {dashboardData?.total_completed_campaigns || 0}
                          </li>
                        </ul>
                      </div>
                    </>
                  ) : null}
                </div>
              </Col>
            </Row>
          </Row>

          {/* Whatsapp Status */}
          {/* <Row className="my-5 g-4 cerdit-history-content">
            <h3>Whatsapp Status</h3>
            <Col xxl={3} xl={4} lg={4} md={6} xs={12}>
              <div className="p-4 rounded-3 cedit-h-card h-100">
                <div className="d-flex align-items-start justify-content-between gap-3">
                  <div>
                    <p className="mb-0 text-capitalize">Server Status</p>
                    <span>
                      Online */}
                      {/* {creditLogBooks.opening_balance || 0} */}
                    {/* </span>
                  </div>
                  <div className="credit-h-icon d-flex align-items-center justify-content-center rounded-1"></div>
                </div>
              </div>
            </Col>
            <Col xxl={3} xl={4} lg={4} md={6} xs={12}>
              <div className="p-4 rounded-3 cedit-h-card  h-100">
                <div className="d-flex align-items-start justify-content-between gap-3">
                  <div>
                    <p className="text-capitalize">Active</p>
                    <span>{dashboardData?.total_active_customers}</span>{" "}
                  </div>
                  <div className="credit-h-icon d-flex align-items-center justify-content-center rounded-1"></div>
                </div>
              </div>
            </Col>
            <Col xxl={3} xl={4} lg={4} md={6} xs={12}>
              <div className="p-4 rounded-3 cedit-h-card  h-100">
                <div className="d-flex align-items-start justify-content-between gap-3">
                  <div>
                    <p className="text-capitalize">Inactive</p>
                    <span>{dashboardData?.total_inactive_customers}</span>
                  </div>
                  <div className="credit-h-icon d-flex align-items-center justify-content-center rounded-1"></div>
                </div>
              </div>
            </Col>
          </Row> */}

          {/* Campaigns */}
          <Row className="my-5 g-4 cerdit-history-content">
            <h3>Campaigns</h3>
            <Col xxl={3} xl={6} lg={4} md={6} xs={12}>
              <div className="p-4 rounded-3 cedit-h-card  h-100">
                <div className="d-flex align-items-start justify-content-between">
                  <div className="d-flex align-items-start justify-content-between gap-3">
                    <div>
                      <p className="text-capitalize">Total Campaigns</p>
                      <span>{dashboardData?.total_campaigns}</span>
                    </div>
                    <div className="credit-h-icon d-flex align-items-center justify-content-center rounded-1"></div>
                  </div>
                  <div>
                    <select
                      className="form-select"
                      value={timePeriod}
                      onChange={(e) => handleTimePeriodChange(e.target.value)}
                      aria-label="Select Time Period"
                      style={{ width: "150px" }}
                    >
                      <option value="This Week">This Week</option>
                      <option value="This Month">This Month</option>
                      <option value="This Year">This Year</option>
                      <option value="All">All</option>
                    </select>
                  </div>
                </div>
                <Bar data={chartData} options={chartOptions} />
              </div>
            </Col>
          </Row>
        </div>
      </CommonLayout>
    </>
  );
};

export default Dashboard;
