import React, { useEffect, useState, useCallback } from "react";
import "./rechargeHistory.css";
import {
  Col,
  Container,
  Row,
  // ProgressBar,
  // Table,
  Badge, Button, Tabs, Tab, Modal
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchWalletDetails, fetchMyCredit } from "../../../store/walletSlice";
import PaginationComponent from "../pagination/pagination";
import { createSelector } from "reselect";
import { ArrowUp, ArrowDown } from "react-feather";
import CommonLayout from "../../layouts/master/index";
import { useAuth } from "../../../context/AuthContext";
import { useLoader } from "../../../context/LoaderContext";
import Credit from "./Credit";
import moment from "moment";

export default function RechargeHistory() {
  const { setLoading } = useLoader();
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(0);
  const limit = 10;
  const [sortKey, setSortKey] = useState("id");
  const [sortOrder, setSortOrder] = useState("desc");
  const [showModal, setShowModal] = useState(false);
  const [activeTab, setActiveTab] = useState(null);
  const { user } = useAuth();
  const [descriptionModalShow, setDescriptionModalShow] = useState(false);
  const [fullDescription, setFullDescription] = useState("");
  // const walletCreditDetail = useSelector((state) => state.wallet.myCredit);

  const selectWalletState = (state) => state.wallet.walletDetails;

  const selectWalletData = createSelector(
    [selectWalletState],
    (walletDetails) => {
      return {
        total: walletDetails.total,
        credits: walletDetails.data,
      };
    }
  );

  const { total, credits } = useSelector(selectWalletData);

  const getWalletList = useCallback(() => {
    setLoading(true);
    Promise.all([
      dispatch(fetchMyCredit(user.id)),
      dispatch(
        fetchWalletDetails({
          id: user.company_id,
          offset: currentPage,
          limit,
          search: "",
          sort_by_key: sortKey,
          sort_by_value: sortOrder,
          status: activeTab,
        })
      ),
    ])
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch, user, currentPage, limit, sortKey, sortOrder, setLoading, activeTab]);

  useEffect(() => {
    getWalletList();
  }, [getWalletList]);

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onClick(e);
      }}
      className="text-muted text-primary-hover"
    >
      {children}
    </Link>
  ));

  CustomToggle.displayName = "CustomToggle";

  const handlePageChange = (newPageNumber) => {
    setCurrentPage(newPageNumber - 1);
  };

  const handleSort = (key) => {
    const isAsc = sortKey === key && sortOrder === "asc";
    setSortKey(key);
    setSortOrder(isAsc ? "desc" : "asc");
  };

  const renderSortIcons = (key) => {
    const isActive = sortKey === key;
    return (
      <>
        <ArrowUp
          size={16}
          onClick={() => handleSort(key, "asc")}
          style={{
            cursor: "pointer",
            color: isActive && sortOrder === "asc" ? "black" : "#ccc",
          }}
        />
        <ArrowDown
          size={16}
          onClick={() => handleSort(key, "desc")}
          style={{
            cursor: "pointer",
            color: isActive && sortOrder === "desc" ? "black" : "#ccc",
          }}
        />
      </>
    );
  };

  // const usedCredits = (available_credits, total_credits) => {
  //   if (
  //     !Number.isFinite(available_credits) ||
  //     !Number.isFinite(total_credits) ||
  //     total_credits === 0
  //   ) {
  //     return 0;
  //   }
  //   return Math.round(
  //     ((total_credits - available_credits) / total_credits) * 100
  //   );
  // };
  const handleShow = () => setShowModal(true);
  const handleClose = () => {
    setShowModal(false);
  }
  
  const handleTabSelect = (key) => {
    if (key === "") {
      setActiveTab(null); 
    } else {
      setActiveTab(key);
    }
  
    setCurrentPage(0); 
  };

  const handleReadMore = (description) => {
    setFullDescription(description);
    setDescriptionModalShow(true);
  };

  return (
    <>
      <CommonLayout>
        <div className="bg-primary pt-10 pb-21"></div>
        <Container fluid className="mt-n22 px-6">
          <Row>
            <Col lg={12} md={12} xs={12}>
              <div>
                <div className="d-flex justify-content-between align-items-center top_title">
                  <div className="mb-2 mb-lg-0">
                    <h3 className="mb-0 subhead_title text-white">
                      Recharge History
                    </h3>
                  </div>
                </div>
              </div>
            </Col>

            <Col xl={12} lg={12} md={12} xs={12} className="mt-6">
            
            
              <div className="position-relative overflow-hidden border-radius-8 tablecontainer">
                <div className="table-responsive rounded-2 custom-tbl">
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button variant="primary" onClick={handleShow}>
                    Request Credit
                  </Button>
                  </div>

                <div className="position-relative overflow-hidden rounded-2 bg-white table-tabs mb-3">
                      <Tabs
                      defaultActiveKey=""
                      className="pb-0 table-tabs-items"
                      id="uncontrolled-tab-example"
                      onSelect={handleTabSelect}
                    >
                      <Tab
                        eventKey=""
                        title={
                          <>
                            <div className="d-flex align-items-center gap-2">
                              {/* <UserList
                              size={22}
                              color="#637381"
                              weight="regular"
                            /> */}
                              <p className="mb-0">All</p>
                            </div>
                          </>
                        }
                      ></Tab>
                      <Tab
                        eventKey="placed"
                        title={
                          <>
                            <div className="d-flex align-items-center gap-2">
                              {/* <ClockCounterClockwise
                              size={22}
                              color="#637381"
                              weight="regular"
                            /> */}
                              <p className="mb-0">Requested</p>
                            </div>
                          </>
                        }
                      ></Tab>
                      <Tab
                        eventKey="completed"
                        title={
                          <>
                            <div className="d-flex align-items-center gap-2">
                              {/* <ClockCounterClockwise
                              size={22}
                              color="#637381"
                              weight="regular"
                            /> */}
                              <p className="mb-0">Completed</p>
                            </div>
                          </>
                        }
                      ></Tab>
                      <Tab
                        eventKey="rejected"
                        title={
                          <>
                            <div className="d-flex align-items-center gap-2">
                              {/* <ClockCounterClockwise
                              size={22}
                              color="#637381"
                              weight="regular"
                            /> */}
                              <p className="mb-0">Rejected</p>
                            </div>
                          </>
                        }
                      ></Tab>
                    </Tabs>
                    </div>
                  <table className="text-nowrap text-start w-100 mt-6">
                    <thead className="table-light">  
                      <tr>
                        <th scope="col">
                          <div className="d-flex justify-content-between align-items-center">
                            <span>
                              Purchase Date {renderSortIcons("created_at")}
                            </span>
                          </div>
                        </th>
                        <th scope="col">
                          <div className="d-flex justify-content-between align-items-center">
                            <span>Credits</span>
                          </div>
                        </th>
                        <th scope="col">
                          <div className="d-flex justify-content-between align-items-center">
                            <span>Credit Rate</span>
                          </div>
                        </th>
                        <th scope="col">
                          <div className="d-flex justify-content-between align-items-center">
                            <span>Total Price</span>
                          </div>
                        </th>
                        <th scope="col">
                          <div className="d-flex justify-content-between align-items-center">
                            <span>Description</span>
                          </div>
                        </th>
                        <th scope="col">
                          <div className="d-flex justify-content-between align-items-center">
                            <span>Status</span>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="credit_data">
                      {credits?.length > 0 ? (
                        credits.map((credit, i) => (
                          <tr key={i} className="data_row">
                            <td>{moment(credit.created_at).format(
                                "DD/MM/YYYY hh:mm A"
                              )}</td>
                            <td>{credit.credits}</td>
                            <td>₹{credit.credit_rate}</td>
                            <td>₹{credit.price}</td>
                            <td>
                          {credit.description?.length > 30 ? (
                            <>
                              {credit.description.substring(0, 30)}...
                              <span style={{ color: 'blue', cursor: 'pointer' }} onClick={(e) => {
                                  e.stopPropagation();
                                  handleReadMore(credit.description);
                                }}>
                                  Read More
                                </span>
                            </>
                          ) : (
                            credit.description || "-"
                          )}
                      </td>                                <td>
                              {credit.status === "completed" ? (
                                <Badge pill bg="success">
                                  Completed
                                </Badge>
                              ) : credit.status === "rejected" ? (
                                <Badge pill bg="danger">
                                  Rejected
                                </Badge>
                              ) : (
                                <Badge pill bg="primary">
                                  Requested
                                </Badge>
                              )}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="7" className="text-center">
                            No data available
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                {total > limit && (
                  <PaginationComponent
                    totalItems={total}
                    itemsPerPage={limit}
                    currentPage={currentPage + 1}
                    onPageChange={handlePageChange}
                  />
                )}
                {/*  */}
              </div>
            </Col>
          </Row>
        </Container>
      </CommonLayout>

      <Modal show={descriptionModalShow} onHide={() => setDescriptionModalShow(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Description</Modal.Title>
        </Modal.Header>
        <Modal.Body>{fullDescription}</Modal.Body>
      </Modal>

      <Credit
          show={showModal}
          handleClose={handleClose}
          refreshData={getWalletList} 
        />
    </>
  );
}
