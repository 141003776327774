import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  //   Table,
  Toast,
  Container,
  Col,
  Row,
  Button,
  //   Dropdown,
  //   Modal,
} from "react-bootstrap";

import {
  StackPlus,
  Coins,
  StackMinus,
  MoneyWavy,
} from "../../../constants/icons/icons";
// import { Coins } from "../../../constants/icons/icons";
// import {
//   fetchCustomers,
//   deleteCustomer,
//   fetchCountry,
//   toggleCustomerActiveStatus,
// } from "../../../store/customerSlice";
// import View from "./View";
// import { MoreVertical } from "react-feather";
import PaginationComponent from "../pagination/pagination";
import "./style.css";
// import CustomerDetailModal from "./CustomerDetail";
// import { createSelector } from 'reselect';
// import { ArrowUp, ArrowDown } from "react-feather";
import { CheckCircle } from "react-feather";
import moment from "moment";
import { useLoader } from "../../../context/LoaderContext";

import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
// import { fetchCreditsLog } from "../../../store/walletSlice";
import { fetchCreditLogBooks } from "../../../store/customerSlice";
import Credit from "./Credit";

export default function List({ editHandler, handleOpenModal }) {
  const { setLoading } = useLoader();
  const dispatch = useDispatch();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarVariant, setSnackbarVariant] = useState("success");
  //   const [currentCustomer, setCurrentCustomer] = useState(null);
  // const [showViewModal, setShowViewModal] = useState(false);
  const [currentCreditHistoryPage, setCurrentCreditHistoryPage] = useState(0);
  const limit = 10;
  //   const [
  //     // searchTerm,
  //     // setSearchTerm
  //   ] = useState("");
  // const firstRender = useRef(true);
  //   const [showCustomerDetailModal, setShowCustomerDetailModal] = useState(false);
  //   const countries = useSelector((state) => state.customer.countries);
  const creditLogBooks = useSelector((state) => state.customer.creditLogBooks);

  //   const [sortKey, setSortKey] = useState("");
  //   const [sortOrder, setSortOrder] = useState("asc");
  // const { total } = useSelector((state) => ({
  //   total: state.customer.total,
  //   customers: state.customer.customers,
  // }));
  //   const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  //   const [customerToDelete, setCustomerToDelete] = useState({});
  //   const [showToggleConfirmation, setShowToggleConfirmation] = useState(false);
  //   const [customerToToggle, setCustomerToToggle] = useState({});
  //   const [toggleStatus, setToggleStatus] = useState(null);

  const [showModal, setShowModal] = useState(false);
  const toastMessage = localStorage.getItem("toastMessage");

  const [dateRange, setDateRange] = useState({
    startDate: moment().subtract(29, "days"),
    endDate: moment(),
  });

  useEffect(() => {
    if (toastMessage) {
      setShowSnackbar(true);
      setSnackbarMessage(toastMessage);
      setSnackbarVariant("success" || "danger");
      localStorage.removeItem("toastMessage");
      if (toastMessage === "Validation failed") {
        setShowSnackbar(true);
        setSnackbarMessage(toastMessage);
        setSnackbarVariant("danger");
        localStorage.removeItem("toastMessage");
      }
    }
  }, [toastMessage]);

  const getCreditsLog = useCallback(() => {
    setLoading(true);

    dispatch(
      fetchCreditLogBooks({
        start_date: dateRange?.startDate.format("YYYY/MM/DD"),
        offset: currentCreditHistoryPage,
        end_date: dateRange?.endDate.format("YYYY/MM/DD"),
      })
    )
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    dispatch,
    setLoading,
    dateRange?.startDate,
    dateRange?.endDate,
    currentCreditHistoryPage,
  ]);

  useEffect(() => {
    getCreditsLog();
  }, [getCreditsLog]);

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onClick(e);
      }}
      className="text-muted text-primary-hover"
      role="button"
    >
      {children}
    </div>
  ));

  CustomToggle.displayName = "CustomToggle";

  //   const handleRowClick = (customer) => {
  //     setSearchTerm("");
  //     // setCurrentCustomer(customer);
  //     setShowCustomerDetailModal(true);
  //   };

  //   const ActionMenu = ({ customer }) => {
  //     return (
  //       <Dropdown drop="up" className=" position-static">
  //         <Dropdown.Toggle as={CustomToggle}>
  //           <MoreVertical size="15px" className="text-muted" />
  //         </Dropdown.Toggle>
  //         <Dropdown.Menu align={"end"}>
  //           <Dropdown.Item
  //             eventKey="1"
  //             onClick={(e) => {
  //               editHandler(customer.id);
  //               e.stopPropagation();
  //             }}
  //           >
  //             Edit
  //           </Dropdown.Item>
  //           <Dropdown.Item eventKey="2">
  //             View
  //           </Dropdown.Item>
  //           <Dropdown.Item
  //             eventKey="3"
  //             onClick={(e) => {
  //               e.stopPropagation();
  //               promptDeleteCustomer(customer);
  //             }}
  //           >
  //             Delete
  //           </Dropdown.Item>
  //         </Dropdown.Menu>
  //       </Dropdown>
  //     );
  //   };

  //   const getCountryName = (countryId) => {
  //     const country = countries.find((c) => c.id === countryId);
  //     return country ? country.name : "Not Found";
  //   };

  //   const handleSort = (key) => {
  //     const isAsc = sortKey === key && sortOrder === "asc";
  //     setSortKey(key);
  //     setSortOrder(isAsc ? "desc" : "asc");
  //   };

  //   const renderSortIcons = (key) => {
  //     const isActive = sortKey === key;
  //     return (
  //       <>
  //         <ArrowUp
  //           size={16}
  //           onClick={() => handleSort(key, "asc")}
  //           style={{
  //             cursor: "pointer",
  //             color: isActive && sortOrder === "asc" ? "black" : "#ccc",
  //           }}
  //         />
  //         <ArrowDown
  //           size={16}
  //           onClick={() => handleSort(key, "desc")}
  //           style={{
  //             cursor: "pointer",
  //             color: isActive && sortOrder === "desc" ? "black" : "#ccc",
  //           }}
  //         />
  //       </>
  //     );
  //   };

  //   const promptDeleteCustomer = (customer) => {
  //     setCustomerToDelete(customer);
  //     setShowDeleteConfirmation(true);
  //   };

  //   const handleDeleteCustomer = async () => {
  //     if (customerToDelete) {
  //       try {
  //         await dispatch(deleteCustomer(customerToDelete.id));
  //         setSnackbarMessage("Customer deleted successfully");
  //         setSnackbarVariant("success");
  //         dispatch(
  //           fetchCustomers({
  //             offset: currentPage * limit,
  //             limit,
  //             search: searchTerm,
  //           })
  //         );
  //       } catch (error) {
  //         setSnackbarMessage("Error deleting customer");
  //         setSnackbarVariant("danger");
  //       } finally {
  //         setShowSnackbar(true);
  //         setShowDeleteConfirmation(false);
  //         setCustomerToDelete(null);
  //       }
  //     }
  //   };

  //   const promptToggleConfirmation = (customer, status) => {
  //     setCustomerToToggle(customer);
  //     setToggleStatus(status);
  //     setShowToggleConfirmation(true);
  //   };

  //   const handleConfirmToggleActive = () => {
  //     if (customerToToggle) {
  //       dispatch(
  //         toggleCustomerActiveStatus({
  //           id: customerToToggle.id,
  //           isActive: toggleStatus.toString(),
  //         })
  //       )
  //         .then(() => {
  //           setSnackbarMessage(
  //             `Customer ${
  //               toggleStatus ? "activated" : "deactivated"
  //             } successfully`
  //           );
  //           setSnackbarVariant("success");
  //           getCustomerList();
  //         })
  //         .catch((error) => {
  //           setSnackbarMessage("Failed to update customer status");
  //           setSnackbarVariant("danger");
  //         })
  //         .finally(() => {
  //           setShowSnackbar(true);
  //           setShowToggleConfirmation(false);
  //           setCustomerToToggle(null);
  //           setToggleStatus(null);
  //         });
  //     }
  //   };

  //   const StatusDropdown = ({ customer }) => (
  //     <Dropdown>
  //       <Dropdown.Toggle
  //         size="sm"
  //         variant={customer.is_active ? "success" : "danger"}
  //         id="dropdown-basic"
  //       >
  //         {customer.is_active ? "Active" : "Inactive"}
  //       </Dropdown.Toggle>
  //       <Dropdown.Menu>
  //         <Dropdown.Item
  //           onClick={() =>
  //             promptToggleConfirmation(customer, !customer.is_active)
  //           }
  //         >
  //           Set {customer.is_active ? "Inactive" : "Active"}
  //         </Dropdown.Item>
  //       </Dropdown.Menu>
  //     </Dropdown>
  //   );

  const handleEvent = (event, picker) => {
    setDateRange({
      startDate: picker.startDate,
      endDate: picker.endDate,
    });
  };

  const handleCreditHistoryPageChange = (newPageNumber) => {
    setCurrentCreditHistoryPage(newPageNumber - 1);
  };

  const handleShow = () => setShowModal(true);
  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <>
      <Toast
        onClose={() => setShowSnackbar(false)}
        show={showSnackbar}
        delay={3000}
        autohide
        bg={snackbarVariant}
        style={{ position: "fixed", top: 20, right: 20, zIndex: 1000 }}
      >
        <Toast.Body
          style={{ color: "white", display: "flex", alignItems: "center" }}
        >
          <CheckCircle size={20} color="white" style={{ marginRight: 8 }} />
          {snackbarMessage}
        </Toast.Body>
      </Toast>

      <div className="bg-primary pt-10 pb-21"></div>
      <Container fluid className="mt-n22 px-6">
        <Row>
          <Col lg={12} md={12} xs={12}>
            <div>
              <div className="d-flex justify-content-between align-items-center top_title">
                <div className="mb-2 mb-lg-0">
                  <h3 className="mb-0 subhead_title text-white">
                    Credits History
                  </h3>
                </div>
              </div>
            </div>
          </Col>
          <Col xl={12} lg={12} md={12} xs={12} className="mt-6">
            <div className="position-relative border-radius-8 tablecontainer">
              <div className="d-flex align-items-center justify-content-between mb-4 pb-3">
                <DateRangePicker
                  initialSettings={{
                    startDate: dateRange.startDate.toDate(),
                    endDate: dateRange.endDate.toDate(),
                    ranges: {
                      Today: [moment(), moment()],
                      Yesterday: [
                        moment().subtract(1, "days"),
                        moment().subtract(1, "days"),
                      ],
                      "Last 7 Days": [moment().subtract(6, "days"), moment()],
                      "Last 30 Days": [moment().subtract(29, "days"), moment()],
                      "This Month": [
                        moment().startOf("month"),
                        moment().endOf("month"),
                      ],
                      "Last Month": [
                        moment().subtract(1, "month").startOf("month"),
                        moment().subtract(1, "month").endOf("month"),
                      ],
                      "Last 3 Months": [
                        moment().subtract(3, "month").startOf("month"),
                        moment().subtract(1, "month").endOf("month"),
                      ],
                      "Last 6 Months": [
                        moment().subtract(6, "month").startOf("month"),
                        moment().subtract(1, "month").endOf("month"),
                      ],
                      "This Year": [
                        moment().startOf("year"),
                        moment().endOf("year"),
                      ],
                    },
                    locale: {
                      format: "YYYY-MM-DD",
                    },
                  }}
                  onApply={(e, picker) => handleEvent(e, picker)}
                >
                  <button className="btn btn-primary date ">
                    <span>
                      {dateRange.startDate.format("DD/MM/YYYY")} -{" "}
                      {dateRange.endDate.format("DD/MM/YYYY")}
                    </span>
                  </button>
                </DateRangePicker>

                <div>
                  <Button
                    variant="primary"
                    onClick={handleShow}
                    // style={{
                    //   position: "relative",
                    //   left: "1350px",
                    //   bottom: "68px",
                    // }}
                  >
                    Add Credit
                  </Button>
                </div>
              </div>
              <Row className="mb-4 g-4 cerdit-logs-content">
                <Col xxl={2} xl={4} lg={4} md={6} xs={12}>
                  <div className="p-4 rounded-3 cedit-h-card h-100">
                    <div className="d-flex align-items-start justify-content-between gap-3">
                      <div>
                        <p className="mb-0 text-capitalize">Opening Balance</p>
                        <span>{creditLogBooks?.opening_balance || 0}</span>
                      </div>
                      <div className="credit-h-icon d-flex align-items-center justify-content-center rounded-1">
                        <Coins size={20} color="#624BFF" weight="duotone" />
                      </div>
                    </div>
                  </div>
                </Col>
                <Col
                  xxl={1}
                  xl={1}
                  lg={1}
                  md={1}
                  xs={1}
                  className="d-flex justify-content-center align-items-center"
                >
                  <i
                    className="fas fa-plus-circle fa-1x"
                    style={{ color: "#624BFF", marginLeft: "10px" }}
                  ></i>
                </Col>
                <Col xxl={2} xl={4} lg={4} md={6} xs={12}>
                  <div className="p-4 rounded-3 cedit-h-card  h-100">
                    <div className="d-flex align-items-start justify-content-between gap-3">
                      <div>
                        <p className="text-capitalize">Purchase Credit</p>
                        <span>{creditLogBooks?.total_credits || 0}</span>
                        <div className="d-flex">
                          <div style={{ fontSize: "small" }}>
                            Total Purchase Amount:
                          </div>
                          <div style={{ fontSize: "small" }}>
                            ₹{creditLogBooks.total_purchase_amount || 0}
                          </div>
                        </div>
                      </div>
                      <div className="credit-h-icon d-flex align-items-center justify-content-center rounded-1">
                        <StackPlus size={20} color="#624BFF" weight="duotone" />
                      </div>
                    </div>
                  </div>
                </Col>
                <Col
                  xxl={1}
                  xl={1}
                  lg={1}
                  md={1}
                  xs={1}
                  className="d-flex justify-content-center align-items-center"
                >
                  <i
                    className="fas fa-minus-circle fa-1x"
                    style={{ color: "#624BFF", marginLeft: "10px" }}
                  ></i>
                </Col>
                <Col xxl={2} xl={4} lg={4} md={6} xs={12}>
                  <div className="p-4 rounded-3 cedit-h-card  h-100">
                    <div className="d-flex align-items-start justify-content-between gap-3">
                      <div>
                        <p className="text-capitalize">Selling Credit</p>
                        <span>{creditLogBooks?.total_debits || 0}</span>
                        <div className="d-flex">
                          <div style={{ fontSize: "small" }}>
                            Total Selling Amount:{" "}
                          </div>
                          <div style={{ fontSize: "small" }}>
                            ₹{creditLogBooks.total_selling_amount || 0}
                          </div>
                        </div>
                      </div>
                      <div className="credit-h-icon d-flex align-items-center justify-content-center rounded-1">
                        <StackMinus
                          size={20}
                          color="#624BFF"
                          weight="duotone"
                        />
                      </div>
                    </div>
                  </div>
                </Col>
                <Col
                  xxl={1}
                  xl={1}
                  lg={1}
                  md={1}
                  xs={1}
                  className="d-flex justify-content-center align-items-center"
                >
                  <i
                    className="fas fa-equals fa-1x"
                    style={{ color: "#624BFF", marginLeft: "10px" }}
                  ></i>
                </Col>
                <Col xxl={2} xl={4} lg={4} md={6} xs={12}>
                  <div className="p-4 rounded-3 cedit-h-card  h-100">
                    <div className="d-flex align-items-start justify-content-between gap-3">
                      <div>
                        <p className="text-capitalize">Closing Balance</p>
                        <span>{creditLogBooks?.closing_balance || 0}</span>
                      </div>
                      <div className="credit-h-icon d-flex align-items-center justify-content-center rounded-1">
                        <MoneyWavy size={20} color="#624BFF" weight="duotone" />
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <div className="table-responsive rounded-2 custom-tbl">
                <table className="w-100 rounded-2 overflow-hidden">
                  <thead>
                    <tr>
                      <th className="fw-medium">
                        <div className="d-flex align-items-center gap-th">
                          <p className="mb-0">Date</p>
                        </div>
                      </th>
                      <th className="fw-medium">
                        <div className="d-flex align-items-center gap-th">
                          <p className="mb-0">Description</p>
                        </div>
                      </th>
                      <th className="fw-medium">
                        <div className="d-flex align-items-center gap-th">
                          <p className="mb-0">Rate</p>
                        </div>
                      </th>
                      <th className="fw-medium">
                        <div className="d-flex align-items-center gap-th">
                          <p className="mb-0">Amount</p>
                        </div>
                      </th>

                      <th className="fw-medium">
                        <div className="d-flex align-items-center gap-th">
                          <p className="mb-0">Message Credit</p>
                        </div>
                      </th>
                      <th className="fw-medium">
                        <div className="d-flex align-items-center gap-th">
                          <p className="mb-0">Message Debit</p>
                        </div>
                      </th>
                      <th className="fw-medium">
                        <div className="d-flex align-items-center gap-th">
                          <p className="mb-0">Balance</p>
                        </div>
                      </th>
                      {/* <th className="fw-medium">
                        <div className="d-flex align-items-center gap-th">
                          <p className="mb-0">Balance</p>
                        </div>
                      </th> */}
                    </tr>
                  </thead>
                  <tbody className="cu-tbody">
                    {creditLogBooks?.data?.length > 0 ? (
                      creditLogBooks?.data?.map((credit, i) => (
                        <tr key={i} className="align-middle data_row">
                          <td>
                            {moment(credit.created_at).format("DD/MM/YYYY")}
                          </td>
                          <td>{credit?.description}</td>
                          {credit.credit_rate ? (
                            <td>₹{credit.credit_rate}</td>
                          ) : (
                            <td>-</td>
                          )}
                          {credit.price ? <td>₹{credit.price}</td> : <td>-</td>}

                          {credit.is_credited && credit.credits ? (
                            <td>{credit.is_credited && credit.credits}</td>
                          ) : (
                            <td>0</td>
                          )}
                          {!credit.is_credited && credit.credits ? (
                            <td>{!credit.is_credited && credit.credits}</td>
                          ) : (
                            <td>0</td>
                          )}
                          <td>
                            {credit?.opening_balance
                              ? `${
                                  credit?.is_credited
                                    ? credit.opening_balance + credit.credits
                                    : credit.opening_balance - credit.credits
                                }`
                              : "-"}
                          </td>
                          {/* {credit?.opening_balance
                                          // ? `${credit?.is_credited? credit.opening_balance + credit.credits : credit.opening_balance - credit.credits}`
                              ?(<td>{credit.opening_balance}</td>)
                            : (<td>{"-"}</td>)} */}
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="6" className="text-center border-bottom-0">
                          <p className="my-5  no-data text-capitalize">
                            No data available
                          </p>
                        </td>
                      </tr>
                    )}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td>
                        <div className="d-flex align-items-center gap-th">
                          <p className="mb-0">Total</p>
                        </div>
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>
                        <div className="d-flex align-items-center gap-th">
                          <p className="mb-0">{creditLogBooks.total_credits}</p>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center gap-th">
                          <p className="mb-0">{creditLogBooks.total_debits}</p>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center gap-th">
                          <p className="mb-0">{}</p>
                        </div>
                      </td>
                      {/* <td></td> */}
                    </tr>
                  </tfoot>
                </table>
              </div>
              {creditLogBooks?.total > limit && (
                <div className="pt-4">
                  <PaginationComponent
                    totalItems={creditLogBooks?.total}
                    itemsPerPage={limit}
                    currentPage={currentCreditHistoryPage + 1}
                    onPageChange={handleCreditHistoryPageChange}
                  />
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Container>

      {/* {currentCustomer && (
        <CustomerDetailModal
          show={showCustomerDetailModal}
          handleCustomerDetailClose={() => setShowCustomerDetailModal(false)}
          customer={currentCustomer}
        />
      )} */}

      {/* {currentCustomer && (
        <View
          show={showViewModal}
          handleClose={() => setShowViewModal(false)}
          customer={currentCustomer}
        />
      )} */}
      <Credit
        show={showModal}
        handleClose={handleClose}
        // companyId={customerDetails.company_id}
        // customerId={customerDetails.id}
        itemsPerPage={limit}
        currentCreditHistoryPage={currentCreditHistoryPage}
        dateRange={dateRange}
      />
    </>
  );
}
