import React from "react";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import './App.css';
import AdminLogin from "./container/pages/auth/Login";
import ForgotPassword from "./container/pages/auth/ForgotPassword";
import ResetPassword from "./container/pages/auth/ResetPassword";
import Dashboard from "./container/pages/dashboard/Dashboard";
import {AuthProvider} from "./context/AuthContext";
import Customer from "./container/pages/customer/Customer";
import Profile from "./container/pages/profile/ProfileDetail";
import PageNotFound from "./container/pages/pageNotFound/Error";
import Wallet from "./container/pages/wallet/WalletCredit";
import CreditsLog from "./container/pages/CreditsLog/creditsLog";
import RequestedCredits from "./container/pages/requestedCredits/List";
import { LoaderProvider } from "./context/LoaderContext";
import Loader from "./container/pages/customer/Loader"; 

import ProtectedLayout from "./components/ProtectedLayout";
import RechargeHistory from "./container/pages/recharge-history/RechargeHistory";

function App() {
  return (
    <div className="App">
      <AuthProvider>
      <LoaderProvider>  
      <Loader />
      <Router>
            <Routes>
                <Route exact path="/" element={<AdminLogin />} />
                <Route exact path="/forgot-password" element={<ForgotPassword />} />
                <Route exact path="/reset-password" element={<ResetPassword />} />

                <Route path="/" element={<ProtectedLayout />}>                 
                    <Route exact path="/dashboard" element={<Dashboard /> } />
                    <Route exact path="/organizations" element={<Customer />} />
                    <Route exact path="/profile" element={<Profile />} />
                    <Route exact path="/wallet" element={<Wallet/>} />
                    <Route exact path="/credits-history" element={<CreditsLog />} />
                    <Route exact path="/recharge-history" element={<RechargeHistory />} />
                    <Route exact path="/requested-credits" element={<RequestedCredits/>} />
                    <Route path="*" element={<PageNotFound />}/>
                </Route>
            </Routes>
        </Router>
        </LoaderProvider>
        </AuthProvider>
    </div>
  );
}

export default App;