import React from 'react';
import Pagination from 'react-bootstrap/Pagination';
import "../customer/style.css"

const PaginationComponent = ({ totalItems, itemsPerPage, currentPage, onPageChange }) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      onPageChange(page);
    }
  };

  const renderPaginationItems = () => {
    let items = [];

    if (totalPages <= 5) {
      for (let page = 1; page <= totalPages; page++) {
        items.push(
          <Pagination.Item
            key={page}
            className={page === currentPage ? "active" : ""}
            onClick={() => handlePageChange(page)}
          >
            {page}
          </Pagination.Item>
        );
      }
    } else {
      items.push(
        <Pagination.Item
          key={1}
          className={currentPage === 1 ? "active" : ""}
          onClick={() => handlePageChange(1)}
        >
          1
        </Pagination.Item>
      );

      if (currentPage > 3) {
        items.push(<Pagination.Ellipsis key="start-ellipsis" />);
      }

      if (currentPage > 2) {
        items.push(
          <Pagination.Item
            key={currentPage - 1}
            onClick={() => handlePageChange(currentPage - 1)}
          >
            {currentPage - 1}
          </Pagination.Item>
        );
      }

      if (currentPage !== 1 && currentPage !== totalPages) {
        items.push(
          <Pagination.Item key={currentPage} className='active'>
            {currentPage}
          </Pagination.Item>
        );
      }

      if (currentPage < totalPages - 1) {
        items.push(
          <Pagination.Item
            key={currentPage + 1}
            onClick={() => handlePageChange(currentPage + 1)}
          >
            {currentPage + 1}
          </Pagination.Item>
        );
      }

      if (currentPage < totalPages - 2) {
        items.push(<Pagination.Ellipsis key="end-ellipsis" />);
      }

      items.push(
        <Pagination.Item
          key={totalPages}
        className={currentPage === totalPages ? "active" : ""}
        onClick={() => handlePageChange(totalPages)}
        >
          {totalPages}
        </Pagination.Item>
      );
    }

    return items;
  };

  return (
    <Pagination className="justify-content-center list_pagination">
      <Pagination.Prev
        className="prev_btn"
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={currentPage === 1}
      >
        Previous
      </Pagination.Prev>
      {renderPaginationItems()}
      <Pagination.Next
        className="next_btn"
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
      >
        Next
      </Pagination.Next>
    </Pagination>
  );
};

export default PaginationComponent;
