import { Row, Col, Card, Button, Toast, ToastContainer, Spinner, InputGroup } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Formik, Field } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { userLogin } from '../../../store/authSlice';
import { loginSchema } from '../../../schema/validationSchemas';
import { useNavigate } from 'react-router-dom';
import { CheckCircle, EyeOff, Eye } from "react-feather";

const LogIn = () => {
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastVariant, setToastVariant] = useState('success');
  const [showPassword, setShowPassword] = useState(true);
  const dispatch = useDispatch();
  const { status, error } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const result = await dispatch(userLogin(values)).unwrap();
      if (result && result.success) { 
        setToastMessage(result.message || 'Login successful!');
        setToastVariant('success');
        setShowToast(true);
        setTimeout(() => {
          navigate('/dashboard');
        }, 2000);
      } 
      else {
        setToastMessage(result.message || 'Failed to login!');
        setToastVariant('danger');
        setShowToast(true);
        setTimeout(() => {
          navigate('/');
        }, 2000);
      }
    } catch (err) {
      const errorMessage = err?.message || 'Failed to login!';
      setToastMessage(errorMessage);
      setToastVariant('danger');
      setShowToast(true);
      setTimeout(() => {
        navigate('/');
      }, 2000);
    } finally {
      setSubmitting(false);
    }
  };
   
  useEffect(() => {
    if (error) {
      setToastMessage(error);
      setToastVariant('danger');
      setShowToast(true);
    }

    if (status === 'loading') {
      setToastMessage('Logging in...');
      setToastVariant('warning');
      setShowToast(true);
    }
  }, [status, error]);

  return (
    <Row className="align-items-center justify-content-center g-0 min-vh-100">
      <Col xxl={4} lg={6} md={8} xs={12} className="py-8 py-xl-0">
      <ToastContainer position="top-end" className="p-3" style={{ zIndex: 1 }}>
          <Toast
            onClose={() => setShowToast(false)}
            show={showToast}
            delay={5000}
            autohide
            bg={toastVariant}
          >
            <Toast.Body style={{ color: "white", display: 'flex', alignItems: 'center' }}>
          <CheckCircle size={20} color="white" style={{ marginRight: 8 }} />
          {toastMessage}
        </Toast.Body>
          </Toast>
        </ToastContainer>
        <Card className="smooth-shadow-md">
          <Card.Body className="p-6">
            <div className="mb-4">
              <Link to="/"><img src="/images/brand/logo/logo-primary.svg" className="mb-2" alt="" /></Link>
              <h1 className="d-flex mb-6 ml-2">Login - Reseller Panel</h1>
            </div>
            <Formik
              initialValues={{ email: '', password: '' }}
              validationSchema={loginSchema}
              onSubmit={handleSubmit}
            >
              {({ errors, touched, isSubmitting, handleChange, handleBlur, handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <label htmlFor="email" className="d-flex form-label">Email or Login ID</label>
                    <Field
                      id="email"
                      name="email"
                      type="text"
                      placeholder="Enter your Email or Login ID here"
                      className={`form-control ${touched.email && errors.email ? 'is-invalid' : ''}`}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.email && errors.email ? (
                      <div className="invalid-feedback">{errors.email}</div>
                    ) : null}
                  </div>

                  <div className="mb-3">
                    <label htmlFor="password" className="d-flex form-label">Password</label>
                    <InputGroup>
                      <Field
                        id="password"
                        name="password"
                        type={!showPassword ? "text" : "password"}
                        placeholder="Enter password"
                        className={`form-control ${touched.password && errors.password ? 'is-invalid' : ''}`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                       <InputGroup.Text
                                  onClick={() =>
                                    setShowPassword(!showPassword)
                                  }
                                >
                                  {showPassword ? (
                                    <EyeOff size={20} />
                                  ) : (
                                    <Eye size={20} />
                                  )}
                       </InputGroup.Text>
                    </InputGroup>
                    {errors.password &&
                                touched.password && (
                                  <div className="invalid-feedback d-block">
                                    {errors.password}
                                  </div>
                                )}
                  </div>
                  <div className="d-grid w-28 d-flex justify-content-center mt-5">
                  <Button variant="primary" type="submit" disabled={isSubmitting}>
                      {isSubmitting ? (
                        <>
                          <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                          <span className="visually-hidden">Loading...</span>
                        </>
                      ) : (
                        'Log In'
                      )}
                    </Button>
                  </div>
                  <div className="d-grid w-28 d-flex justify-content-center mt-3">
                    <Link to="/forgot-password" className='text-decoration-underline'>Forgot Password</Link>
                  </div>
                </form>
              )}
            </Formik>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}

export default LogIn;