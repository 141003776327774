import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Container,
  Col,
  Row,
  Button,
  Toast,
  Spinner,
  ToastContainer,
  InputGroup,
  FormControl, Modal
} from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import "./profileModule.css";
import CommonLayout from "../../layouts/master/index";
import {
  userDetails,
  userProfile,
  userUpdatePassword, userDeleteLogo
} from "../../../store/authSlice";
import { Formik, Field, Form, ErrorMessage } from "formik";
import {
  profileUpdateSchema,
  passwordSchema,
} from "../../../schema/validationSchemas";
import { CheckCircle, Eye, EyeOff, XCircle } from "react-feather";

export default function Detail() {
  const dispatch = useDispatch();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarVariant, setSnackbarVariant] = useState("success");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [logo, setLogo] = useState(null);
  const [logoPreview, setLogoPreview] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const initialPasswordUpdate = {
    current_password: "",
    new_password: "",
    new_password_confirmation: "",
  };

  useEffect(() => {
    dispatch(userDetails()).then((action) => {
      if (action.payload && action.payload.data.success) {
        setName(action.payload.data.data.name);
        setEmail(action.payload.data.data.email);
        setMobileNumber(action.payload.data.data.company.mobile_number);  
        const logoPath = action.payload.data.data.company.logo || "";
        
        setLogo(logoPath);
        
        setLogoPreview(
          logoPath
            ? `${process.env.REACT_APP_API_URL}/storage/${logoPath}`
            : ""
        );
      }
    });
  }, [dispatch]);
  
  

  const handleProfileSubmit = (values, { setSubmitting, setErrors }) => {
    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("mobile_number", values.mobile_number);
    formData.append("email", values.email);

    if (logo) {
      formData.append("company_logo", logo);
    }

    dispatch(userProfile(formData))
      .then((action) => {
        if (action.payload && action.payload.data.success) {
          setSnackbarMessage(action.payload.data.message);
          setSnackbarVariant("success");
          setShowSnackbar(true);
        } else {
          setErrors(action.payload.data);
          setSnackbarMessage(
            action.payload.message || "Failed to update profile"
          );
          setSnackbarVariant("danger");
          setShowSnackbar(true);
        }
        setSubmitting(false);
      })
      .catch((error) => {
        setSnackbarMessage(
          "Update failed: Network or server error" + error.message
        );
        setSnackbarVariant("danger");
        setShowSnackbar(true);
        setSubmitting(false);
      });
  };

  const handlePasswordChange = (
    values,
    { setSubmitting, setErrors, resetForm }
  ) => {
    dispatch(userUpdatePassword(values))
      .then((action) => {
        if (action.payload && action.payload.data.success) {
          setSnackbarMessage(action.payload.data.message);
          setSnackbarVariant("success");
          setShowSnackbar(true);
          resetForm();
        } else {
          setErrors(action.payload.data);
          setSnackbarMessage(
            action.payload.data.message || "Failed to update password"
          );
          setSnackbarVariant("danger");
          setShowSnackbar(true);
        }
      })
      .catch((error) => {
        setSnackbarMessage(
          "Update failed: Network or server error" + error.message
        );
        setSnackbarVariant("danger");
        setShowSnackbar(true);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const handleLogoUpload = (event) => {
    const file = event.currentTarget.files[0];
    if (file) {
      // setLogoName(file.name); 
      setLogo(file);
      // setFileName(file.name)
      const reader = new FileReader();
      reader.onloadend = () => {
        setLogoPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
    else {
      setLogo(null);
      setLogoPreview("");
    }
  };

  const handleRemoveLogo = () => {
    dispatch(userDeleteLogo())
      .then((action) => {
        if (action.payload && action.payload.success) {
          setSnackbarMessage(action.payload.message);
          setSnackbarVariant("success");
          setShowSnackbar(true);
          setLogo(null);
          setLogoPreview("");
        } else {
          setSnackbarMessage(action.payload.message || "Failed to delete logo");
          setSnackbarVariant("danger");
          setShowSnackbar(true);
        }
        setShowDeleteModal(false);
      })
      .catch((error) => {
        setSnackbarMessage("Failed to delete logo: Network or server error");
        setSnackbarVariant("danger");
        setShowSnackbar(true);
        setShowDeleteModal(false);
      });
  };


  return (
    <CommonLayout>
      <ToastContainer position="top-end" className="p-3" style={{ zIndex: 1 }}>
        <Toast
          onClose={() => setShowSnackbar(false)}
          show={showSnackbar}
          delay={3000}
          autohide
          bg={snackbarVariant}
        >
          <Toast.Body
            style={{ color: "white", display: "flex", alignItems: "center" }}
          >
            <CheckCircle size={20} color="white" style={{ marginRight: 8 }} />
            {snackbarMessage}
          </Toast.Body>
        </Toast>
      </ToastContainer>

      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Logo Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this logo?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Close
          </Button>
          <Button variant="danger" onClick={handleRemoveLogo}>
            Yes, Delete
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="bg-primary pt-10 pb-21"></div>
      <Container fluid className="mt-n22 px-6">
        <Row>
          <Col lg={12} md={12} xs={12}>
            <div>
              <div className="d-flex justify-content-between align-items-center top_title">
                <div className="mb-2 mb-lg-0">
                  <h3 className="mb-0 subhead_title text-white">Profile</h3>
                </div>
              </div>
            </div>
          </Col>

          <Col xl={12} lg={12} md={12} xs={12} className="mt-6">
            <div className="position-relative overflow-hidden border-radius-8 tablecontainer">
              <Tabs
                defaultActiveKey="profilelist"
                className="mb-5 border-0 tablist_data"
              >
                <Tab eventKey="profilelist" title="Profile">
                  <Formik
                    enableReinitialize
                    initialValues={{
                      name: name,
                      mobile_number: mobileNumber,
                      email: email,
                      company_logo: "",
                      profile_url: null,
                    }}
                    validationSchema={profileUpdateSchema}
                    onSubmit={handleProfileSubmit}
                  >
                    {({ isSubmitting, errors, touched, handleChange, setFieldValue }) => (
                      <Form encType="multipart/form-data">
                        <div className="input_head position-relative d-flex align-items-center justify-content-between">
                          <h3 className="float-start m-0 fw-500">
                            Profile Details
                          </h3>
                        </div>
                        <div className="tablecontent_details col-8 position-relative border-radius-8 mt-4">
                          <h4 className="fw-500">Person Details</h4>
                          <div className="row justify-content-center align-items-center pt-4 pb-5 ">
                            {/* <div className="position-relative col-6 floating-label-content">
                              <Field
                                className="w-100 bg-white fs-16 floating-input"
                                type="text"
                                name="name"
                                placeholder=" "
                              />
                              <label className="bg-white position-absolute floating-label">
                                Name
                              </label>
                              <ErrorMessage
                                name="name"
                                component="div"
                                className="text-danger"
                              />
                            </div> */}
                            <div className="col-6">
                              <label className="form-label">
                                <b>Name</b>
                              </label>
                              <Field
                                name="name"
                                type="text"
                                className={`form-control ${
                                  errors.name && touched.name
                                    ? "is-invalid"
                                    : ""
                                } w-5`}
                                placeholder="Enter name"
                                onChange={handleChange}
                              />
                              {errors.name && touched.name ? (
                                <div className="invalid-feedback">
                                  {errors.name}
                                </div>
                              ) : null}
                            </div>

                            <div className="col-6">
                              <label className="form-label">
                                <b>Mobile Number</b>
                              </label>
                              <Field
                                name="mobile_number"
                                type="text"
                                className={`form-control ${
                                  errors.mobile_number && touched.mobile_number
                                    ? "is-invalid"
                                    : ""
                                } `}
                                placeholder="Enter Mobile number"
                                onChange={handleChange}
                              />
                              {errors.mobile_number && touched.mobile_number ? (
                                <div className="invalid-feedback">
                                  {errors.mobile_number}
                                </div>
                              ) : null}
                            </div>


                            <div className="col-6 mt-3">
                              <label className="form-label">
                                <b>Email</b>
                              </label>
                              <Field
                                name="email"
                                type="text"
                                className={`form-control ${
                                  errors.email && touched.email
                                    ? "is-invalid"
                                    : ""
                                } `}
                                placeholder="Enter email"
                                onChange={handleChange}
                              />
                              {errors.email && touched.email ? (
                                <div className="invalid-feedback">
                                  {errors.email}
                                </div>
                              ) : null}
                            </div>

                            <div className="col-6 mt-3">
                              <label className="form-label"><b>Company Logo</b></label>
                              <span style={{color: "grey", fontSize: "smaller", marginLeft:"5px"}}>
                                  (The logo must not exceed 250 pixels in width and 150 pixels in height.)
                                </span>
                              <InputGroup className="mb-3">
                                <FormControl
                                  name="company_logo"
                                  type="file"
                                  onChange={handleLogoUpload}
                                />
                              </InputGroup>

                              {logoPreview && (
                                  <div className="image-preview mt-3 position-relative">
                                    <img
                                      src={logoPreview}
                                      alt=""
                                      className="img-fluid"
                                      style={{ maxHeight: "200px" }}
                                    />
                                    {(logo || logoPreview) && (
                                      <Button
                                        variant="link"
                                        className="remove-logo-btn position-absolute"
                                        style={{
                                          top: "5px",
                                          right: "5px",
                                          color: "red",
                                        }}
                                        onClick={() => setShowDeleteModal(true)}
                                      >
                                        <XCircle size={24} />
                                      </Button>
                                    )}
                                  </div>
                                )}
                            </div>
                            <ErrorMessage
                            component="div"
                            className="text-danger mb-2 mx-6"
                            name="company_logo"
                            style={{ position: "relative", left:"424px" }}
                          />
                          </div>

                          <Button
                            type="submit"
                            className="mt-4"
                            disabled={isSubmitting}
                          >
                            {isSubmitting ? (
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                            ) : (
                              "Update Profile"
                            )}
                          </Button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </Tab>
                <Tab eventKey="settingdetails" title="Settings">
                  <Formik
                    initialValues={initialPasswordUpdate}
                    validationSchema={passwordSchema}
                    onSubmit={handlePasswordChange}
                  >
                    {({ isSubmitting, resetForm, errors, touched }) => (
                      <Form>
                        <div className="input_head position-relative d-flex align-items-center justify-content-between">
                          <h3 className="float-start m-0 fw-500">Settings</h3>
                        </div>
                        <div className="tablecontent_details col-12 position-relative border-radius-8 mt-4">
                          <h4 className="fw-500">Reset Password</h4>
                          <div className="row justify-content-center align-items-center pt-4 pb-5 ">
                            <div className="col-4">
                              <label className="form-label">
                                <b>Current Password</b>
                              </label>
                              <InputGroup className="mb-3">
                                <Field
                                  name="current_password"
                                  type={
                                    showCurrentPassword ? "text" : "password"
                                  }
                                  as={FormControl}
                                  placeholder="Enter current password"
                                  isInvalid={
                                    errors.current_password &&
                                    touched.current_password
                                  }
                                />
                                <InputGroup.Text
                                  onClick={() =>
                                    setShowCurrentPassword(!showCurrentPassword)
                                  }
                                >
                                  {!showCurrentPassword ? (
                                    <EyeOff size={20} />
                                  ) : (
                                    <Eye size={20} />
                                  )}
                                </InputGroup.Text>
                              </InputGroup>
                              {errors.current_password &&
                                touched.current_password && (
                                  <div className="invalid-feedback d-block">
                                    {errors.current_password}
                                  </div>
                                )}
                            </div>

                            <div className="col-4">
                              <label className="form-label">
                                <b>New Password</b>
                              </label>
                              <InputGroup className="mb-3">
                                <Field
                                  name="new_password"
                                  type={showNewPassword ? "text" : "password"}
                                  as={FormControl}
                                  placeholder="Enter new password"
                                  isInvalid={
                                    errors.new_password && touched.new_password
                                  }
                                />
                                <InputGroup.Text
                                  onClick={() =>
                                    setShowNewPassword(!showNewPassword)
                                  }
                                >
                                  {!showNewPassword ? (
                                    <EyeOff size={20} />
                                  ) : (
                                    <Eye size={20} />
                                  )}
                                </InputGroup.Text>
                              </InputGroup>
                              {errors.new_password && touched.new_password && (
                                <div className="invalid-feedback d-block">
                                  {errors.new_password}
                                </div>
                              )}
                            </div>

                            <div className="col-4">
                              <label className="form-label">
                                <b>Confirm Password</b>
                              </label>
                              <InputGroup className="mb-3">
                                <Field
                                  name="new_password_confirmation"
                                  type={
                                    showConfirmPassword ? "text" : "password"
                                  }
                                  as={FormControl}
                                  placeholder="Enter confirmation password"
                                  isInvalid={
                                    errors.new_password_confirmation &&
                                    touched.new_password_confirmation
                                  }
                                />
                                <InputGroup.Text
                                  onClick={() =>
                                    setShowConfirmPassword(!showConfirmPassword)
                                  }
                                >
                                  {!showConfirmPassword ? (
                                    <EyeOff size={20} />
                                  ) : (
                                    <Eye size={20} />
                                  )}
                                </InputGroup.Text>
                              </InputGroup>
                              {errors.new_password_confirmation &&
                                touched.new_password_confirmation && (
                                  <div className="invalid-feedback d-block">
                                    {errors.new_password_confirmation}
                                  </div>
                                )}
                            </div>

                            <div className="mt-5 px-n0 d-flex">
                              <Button
                                type="submit"
                                className="btn btn-primary w-20 mx-n1"
                                disabled={isSubmitting}
                              >
                                Reset
                              </Button>
                              <Button
                                type="button"
                                className="btn btn-secondary w-20 mx-5"
                                onClick={() => resetForm()}
                                disabled={isSubmitting}
                              >
                                Clear
                              </Button>
                            </div>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </Tab>
              </Tabs>
            </div>
          </Col>
        </Row>
      </Container>
    </CommonLayout>
  );
}
