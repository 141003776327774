import { Row, Col, Card, Button, Toast, ToastContainer, Spinner } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Formik, Field } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { userForgotPassword } from '../../../store/authSlice';
import { ForgotPasswordSchema } from '../../../schema/validationSchemas';
import { CheckCircle } from "react-feather";

const ForgotPassword = () => {
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastVariant, setToastVariant] = useState('success');
  const [coolDown, setCoolDown] = useState(0);
  const dispatch = useDispatch();
  const { status, error } = useSelector((state) => state.auth);

  const calculateCoolDown = () => {
    const lastRequestTime = localStorage.getItem('forgotPasswordLastRequestTime');
    if (lastRequestTime) {
      const elapsed = (Date.now() - lastRequestTime) / 1000;
      const remaining = Math.max(60 - elapsed, 0);
      setCoolDown(remaining);
    }
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const result = await dispatch(userForgotPassword(values)).unwrap();
      if (result && result.success) {
        setToastMessage(result.message || 'Sent link to mail successfully!');
        setToastVariant('success');
        resetForm();
        const now = Date.now();
        localStorage.setItem('forgotPasswordLastRequestTime', now);
        calculateCoolDown();
      } else {
        setToastMessage(result.message || 'Failed to send link to mail!');
        setToastVariant('danger');
        resetForm();
      }
    } catch (err) {
      const errorMessage = err?.message || 'Failed to send link to mail!';
      setToastMessage(errorMessage);
      setToastVariant('danger');
      resetForm();
    } finally {
      setShowToast(true);
      setSubmitting(false);
    }
  };

  useEffect(() => {
    calculateCoolDown();
  }, []);

  useEffect(() => {
    if (error) {
      setToastMessage(error);
      setToastVariant('danger');
      setShowToast(true);
    }

    if (status === 'loading') {
      setToastMessage('Sending link to mail...');
      setToastVariant('warning');
      setShowToast(true);
    }
  }, [status, error]);

  useEffect(() => {
    if (coolDown > 0) {
      const timer = setTimeout(() => setCoolDown(coolDown - 1), 1000);
      return () => clearTimeout(timer);
    }
  }, [coolDown]);

  return (
    <Row className="align-items-center justify-content-center g-0 min-vh-100">
      <Col xxl={4} lg={6} md={8} xs={12} className="py-8 py-xl-0">
        <ToastContainer position="top-end" className="p-3" style={{ zIndex: 1 }}>
            <Toast
                onClose={() => setShowToast(false)}
                show={showToast}
                delay={5000}
                autohide
                bg={toastVariant}
            >
                <Toast.Body style={{ color: "white", display: 'flex', alignItems: 'center' }}>
                    <CheckCircle size={20} color="white" style={{ marginRight: 8 }} />
                    {toastMessage}
                </Toast.Body>
            </Toast>
        </ToastContainer>
        <Card className="smooth-shadow-md">
          <Card.Body className="p-6">
            <div className="mb-4">
              <Link to="/"><img src="/images/brand/logo/logo-primary.svg" className="mb-2" alt="" /></Link>
              <p className="d-flex mb-6 ml-2">Please enter your email</p>
            </div>
            <Formik
              initialValues={{ email: '' }}
              validationSchema={ForgotPasswordSchema}
              onSubmit={handleSubmit}
            >
              {({ errors, touched, isSubmitting, handleChange, handleBlur, handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <label htmlFor="email" className="d-flex form-label">Email</label>
                    <Field
                      id="email"
                      name="email"
                      type="email"
                      placeholder="Enter your email here"
                      className={`form-control ${touched.email && errors.email ? 'is-invalid' : ''}`}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.email && errors.email ? (
                      <div className="invalid-feedback">{errors.email}</div>
                    ) : null}
                  </div>

                  <div className="d-grid w-28 d-flex justify-content-center mt-5">
                    <Button variant="primary" type="submit" disabled={isSubmitting || coolDown > 0}>
                    {isSubmitting ? (
                        <>
                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                        <span className="visually-hidden">Loading...</span>
                        </>
                    ) : (
                        coolDown > 0 ? `Wait ${Math.ceil(coolDown)}s For Next Request` : 'Forgot Password'
                    )}
                    </Button>
                  </div>
                  <div className="d-grid w-28 d-flex justify-content-center mt-3">
                    <Link to="/" className='text-decoration-underline'>Sign In</Link>
                  </div>
                </form>
              )}
            </Formik>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}

export default ForgotPassword;