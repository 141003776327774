import * as Yup from 'yup';

export const loginSchema = Yup.object().shape({
  email: Yup.string()
  .min(1, 'Minimum email length is 1')
  .max(190, 'Maximum email length is 190')
  .matches(
    /^[a-zA-Z0-9_.-]+([@][a-zA-Z0-9_.-]+(\.[a-zA-Z]{2,})+)?$/, 
    'Please enter a valid email or username (usernames must be lowercase and can include numbers and special characters (.-_))'
  )
  .required('Email or Login ID is required'),

  password: Yup.string().min(8, 'Minimum password length is 8').max(30, 'Maximum password length is 30').required('Password is required'),
});

export const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string().min(1, 'Minimum email length is 1').max(190, 'Maximum email length is 190').required('Email is required'),
});

export const ResetPasswordSchema = Yup.object().shape({
  new_password: Yup.string()
    .min(8, 'Minimum password length is 8')
    .max(30, 'Maximum password length is 30')
    .required('New password is required'),
  new_password_confirmation: Yup.string()
    .oneOf([Yup.ref('new_password'), null], 'Passwords must match')
    .required('Confirm password is required'),
});

export const customerSchema = Yup.object().shape({
  organization_name: Yup.string()
  .required('Organization name is required')
  .min(2, 'Organization name should be at least 2 characters')
  .max(50, 'Organization name should not exceed 50 characters'),
  email: Yup.string()
    .email('Invalid email address')
    .required('Email is required')
    .matches(
      /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/,
      'Invalid email format'
    ),
  mobile_number: Yup.string()
      .required('Mobile number is required')
      .matches(/^[0-9]{10}$/, 'Please, Enter 10 digits Mobile number'),
  organization_id: Yup.string()
    .required('Organization type is required'),
  state_id: Yup.string()
    .required('State is required'),
  city_id: Yup.string()
    .required('City is required'),
  expiry_date: Yup.date()
  .typeError('Expiry date must be a date')
  .required('Expiry date is required'),

  

  name: Yup.string()
    .required('Name is required')
    .min(2, 'Name should be at least 2 characters')
    .max(50, 'Name should not exceed 50 characters')
    .matches(/^[a-zA-Z\s\-']+$/, 'Name should not contain numbers or special characters except space, dash, and apostrophe'),
  username: Yup.string()
    .required('Username is required')
    .min(2, 'Username should be at least 2 characters')
    .max(50, 'Username should not exceed 50 characters'),
  password: Yup.string()
    .min(8, 'Minimum password length is 8')
    .max(30, 'Maximum password length is 30')
    .required('Password is required'),
  password_confirmation: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Confirm password is required'),
});

export const editCustomerSchema = Yup.object().shape({
  organization_name: Yup.string()
  .required('Organization name is required')
  .min(2, 'Organization name should be at least 2 characters')
  .max(50, 'Organization name should not exceed 50 characters'),
  email: Yup.string()
    .email('Invalid email address')
    .required('Email is required')
    .matches(
      /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/,
      'Invalid email format'
    ),
  mobile_number: Yup.string()
      .required('Mobile number is required')
      .matches(/^[0-9]{10}$/, 'Please, Enter 10 digits Mobile number'),
  organization_id: Yup.string()
    .required('Organization type is required'),
  state_id: Yup.string()
    .required('State is required'),
  city_id: Yup.string()
    .required('City is required'),
  expiry_date: Yup.date()
  .typeError('Expiry date must be a date')
  .required('Expiry date is required'),

  

  name: Yup.string()
    .required('Name is required')
    .min(2, 'Name should be at least 2 characters')
    .max(50, 'Name should not exceed 50 characters')
    .matches(/^[a-zA-Z\s\-']+$/, 'Name should not contain numbers or special characters except space, dash, and apostrophe'),
  // username: Yup.string()
  //   .required('Username is required')
  //   .min(2, 'Username should be at least 2 characters')
  //   .max(50, 'Username should not exceed 50 characters'),
  // password: Yup.string()
  //   .min(8, 'Minimum password length is 8')
  //   .max(30, 'Maximum password length is 30')
  //   .required('Password is required'),
  // password_confirmation: Yup.string()
  //   .oneOf([Yup.ref('password'), null], 'Passwords must match')
  //   .required('Confirm password is required'),
  is_active: Yup.boolean().required('Status is required')
});

export const profileSchema = Yup.object().shape({
  // organization_name: "",
  //     mobile_number: "",
  //     email: "",
  //     organization_id: "",
  //     city_id:"",
  //     state_id:"",
  //     expiry_date:"",

  //     name: "",
  //     username: "",
  //     password: "",
  //     password_confirmation: "",

  organization_name: Yup.string()
  .required('Organization name is required')
  .min(2, 'Organization name should be at least 2 characters')
  .max(50, 'Organization name should not exceed 50 characters'),
  email: Yup.string()
    .email('Invalid email address')
    .required('Email is required')
    .matches(
      /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/,
      'Invalid email format'
    ),
  mobile_number: Yup.string()
      .required('Mobile number is required')
      .matches(/^[0-9]{8,12}$/, 'Invalid mobile number format'),
  organization_type: Yup.string()
    .required('Organization type is required'),
  state_id: Yup.string()
    .required('State is required'),
  city_id: Yup.string()
    .required('City is required'),
  expiry_date: Yup.date()
  .typeError('Expiry date must be a date'),
  
  name: Yup.string()
    .required('Name is required')
    .min(2, 'Name should be at least 2 characters')
    .max(50, 'Name should not exceed 50 characters')
    .matches(/^[a-zA-Z\s\-']+$/, 'Name should not contain numbers or special characters except space, dash, and apostrophe'),
  username: Yup.string()
    .required('Username is required')
    .min(2, 'Username should be at least 2 characters')
    .max(50, 'Username should not exceed 50 characters'),

});

export const profileUpdateSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email address')
    .required('Email is required')
    .matches(
      /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/,
      'Invalid email format'
    ),
  mobile_number: Yup.string()
      .required('Mobile number is required')
      .matches(/^[0-9]{8,12}$/, 'Invalid mobile number format'),
  
  name: Yup.string()
    .required('Name is required')
    .min(2, 'Name should be at least 2 characters')
    .max(50, 'Name should not exceed 50 characters')
    .matches(/^[a-zA-Z\s\-']+$/, 'Name should not contain numbers or special characters except space, dash, and apostrophe'),
});

export const passwordSchema = Yup.object().shape({
  current_password: Yup.string().required('Current password is required'),
  new_password: Yup.string()
    .min(8, 'Minimum password length is 8')
    .max(30, 'Maximum password length is 30')
    .required('New password is required'),
  new_password_confirmation: Yup.string()
    .oneOf([Yup.ref('new_password'), null], 'Passwords must match')
    .required('Confirm password is required'),
});

export const creditSchema = Yup.object().shape({
  credits: Yup.number()
    .typeError('Credits must be a number')
    .min(1, 'Credits must be at least 1')
    .required('Credits are required'),
  price_per_credits: Yup.number()
    .typeError('Price per credit rate must be a number')
    .min(0.1, 'Price per credit must be greater than 0.1')
    .max(10000000, 'Price per credit must be less than 10000000')
    .required('Price per credit rate is required'),
});

export const creditHistorySchema = Yup.object().shape({
  organizationId: Yup.string()
  .required('Organization is required'),

  credits: Yup.number()
    .typeError('Credits must be a number')
    .min(1, 'Credits must be at least 1')
    .required('Credits are required'),
  price_per_credits: Yup.number()
    .typeError('Price per credit rate must be a number')
    .min(0.1, 'Price per credit must be greater than 0.1')
    .max(10000000, 'Price per credit must be less than 10000000')
    .required('Price per credit rate is required'),
});

export const requestCreditSchema = Yup.object().shape({
  credits: Yup.number()
    .typeError('Credits must be a number')
    .min(1, 'Credits must be at least 1')
    .required('Credits are required'),
});