import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Table,
  Button,
  Toast,
  Container,
  Col,
  Row,
  Dropdown,
  Modal,
} from "react-bootstrap";
import {
  fetchCustomers,
  deleteCustomer,
  fetchCountry,
  toggleCustomerActiveStatus,
} from "../../../store/customerSlice";
// import View from "./View";
import { MoreVertical } from "react-feather";
import PaginationComponent from "../pagination/pagination";
import "./style.css";
import CustomerDetailModal from "./CustomerDetail";
// import { createSelector } from 'reselect';
import { ArrowUp, ArrowDown } from "react-feather";
import { CheckCircle } from "react-feather";
import moment from "moment";
import { useLoader } from "../../../context/LoaderContext";

export default function List({ editHandler, handleOpenModal }) {
  const { setLoading } = useLoader();
  const dispatch = useDispatch();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarVariant, setSnackbarVariant] = useState("success");
  const [currentCustomer, setCurrentCustomer] = useState(null);
  // const [showViewModal, setShowViewModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const limit = 10;
  const [searchTerm, setSearchTerm] = useState("");
  // const firstRender = useRef(true);
  const [showCustomerDetailModal, setShowCustomerDetailModal] = useState(false);
  // const countries = useSelector((state) => state.customer.countries);
  const [sortKey, setSortKey] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const { total, customers } = useSelector((state) => ({
    total: state.customer.total,
    customers: state.customer.customers,
  }));
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [customerToDelete, setCustomerToDelete] = useState({});
  const [showToggleConfirmation, setShowToggleConfirmation] = useState(false);
  const [customerToToggle, setCustomerToToggle] = useState({});
  const [toggleStatus, setToggleStatus] = useState(null);

  // const selectCustomerState = (state) => state.customer;

  // const selectCustomerData = createSelector(
  //   [selectCustomerState],
  //   (customer) => {
  //     return {
  //       total: customer.total,
  //       customers: customer.customers
  //     };
  //   }
  // );

  // const { total, customers } = useSelector(selectCustomerData);

  // useEffect(() => {
  //   if (firstRender.current) {
  //     firstRender.current = false;
  //   } else {
  //     dispatch(fetchCustomers({ offset: currentPage, limit, search: searchTerm, sort_by_key: sortKey, sort_by_value: sortOrder }));
  //     dispatch(fetchCountry());
  //   }
  // }, [dispatch, currentPage, limit, searchTerm, sortKey, sortOrder]);

  const toastMessage = localStorage.getItem("toastMessage");

  useEffect(() => {
    if (toastMessage) {
      setShowSnackbar(true);
      setSnackbarMessage(toastMessage);
      setSnackbarVariant("success" || "danger");
      localStorage.removeItem("toastMessage");
      if (toastMessage === "Validation failed") {
        setShowSnackbar(true);
        setSnackbarMessage(toastMessage);
        setSnackbarVariant("danger");
        localStorage.removeItem("toastMessage");
      }
    }
  }, [toastMessage]);

  const getCustomerList = useCallback(() => {
    setLoading(true);
    Promise.all([
      dispatch(
        fetchCustomers({
          offset: currentPage,
          limit,
          search: searchTerm,
          sort_by_key: sortKey,
          sort_by_value: sortOrder,
        })
      ),
      dispatch(fetchCountry()),
    ])
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    dispatch,
    currentPage,
    limit,
    searchTerm,
    sortKey,
    sortOrder,
    setLoading,
  ]);

  useEffect(() => {
    if (!showCustomerDetailModal) {
      getCustomerList();
    }
  }, [getCustomerList, showCustomerDetailModal]);

  const handlePageChange = (newPageNumber) => {
    setCurrentPage(newPageNumber - 1);
  };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onClick(e);
      }}
      className="text-muted text-primary-hover"
      role="button"
    >
      {children}
    </div>
  ));

  CustomToggle.displayName = "CustomToggle";

  const handleRowClick = (customer) => {
    setCurrentPage(0);
    setSearchTerm("");
    setCurrentCustomer(customer);
    setShowCustomerDetailModal(true);
  };

  const ActionMenu = ({ customer }) => {
    return (
      <Dropdown drop="up" className=" position-static">
        <Dropdown.Toggle as={CustomToggle}>
          <MoreVertical size="15px" className="text-muted" />
        </Dropdown.Toggle>
        <Dropdown.Menu align={"end"}>
          <Dropdown.Item
            eventKey="1"
            onClick={(e) => {
              editHandler(customer.id);
              e.stopPropagation();
            }}
          >
            Edit
          </Dropdown.Item>
          {/* <Dropdown.Item eventKey="2" onClick={() => handleRowClick(customer)}>
            View
          </Dropdown.Item> */}
          <Dropdown.Item
            eventKey="3"
            onClick={(e) => {
              e.stopPropagation();
              promptDeleteCustomer(customer);
            }}
          >
            Delete
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  // const getCountryName = (countryId) => {
  //   const country = countries.find((c) => c.id === countryId);
  //   return country ? country.name : "Not Found";
  // };

  const handleSort = (key) => {
    const isAsc = sortKey === key && sortOrder === "asc";
    setSortKey(key);
    setSortOrder(isAsc ? "desc" : "asc");
  };

  const renderSortIcons = (key) => {
    const isActive = sortKey === key;
    return (
      <>
        <ArrowUp
          size={16}
          onClick={() => handleSort(key, "asc")}
          style={{
            cursor: "pointer",
            color: isActive && sortOrder === "asc" ? "black" : "#ccc",
          }}
        />
        <ArrowDown
          size={16}
          onClick={() => handleSort(key, "desc")}
          style={{
            cursor: "pointer",
            color: isActive && sortOrder === "desc" ? "black" : "#ccc",
          }}
        />
      </>
    );
  };

  const promptDeleteCustomer = (customer) => {
    setCustomerToDelete(customer);
    setShowDeleteConfirmation(true);
  };

  const handleDeleteCustomer = async () => {
    if (customerToDelete) {
      try {
        await dispatch(deleteCustomer(customerToDelete.id));
        setSnackbarMessage("Organization deleted successfully");
        setSnackbarVariant("success");
        dispatch(
          fetchCustomers({
            offset: currentPage * limit,
            limit,
            search: searchTerm,
          })
        );
      } catch (error) {
        setSnackbarMessage("Error deleting customer");
        setSnackbarVariant("danger");
      } finally {
        setShowSnackbar(true);
        setShowDeleteConfirmation(false);
        setCustomerToDelete(null);
      }
    }
  };

  // const promptToggleConfirmation = (customer, status) => {
  //   setCustomerToToggle(customer);
  //   setToggleStatus(status);
  //   setShowToggleConfirmation(true);
  // };

  const handleConfirmToggleActive = () => {
    if (customerToToggle) {
      dispatch(
        toggleCustomerActiveStatus({
          id: customerToToggle.id,
          isActive: toggleStatus.toString(),
        })
      )
        .then(() => {
          setSnackbarMessage(`Organization ${toggleStatus ? "activated" : "deactivated"} successfully`);
          setSnackbarVariant("success");
          getCustomerList();
        })
        .catch((error) => {
          setSnackbarMessage("Failed to update customer status");
          setSnackbarVariant("danger");
        })
        .finally(() => {
          setShowSnackbar(true);
          setShowToggleConfirmation(false);
          setCustomerToToggle(null);
          setToggleStatus(null);
        });
    }
  };

  // const StatusDropdown = ({ customer }) => (
  //   <Dropdown onClick={(e) => e.stopPropagation()}>
  //     <Dropdown.Toggle
  //       size="sm"
  //       variant={customer.is_active ? "success" : "danger"}
  //       id="dropdown-basic"
  //     >
  //       {customer.is_active ? "Active" : "Inactive"}
  //     </Dropdown.Toggle>
  //     <Dropdown.Menu>
  //       <Dropdown.Item
  //         onClick={() =>
  //           promptToggleConfirmation(customer, !customer.is_active)
  //         }
  //       >
  //         Set {customer.is_active ? "Inactive" : "Active"}
  //       </Dropdown.Item>
  //     </Dropdown.Menu>
  //   </Dropdown>
  // );

  return (
    <>
      <Toast
        onClose={() => setShowSnackbar(false)}
        show={showSnackbar}
        delay={3000}
        autohide
        bg={snackbarVariant}
        style={{ position: "fixed", top: 20, right: 20, zIndex: 1100 }}
      >
        <Toast.Body
          style={{ color: "white", display: "flex", alignItems: "center" }}
        >
          <CheckCircle size={20} color="white" style={{ marginRight: 8 }} />
          {snackbarMessage}
        </Toast.Body>
      </Toast>

      <Modal
        show={showDeleteConfirmation}
        onHide={() => setShowDeleteConfirmation(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Delete Organization
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete <b>{customerToDelete?.name}</b> ?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowDeleteConfirmation(false)}
          >
            Cancel
          </Button>
          <Button variant="primary" onClick={() => handleDeleteCustomer()}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showToggleConfirmation}
        onHide={() => setShowToggleConfirmation(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Status Change</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to {toggleStatus ? "activate" : "deactivate"}{" "}
          <b>{customerToToggle?.name}</b> ?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowToggleConfirmation(false)}
          >
            Cancel
          </Button>
          <Button variant="primary" onClick={handleConfirmToggleActive}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="bg-primary pt-10 pb-21"></div>
      <Container fluid className="mt-n22 px-6">
        <Row>
          <Col lg={12} md={12} xs={12}>
            <div>
              <div className="d-flex justify-content-between align-items-center top_title">
                <div className="mb-2 mb-lg-0">
                  <h3 className="mb-0 subhead_title text-white">
                    Organizations List
                  </h3>
                </div>
              </div>
            </div>
          </Col>

          <Col xl={12} lg={12} md={12} xs={12} className="mt-6">
            <div className="position-relative overflow-hidden border-radius-8 tablecontainer">
              <div className="input_btn">
                <input
                  type="search"
                  value={searchTerm}
                  placeholder="Search "
                  style={{ cursor: 'pointer' }}
                  className="searchbar float-start w-20"
                  onChange={(e) => {
                    setSearchTerm(e.target.value);
                    setCurrentPage(0);
                  }}
                />
                <div className="float-end">
                  <Button
                    variant="primary"
                    onClick={() => handleOpenModal(true)}
                  >
                    Add Organization
                  </Button>
                </div>
              </div>

              <div className="table-responsive">
                <div className="row">
                  <Table className="text-nowrap text-start">
                    <thead className="table-light">
                      <tr>
                        <th scope="col" className="fw-500">
                          <div className="d-flex justify-content-between align-items-center">
                            <span>Organization Name {renderSortIcons("organization_name")}</span>
                          </div>
                        </th>
                        <th scope="col" className="fw-500">
                          <div className="d-flex justify-content-between align-items-center">
                            <span>Person Name {renderSortIcons("name")}</span>
                          </div>
                        </th>                       
                        {/* <th scope="col" className="fw-500">
                          <div className="d-flex justify-content-between align-items-center">
                            <span>Country Code</span>
                          </div>
                        </th> */}
                        <th scope="col" className="fw-500">
                          <div className="d-flex justify-content-between align-items-center">
                            <span>
                              Mobile Number {renderSortIcons("mobile_number")}
                            </span>
                          </div>
                        </th>
                        <th scope="col" className="fw-500">
                          <div className="d-flex justify-content-between align-items-center">
                            <span>Email {renderSortIcons("email")}</span>
                          </div>
                        </th>
                        <th scope="col">
                          <div className="d-flex justify-content-between align-items-center">
                            <span>Credits {renderSortIcons("credits")}</span>
                          </div>
                        </th>
                        <th scope="col">
                          <div className="d-flex justify-content-between align-items-center">
                            <span>
                              Expiry Date {renderSortIcons("credits")}
                            </span>
                          </div>
                        </th>
                        {/* <th scope="col">
                          <div className="d-flex justify-content-between align-items-center">
                            <span>Country</span>
                          </div>
                        </th> */}
                        {/* <th scope="col">
                          <div className="d-flex justify-content-between align-items-center">
                            <span>Status</span>
                          </div>
                        </th> */}
                        <th scope="col" className="fw-500">
                          <div className="d-flex justify-content-between align-items-center">
                            <span>
                              Created at {renderSortIcons("created_at")}
                            </span>
                          </div>
                        </th>
                        <th scope="col">
                          <div className="d-flex justify-content-between align-items-center">
                            <span>Action</span>
                          </div>
                        </th>     
                      </tr>
                    </thead>
                    <tbody>
                      {customers.length === 0 ? (
                        <tr>
                          <td colSpan="12" style={{ paddingLeft: "650px" }}>
                            No data available
                          </td>
                        </tr>
                      ) : (
                        customers.map((customer) => (
                          <tr
                            key={customer.id}
                            className="table-row"
                            onClick={() => handleRowClick(customer)}
                          >
                            <td>{customer.organization_name}</td>
                            <td>{customer.name}</td>
                            <td>{customer.mobile_number}</td>
                            <td>{customer.email}</td>
                            <td>{customer?.credits}</td>
                            <td>
                             {moment(customer.expiry_date).format(
                               "DD/MM/YYYY" 
                             )}
                           </td>
                            <td>
                              {moment(customer.created_at).format("DD/MM/YYYY hh:mm A")}
                            </td>
                            {/* <td>{customer.country_code}</td> */}
                            {/* <td>{getCountryName(customer.country_id)}</td> */}
                            {/* <td style={{ minWidth: "136px" }}>
                              <StatusDropdown customer={customer} />
                            </td> */}
                            <td>
                              <ActionMenu customer={customer} />
                            </td>
                           
                          </tr>
                        ))
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
              {total > limit && (
                <PaginationComponent
                  totalItems={total}
                  itemsPerPage={limit}
                  currentPage={currentPage + 1}
                  onPageChange={handlePageChange}
                />
              )}
            </div>
          </Col>
        </Row>
      </Container>

      {currentCustomer && (
        <CustomerDetailModal
          show={showCustomerDetailModal}
          handleCustomerDetailClose={() => setShowCustomerDetailModal(false)}
          editHandler={editHandler}
          customer={currentCustomer}
        />
      )}

      {/* {currentCustomer && (
        <View
          show={showViewModal}
          handleClose={() => setShowViewModal(false)}
          customer={currentCustomer}
        />
      )} */}
    </>
  );
}
