import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./authSlice";
import customerReducer from "./customerSlice";
import walletReducer from "./walletSlice";
import organizationTypeReducer from "./organizationTypeSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    customer: customerReducer,
    wallet: walletReducer,
    organizationType: organizationTypeReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false
  }),
});

export default store;
