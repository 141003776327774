import React from 'react'

export const LogoutIcon = () => {
    return (
        <>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="me-2">
                <path opacity="0.5" d="M7.50195 5.83341C7.51204 4.02089 7.59242 3.0393 8.23274 2.39898C8.96499 1.66675 10.1435 1.66675 12.5005 1.66675H13.3338C15.6908 1.66675 16.8694 1.66675 17.6016 2.39898C18.3338 3.13121 18.3338 4.30972 18.3338 6.66675V13.3334C18.3338 15.6904 18.3338 16.8689 17.6016 17.6012C16.8694 18.3334 15.6908 18.3334 13.3338 18.3334H12.5005C10.1435 18.3334 8.96499 18.3334 8.23274 17.6012C7.59242 16.9608 7.51204 15.9792 7.50195 14.1667" stroke="#161C24" strokeWidth="1.5" strokeLinecap="round" />
                <path d="M12.5003 10H1.66699M1.66699 10L4.58366 7.5M1.66699 10L4.58366 12.5" stroke="#161C24" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </>
    )
}
