import React from 'react'

export const WalletIcon = () => {
    return (
        <>
            <svg width="20" height="20" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg" className="me-2">
                <path opacity="0.4" d="M10.834 8.2915H5.83398" stroke="#161C24" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path opacity="0.4" d="M1.66699 8.29142V4.44146C1.66699 2.74146 3.04199 1.36646 4.74199 1.36646H9.42533C11.1253 1.36646 12.5003 2.42479 12.5003 4.12479" stroke="#161C24" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M14.567 9.16642C14.1503 9.56642 13.9503 10.1831 14.117 10.8164C14.3253 11.5914 15.092 12.0831 15.892 12.0831H16.667V13.2914C16.667 15.1331 15.1753 16.6248 13.3337 16.6248H5.00033C3.15866 16.6248 1.66699 15.1331 1.66699 13.2914V7.45809C1.66699 5.61642 3.15866 4.12476 5.00033 4.12476H13.3337C15.167 4.12476 16.667 5.62476 16.667 7.45809V8.66642H15.767C15.3003 8.66642 14.8753 8.84976 14.567 9.16642Z" stroke="#161C24" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M18.3338 9.51675V11.2334C18.3338 11.7001 17.9505 12.0834 17.4755 12.0834H15.8672C14.9672 12.0834 14.1422 11.4251 14.0672 10.5251C14.0172 10.0001 14.2172 9.50842 14.5672 9.16675C14.8755 8.85008 15.3005 8.66675 15.7672 8.66675H17.4755C17.9505 8.66675 18.3338 9.05008 18.3338 9.51675Z" stroke="#161C24" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </>
    )
}
