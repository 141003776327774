import React, { useState } from "react";
import CommonLayout from "../../layouts/master/index";
import List from "./List";
import Add from "./Add";
import Edit from "./Edit";

export default function Customer() {
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [currentCustomerId, setCurrentCustomerId] = useState(null);

  const handleOpenModal = () => {
    setShowAddModal(true);
  };

  const handleCloseModal = () => {
    setShowAddModal(false);
  };

  return (
    <>
      <CommonLayout>    
        <div>
          <List
            editHandler={(id) => {
              setCurrentCustomerId(id);
              setShowEditModal(true);
            }}
            handleOpenModal={handleOpenModal}
          />
          <Add
            show={showAddModal}
            handleClose={handleCloseModal}
          />
          {showEditModal && (
            <Edit
              show={showEditModal}
              handleClose={() => setShowEditModal(false)}
              customerId={currentCustomerId}
            />
          )}
        
        </div>
      </CommonLayout>
    </>
  );
}
