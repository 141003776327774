import { Row, Col, Card, Button, Toast, ToastContainer, Spinner } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Formik, Field } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { userResetPassword } from '../../../store/authSlice';
import { ResetPasswordSchema } from '../../../schema/validationSchemas';
import { CheckCircle } from "react-feather";

const ResetPassword = () => {
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastVariant, setToastVariant] = useState('success');
  const dispatch = useDispatch();
  const { status, error } = useSelector((state) => state.auth);
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');
  const email = queryParams.get('email');

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const result = await dispatch(userResetPassword(values)).unwrap();
      if (result && result.success) {
        setToastMessage(result.message || 'Reset password successfully!');
        setToastVariant('success');
        resetForm();
        // navigate('/');
      } else {
        setToastMessage(result.message || 'Failed to reset password!');
        setToastVariant('danger');
        // navigate('/');
      }
    } catch (err) {
      const errorMessage = err?.message || 'Failed to reset password!';
      setToastMessage(errorMessage);
      setToastVariant('danger');
    //   navigate('/'); 
    } finally {
      setShowToast(true);
      setSubmitting(false);
    }
  };
  
  useEffect(() => {
    if (error) {
      setToastMessage(error);
      setToastVariant('danger');
      setShowToast(true);
    }

    if (status === 'loading') {
      setToastMessage('Resetting password...');
      setToastVariant('warning');
      setShowToast(true);
    }
  }, [status, error]);

  return (
    <Row className="align-items-center justify-content-center g-0 min-vh-100">
      <Col xxl={4} lg={6} md={8} xs={12} className="py-8 py-xl-0">
        <ToastContainer position="top-end" className="p-3" style={{ zIndex: 1 }}>
            <Toast
                onClose={() => setShowToast(false)}
                show={showToast}
                delay={5000}
                autohide
                bg={toastVariant}
            >
                <Toast.Body style={{ color: "white", display: 'flex', alignItems: 'center' }}>
                    <CheckCircle size={20} color="white" style={{ marginRight: 8 }} />
                    {toastMessage}
                </Toast.Body>
            </Toast>
        </ToastContainer>

        <Card className="smooth-shadow-md">
          <Card.Body className="p-6">
            <div className="mb-4">
              <Link to="/"><img src="/images/brand/logo/logo-primary.svg" className="mb-2" alt="" /></Link>
              <p className="d-flex mb-6 ml-2">Please enter New Password</p>
            </div>
            <Formik
              initialValues={{ email: email || '', token: token || '', new_password: '', new_password_confirmation: '' }}
              validationSchema={ResetPasswordSchema}
              onSubmit={handleSubmit}
            >
              {({ errors, touched, isSubmitting, handleChange, handleBlur, handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  {/* <div className="mb-3">
                    <label htmlFor="email" className="d-flex form-label">Email</label>
                    <Field
                      id="email"
                      name="email"
                      type="email"
                      placeholder="Enter your email here"
                      className={`form-control ${touched.email && errors.email ? 'is-invalid' : ''}`}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.email && errors.email ? (
                      <div className="invalid-feedback">{errors.email}</div>
                    ) : null}
                  </div> */}

                  <div className="mb-3">
                    <label htmlFor="new_password" className="d-flex form-label">Password</label>
                    <Field
                      id="new_password"
                      name="new_password"
                      type="password"
                      placeholder=""
                      className={`form-control ${touched.new_password && errors.new_password ? 'is-invalid' : ''}`}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.new_password && errors.new_password ? (
                      <div className="invalid-feedback">{errors.new_password}</div>
                    ) : null}
                  </div>

                  <div className="mb-3">
                    <label htmlFor="new_password_confirmation" className="d-flex form-label">Confirm Password</label>
                    <Field
                      id="new_password_confirmation"
                      name="new_password_confirmation"
                      type="password"
                      placeholder=""
                      className={`form-control ${touched.new_password_confirmation && errors.new_password_confirmation ? 'is-invalid' : ''}`}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.new_password_confirmation && errors.new_password_confirmation ? (
                      <div className="invalid-feedback">{errors.new_password_confirmation}</div>
                    ) : null}
                  </div>

                  <div className="d-grid w-28 d-flex justify-content-center mt-5">
                    <Button variant="primary" type="submit" disabled={isSubmitting}>
                      {isSubmitting ? (
                        <>
                          <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                          <span className="visually-hidden">Loading...</span>
                        </>
                      ) : (
                        'Reset Password'
                      )}
                    </Button>
                  </div>
                  <div className="d-grid w-28 d-flex justify-content-center mt-3">
                    <Link to="/" className='text-decoration-underline'>Sign In</Link>
                  </div>
                </form>
              )}
            </Formik>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}

export default ResetPassword;