import React, { useEffect, useState, useCallback } from "react";
import "./style.css";
import { Row, Col, 
  Dropdown, 
  Modal, Table, Badge, ModalBody, Button, Toast, ToastContainer, ButtonGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
// import { MoreVertical } from "react-feather";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCustomerDetail,
  fetchCustomerCredits,
  fetchCustomerEvents,
  fetchCustomerCreditDetail,
  fetchCustomerCreditLogBooks,
  deleteCustomer,
  fetchCustomers,
  toggleCustomerActiveStatus
} from "../../../store/customerSlice";
import PaginationComponent from "../pagination/pagination";
import DateRangePicker from "react-bootstrap-daterangepicker";
import ClientDetailModal from "./ClientDetail";
import { createSelector } from 'reselect';
import { ArrowUp, ArrowDown } from 'react-feather';
import Credit from "./Credit";
import {useLoader} from '../../../context/LoaderContext';
import moment from "moment";
import {
  Cardholder,
  MoneyWavy,
  StackMinus,
  Coins,
  StackPlus,
  Pencil,
  Trash,
  CheckCircle,
  Warning,
  X, UserCheck, DotsThreeVertical
} from "../../../constants/icons/icons";

export default function CustomerDetailModal({ show, customer, handleCustomerDetailClose, editHandler }) {
  const { setLoading } = useLoader();
  const dispatch = useDispatch();
  // const countries = useSelector((state) => state.customer.countries);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentCreditPage, setCurrentCreditPage] = useState(0);
  const limit = 10;

  const customerDetails = useSelector(
    (state) => state.customer.currentCustomer
  );
  const customerCreditDetail = useSelector(
    (state) => state.customer.currentCreditDetail
  );
  const [searchTerm, setSearchTerm] = useState("");
  const [currentEvent, setCurrentEvent] = useState(null);
  const [showClientDetailModal, setShowClientDetailModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [sortClientKey, setSortClientKey] = useState('');
  const [sortClientOrder, setSortClientOrder] = useState('asc');
  const [sortCreditKey, setSortCreditKey] = useState('');
  const [sortCreditOrder, setSortCreditOrder] = useState('desc');
  const [activeTab, setActiveTab] = useState('clientlist');
  const [currentCreditHistoryPage, setCurrentCreditHistoryPage] = useState(0);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [customerToDelete, setCustomerToDelete] = useState({});
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarVariant, setSnackbarVariant] = useState("success");
  const [showToggleConfirmation, setShowToggleConfirmation] = useState(false);
  const [openCustomerDetailDropdown, setOpenCustomerDetailDropdown] = useState({
    id: null,
    type: null,
  }); 
  const handleShow = () => setShowModal(true);
  const handleClose = () => {
    setCurrentCreditPage(0);
    setShowModal(false);
  }

  const [dateRange, setDateRange] = useState({
    startDate: moment().subtract(29, "days"),
    endDate: moment(),
  });

  const selectClientState = (state) => state.customer.customerEvents;
  const selectCreditState = (state) => state.customer.customerCredits;
  const selectCreditLogState = (state) => state.customer.customerCreditLogBooks;

  const selectClientData = createSelector(
    [selectClientState],
    (customerEvents) => {
      return {
        total: customerEvents.total,
        events: customerEvents.data
      };
    }
  );

  const selectCreditStates = createSelector(
    [selectCreditState],
    (customerCredits) => {
      return {
        totalCredit: customerCredits.total,
        credits: customerCredits.data
      };
    }
  );

  const selectCreditLogStates = createSelector(
    [selectCreditLogState],
    (creditLogBooks) => {
      return {
        creditLogBooksData: creditLogBooks.data,
        creditLogBooks: creditLogBooks,
      };
    }
  );

  const { total, events } = useSelector(selectClientData);
  const { totalCredit, credits } = useSelector(selectCreditStates);
  const { creditLogBooksData, creditLogBooks } = useSelector(
    selectCreditLogStates
  );

  // const firstRender = useRef(true);

  // useEffect(() => {
  //   if (firstRender.current) {
  //     firstRender.current = false;
  //   } else {
  //     dispatch(fetchCustomerCreditDetail(customer.id));
  //     dispatch(fetchCustomerDetail(customer.id));
  //     dispatch(
  //       fetchCustomerCredits({
  //         id: customer.id,
  //         offset: currentPage,
  //         limit,
  //         search: searchTerm,
  //         sort_by_key: sortKey, 
  //         sort_by_value: sortOrder
  //       })
  //     );
  //     dispatch(
  //       fetchCustomerEvents({
  //         id: customer.id,
  //         offset: currentPage,
  //         limit,
  //         search: searchTerm,
  //         sort_by_key: sortKey, 
  //         sort_by_value: sortOrder
  //       })
  //     );
  //   }
  // }, [dispatch, customer, id, currentPage, limit, searchTerm, sortKey, sortOrder ]);
// 
const getEventsList = useCallback(() => {
  setLoading(true);
  Promise.all([ dispatch(fetchCustomerCreditDetail(customer.id)),
    dispatch(fetchCustomerDetail(customer.id)),

  
  ]).then(() => {
    setLoading(false);
  }).catch(() => {
    setLoading(false); 
  }).finally(() => {
    setLoading(false);
  });
},[ dispatch, customer, setLoading ]);

// const getClientDetail = useCallback(async () => {
//   Promise.all([
//     dispatch(
//       fetchCustomerEvents({
//         id: customer.id,
//         offset: currentPage,
//         limit,
//         search: searchTerm,
//         sort_by_key: sortClientKey, 
//         sort_by_value: sortClientOrder
//       })
//     )
//   ]).then(() => {
//     setLoading(false);
//   }).catch(() => {
//     setLoading(false); 
//   }).finally(() => {
//     setLoading(false);
//   });

// },[dispatch, customer, setLoading, currentPage, limit, searchTerm, sortClientKey, sortClientOrder]);

useEffect(() => {
  getEventsList();
}, [getEventsList]);

  // const getCountryName = (countryId) => {
  //   const country = countries.find((c) => c.id === countryId);
  //   return country ? country.name : "Not Found";
  // };

  // const ActionMenu = ({ event }) => {
  //   return (
  //     <Dropdown drop="up" className=" position-static">
  //       <Dropdown.Toggle as={CustomToggle}>
  //         <MoreVertical size="15px" className="text-muted" />
  //       </Dropdown.Toggle>
  //       <Dropdown.Menu align={"end"}>
  //         <Dropdown.Item eventKey="1" onClick={() => handleRowClick(event)}>
  //           View
  //         </Dropdown.Item>
  //       </Dropdown.Menu>
  //     </Dropdown>
  //   );
  // };
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onClick(e);
      }}
      className="text-muted text-primary-hover"
    >
      {children}
    </Link>
  ));

  CustomToggle.displayName = "CustomToggle";

  const handlePageChange = (newPageNumber) => {
    setCurrentPage(newPageNumber - 1);
  };

  const handleCreditPageChange = (newPageNumber) => {
    setCurrentCreditPage(newPageNumber - 1);
  };  

  const handleCreditHistoryPageChange = (newPageNumber) => {
    setCurrentCreditHistoryPage(newPageNumber - 1);
  };

  const handleRowClick = (event) => {
    setCurrentPage(0);
    setCurrentCreditPage(0);
    setCurrentCreditHistoryPage(0);
    setSearchTerm("");
    setCurrentEvent(event);
    setShowClientDetailModal(true);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); 
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  };

  const handleCloseModal = () => {
    setSearchTerm("");
    setCurrentPage(0);
    setCurrentCreditPage(0);
    handleCustomerDetailClose(); 
    setCurrentCreditHistoryPage(0);
    setLoading(false); 
    setDateRange({
      startDate: moment().subtract(29, "days"),
      endDate: moment(),
    });
  };

  const handleEvent = (event, picker) => {
    setDateRange({
      startDate: picker.startDate,
      endDate: picker.endDate,
    });
  };

  const handleCustomerSort = (key) => {
    const isAsc = sortClientKey === key && sortClientOrder === 'asc';
    setSortClientKey(key);
    setSortClientOrder(isAsc ? 'desc' : 'asc');
  };

  const handleCreditSort = (key) => {
    const isAsc = sortCreditKey === key && sortCreditOrder === 'asc';
    setSortCreditKey(key);
    setSortCreditOrder(isAsc ? 'desc' : 'asc');
  };

  const renderSortCustomerIcons = (key) => {
    const isActive = sortClientKey === key;
    return (
      <>
        <ArrowUp
          size={16}
          onClick={() => handleCustomerSort(key, 'asc')}
          style={{ cursor: 'pointer', color: isActive && sortClientOrder === 'asc' ? 'black' : '#ccc' }}
        />
        <ArrowDown
          size={16}
          onClick={() => handleCustomerSort(key, 'desc')}
          style={{ cursor: 'pointer', color: isActive && sortClientOrder === 'desc' ? 'black' : '#ccc' }}
        />
      </>
    );
  };

  const renderSortCreditIcons = (key) => {
    const isActive = sortClientKey === key;
    return (
      <>
        <ArrowUp
          size={16}
          onClick={() => handleCreditSort(key, 'asc')}
          style={{ cursor: 'pointer', color: isActive && sortCreditOrder === 'asc' ? 'black' : '#ccc' }}
        />
        <ArrowDown
          size={16}
          onClick={() => handleCreditSort(key, 'desc')}
          style={{ cursor: 'pointer', color: isActive && sortCreditOrder === 'desc' ? 'black' : '#ccc' }}
        />
      </>
    );
  };

  const fetchClientDetailsTab = useCallback(() => {
    setLoading(true);
    setDateRange({
      startDate: moment().subtract(29, "days"),
      endDate: moment(),
    });
    dispatch(
      fetchCustomerEvents({
        id: customer.id,
        offset: currentPage,
        limit,
        search: searchTerm,
        sort_by_key: sortClientKey, 
        sort_by_value: sortClientOrder
      })
    )
    .finally(() => {
      setLoading(false);
    });
}, [dispatch, customer.id, currentPage, limit, searchTerm, sortClientKey, sortClientOrder, setLoading]);

  const fetchCreditDetailsTab = useCallback(() => {
    setLoading(true);
    setDateRange({
      startDate: moment().subtract(29, "days"),
      endDate: moment(),
    });
    dispatch(
      fetchCustomerCredits({
        id: customer.id,
        offset: currentCreditPage,
        limit,
        sort_by_key: "created_at", 
        sort_by_value: sortCreditOrder
      })
    )
    .finally(() => {
      setLoading(false);
    });
  }, [dispatch, customer.id, currentCreditPage, limit, sortCreditOrder, setLoading]);

  const fetchCreditHistoryTab = useCallback(() => {
    setLoading(true);

    dispatch(
      fetchCustomerCreditLogBooks({
        customer_id: customer.id,
        offset: currentCreditHistoryPage,
        start_date: dateRange.startDate.format("YYYY/MM/DD"),
        end_date: dateRange.endDate.format("YYYY/MM/DD"),
      })
    ).finally(() => {
      setLoading(false);
    });
  }, [dispatch, customer.id, dateRange, setLoading, currentCreditHistoryPage]);

  useEffect(() => {
    if(activeTab === "rechargeCreditHistory") {
      fetchCreditDetailsTab();
    }
  }, [fetchCreditDetailsTab, activeTab]);

  useEffect(() => {
    if (activeTab === "creditHistory") {
      fetchCreditHistoryTab();
    }
  }, [fetchCreditHistoryTab, activeTab]);

  useEffect(() => {
    fetchClientDetailsTab();
  }, [fetchClientDetailsTab]); 


  const handleTabSelect = (key) => {
    setActiveTab(key);
    switch (key) {
      case "clientlist":
        setCurrentCreditHistoryPage(0);
        setSearchTerm("");
        setCurrentPage(0);
        setCurrentCreditPage(0);
        fetchClientDetailsTab();
        break;
      case "rechargeCreditHistory":
        setCurrentCreditPage(0);
        setCurrentCreditHistoryPage(0);
        fetchCreditDetailsTab();
        break;
      case "creditHistory":
        setCurrentCreditPage(0);
        setCurrentCreditHistoryPage(0);    
        fetchCreditHistoryTab();
        break;
      default:
        break;
    }
  };

  const promptDeleteCustomer = (customer) => {
    setCustomerToDelete(customer);
    setShowDeleteConfirmation(true);
  };

  const handleDeleteCustomer = async () => {
    if (customerToDelete) {
      try {
        await dispatch(deleteCustomer(customerToDelete.id));
        setSnackbarMessage("Customer deleted successfully");
        setSnackbarVariant("success");
        dispatch(
          fetchCustomers({
            offset: currentPage * limit,
            limit,
            search: searchTerm,
          })
        );
        handleCloseModal();
      } catch (error) {
        setSnackbarMessage("Error deleting customer");
        setSnackbarVariant("danger");
      } finally {
        setShowSnackbar(true);
        setShowDeleteConfirmation(false);
        setCustomerToDelete(null);
      }
    }
  };

  const handleDropdownToggleCustomerDetail = (id, type) => {
    if (
      openCustomerDetailDropdown.id === id &&
      openCustomerDetailDropdown.type === type
    ) {
      // Close the currently open dropdown
      setOpenCustomerDetailDropdown({ id: null, type: null });
    } else {
      // Open the new dropdown and close any others
      setOpenCustomerDetailDropdown({ id: id, type: type });
    }
  };

  const ActionMenuCustomerDetail = ({
    customer,
    isCustomerDetailOpen,
    toggleMenuCustomerDetail,
  }) => (
    <Dropdown
      drop="up"
      as={ButtonGroup} 
      className="action-dropdown w-100"
      style={{display:"flex", justifyContent:"flex-end", position:"relative", top:"-15px"}}
      show={isCustomerDetailOpen}
      onToggle={() => handleDropdownToggleCustomerDetail(customer.id, "action")}
    >
      <Dropdown.Toggle
        id={`dropdown-split-basic-${customer.id}`}
        className="p-0"
        variant="link"
        as={CustomToggle}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          toggleMenuCustomerDetail(customer.id);
        }}
      >
        <DotsThreeVertical size={17} color="#637381" weight="bold" />
      </Dropdown.Toggle>
      <Dropdown.Menu align="end" className="action-dr-menu z-1">
        <Dropdown.Item
          eventKey="1"
          className="d-flex align-items-center dr-item item-orange"
          onClick={(e) => {
            e.stopPropagation();
            editHandler(customer.id);
          }}
        >
          <span>
            <Pencil size={15} color="#637381" weight="regular" />
          </span>
          <p className="mb-0 mx-2">Edit</p>
        </Dropdown.Item>
        <Dropdown.Item
          eventKey="2"
          className="d-flex align-items-center dr-item item-red"
          onClick={(e) => {
            e.stopPropagation();
            promptDeleteCustomer(customer);
            toggleMenuCustomerDetail(null); 
          }}
        >
          <span>
            <Trash size={15} color="#637381" weight="regular" />
          </span>
          <p className="mb-0 mx-2">Delete</p>
        </Dropdown.Item>

        <Dropdown.Item
          className="dr-menu-item"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            promptToggleConfirmation(customer, !customer.is_active);
            toggleMenuCustomerDetail(null);
          }}
        >
          <span>
            <UserCheck size={15} color="#637381" weight="regular" />
          </span>
          <span className="mx-2">Make {customerDetails?.is_active ? "Inactive" : "Active"}</span>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  ); 

  const promptToggleConfirmation = (customer, status) => {
    // setCustomerToToggle(customer);
    // setToggleStatus(status);
    setShowToggleConfirmation(true);
  };

  const handleConfirmToggleActive = async () => {
    if (customerDetails) {
      try {
        const value = customerDetails.is_active;
        const response = await dispatch(
          toggleCustomerActiveStatus({
            id: customerDetails.id,
            isActive: value ? "false" : "true",
          })
        );

        if (response.payload) {
          getEventsList();
        }
        setSnackbarMessage(
          `Organization ${
            customerDetails?.is_active ? "deactivated" : "activate"
          } successfully`
        );
        setSnackbarVariant("success");
      } catch (error) {
        setSnackbarMessage("Failed to update Organization status");
        setSnackbarVariant("danger");
      } finally {
        setShowSnackbar(true);
        setShowToggleConfirmation(false);
        // setCustomerToToggle(null);
        // setToggleStatus(null);
      }
    }
  };

  // const calculateBalances = (creditLogBooksData) => {
  //   let balance = 0;
  //   const updatedLogs = creditLogBooksData?.slice()?.reverse().map((log) => {
  //     const credit = log.is_credited ? log.credits : 0;
  //     const debit = !log.is_credited ? log.credits : 0;
  //     balance += credit - debit;
  //     return { ...log, balance }; 
  //   });
  //     return updatedLogs?.reverse(); 
  //   };
  
    // const creditLogsWithBalances = calculateBalances(creditLogBooksData);
    // console.log(creditLogsWithBalances);

  return (
    <>
    <ToastContainer position="top-end" className=" pt-3 pe-3 cu-toster">
        <Toast
          onClose={() => setShowSnackbar(false)}
          show={showSnackbar}
          delay={3000}
          autohide
          bg={snackbarVariant}
        >
          <Toast.Body className="d-flex align-items-center gap-2 text-white">
            <CheckCircle size={22} color="#ffffff" weight="duotone" />
            <p className="mb-0">{snackbarMessage}</p>
          </Toast.Body>
        </Toast>
      </ToastContainer>

      <Modal
        show={showToggleConfirmation}
        onHide={() => setShowToggleConfirmation(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Status Change</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to{" "}
          {customerDetails?.is_active ? "deactivate" : "activate"}{" "}
          <b>{customerDetails?.name}</b> ?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowToggleConfirmation(false)}
          >
            Cancel
          </Button>
          <Button variant="primary" onClick={handleConfirmToggleActive}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showDeleteConfirmation}
        onHide={() => setShowDeleteConfirmation(false)}
        dialogClassName="modal-dialog-scrollable add-customer-popup"
        centered
      >
        <Modal.Header className="add-customer-header d-inline-block p-0  border-bottom-0">
          <div className="d-flex align-items-center justify-content-between gap-3 br-bottom py-3 px-4">
            <h5 className="fw-semibold mb-0">Delete Organization</h5>
            <div className="cursor-pointer rounded-circle d-flex align-items-center justify-content-center x-arrow">
              <X
                size={18}
                color=""
                weight="bold"
                onClick={() => setShowDeleteConfirmation(false)}
              />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className="py-4 delete-customer-content">
          <div className="d-flex flex-column gap-3 justify-content-center align-items-center">
            <div className="rounded-2 d-flex align-items-center justify-content-center error-show-icon">
              <Warning size={46} color="#dc3545" weight="regular" />
            </div>
            <p className="mb-0 fw-medium text-center">
              Are you sure you want to delete <b>{customerToDelete?.name}</b>.
            </p>
          </div>
        </Modal.Body>

        <Modal.Footer className="d-flex align-items-center justify-content-center pt-2  pb-4 px-4 border-top-0 add-customer-footer">
          <Button
            variant="link"
            className="close-btn fw-medium m-0"
            onClick={() => setShowDeleteConfirmation(false)}
          >
            Cancel
          </Button>
          <Button
            variant="link"
            className="next-btn fw-medium m-0"
            onClick={() => handleDeleteCustomer()}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
          show={show}        
          onHide={handleCloseModal}
        fullscreen={true}
        className="modal-fullscreen bg-lightgray"
      >
        <Modal.Header closeButton>
          <Modal.Title>Organization Details</Modal.Title>
        </Modal.Header>
        <ModalBody>
        <div className="row">
          <div className="col-4">
            <Col xl={12} lg={12} md={12} xs={12} className="mt-6" >
              <div className="customer_details" style={{height:"400px"}}>
                <div className="personal_details pb-3">
                <h5 className="mb-n4 me-2">Organization Information</h5> 
                </div>
                <div>
                    <ActionMenuCustomerDetail
                      customer={customer}
                      isCustomerDetailOpen={
                        openCustomerDetailDropdown.id === customer.id &&
                        openCustomerDetailDropdown.type === "action"
                      }
                      toggleMenuCustomerDetail={() =>
                        handleDropdownToggleCustomerDetail(customer.id, "action")
                      }
                    />
                    </div>
                  {/* <div className="border-1 separtor line_small"></div> */}
                  <div className="mt-n6">
                  <div className="py-3">
                      {
                          customerDetails.is_active ?
                          <Badge pill bg="success">
                            Active  
                            </Badge>:
                            <Badge pill bg="danger">
                              Inactive
                            </Badge>
                            }
                        {/* <StatusDropdown
                          reseller={reseller}
                          isOpen={
                            openResellerDetailDropdown.id === reseller.id &&
                            openResellerDetailDropdown.type === "status"
                          }
                          toggleDropdown={() =>
                            handleDropdownToggleResellerDetail(
                              reseller.id,
                              "status"
                            )
                          }
                        /> */}
                      </div>
                    <div className="d-flex detail_person pb-2">
                      <span className="user_name">Organization Name </span>
                      <span className="part_name">:</span>
                      <span className="detail_name">
                        {customerDetails.organization_name}
                      </span>
                    </div>
                    <div className="d-flex detail_person pb-2">
                      <span className="user_name">Email </span>
                      <span className="part_name">:</span>
                      <span className="detail_name">
                        {customerDetails.email}
                      </span>
                    </div>
                    <div className="d-flex detail_person pb-2">
                      <span className="user_name">Mobile No.</span>
                      <span className="part_name">:</span>
                      <span className="detail_name">
                        {customerDetails?.mobile_number}
                      </span>
                    </div>
                    <div className="d-flex detail_person pb-2">
                      <span className="user_name">State</span>
                      <span className="part_name">:</span>
                      <span className="detail_name">
                        {customerDetails?.state?.name}
                      </span>
                    </div>
                    <div className="d-flex detail_person pb-2">
                      <span className="user_name">City</span>
                      <span className="part_name">:</span>
                      <span className="detail_name">
                        {customerDetails?.city?.name}
                      </span>
                    </div>
                    <div className="d-flex detail_person pb-2">
                      <span className="user_name">Created on</span>
                      <span className="part_name fs-16">:</span>
                      <span className="detail_name fs-16">
                        {moment(customerDetails.created_at).format("DD/MM/YYYY hh:mm A")}
                      </span>
                    </div>
                    <div className="d-flex detail_person pb-2">
                      <span className="user_name">Updated on</span>
                      <span className="part_name fs-16">:</span>
                      <span className="detail_name fs-16">
                        {moment(customerDetails.updated_at).format("DD/MM/YYYY hh:mm A")}
                      </span>
                    </div>
                    <div className="d-flex detail_person pb-2">
                      <span className="user_name">Expires on</span>
                      <span className="part_name fs-16">:</span>
                      <span className="detail_name fs-16">
                        {moment(customerDetails.expiry_date).format("DD/MM/YYYY")}
                      </span>
                    </div>
                  </div>
                
              </div>

              <div className="customer_details mt-5">
                <div className="personal_details pb-3">
                  <h5>User Details</h5>
                  <div className="pt-3">
                    <div className="d-flex detail_person pb-2">
                      <span className="user_name">Person Name</span>
                      <span className="part_name fs-16">:</span>
                      <span className="detail_name fs-16">{customerDetails?.name}</span>
                    </div>
                    <div className="d-flex detail_person pb-2">
                      <span className="user_name">Login ID</span>
                      <span className="part_name fs-16">:</span>
                      <span className="detail_name fs-16">{customerDetails?.username}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="customer_details mt-5">
                <div className="personal_details pb-3">
                  <h5>Credits Details</h5>
                  <div className="float-end my-n7">
                    <Button variant="primary" onClick={handleShow}>
                      Add Credit
                    </Button>
                  </div>
                  <div className="pt-3">
                    <div className="d-flex detail_person pb-2">
                      <span className="user_name">Total Credits</span>
                      <span className="part_name fs-16">:</span>
                      <span className="detail_name fs-16">{customerCreditDetail?.total_credits}</span>
                    </div>
                    <div className="d-flex detail_person pb-2">
                      <span className="user_name">Available Credits</span>
                      <span className="part_name fs-16">:</span>
                      <span className="detail_name fs-16">{customerCreditDetail?.available_credits}</span>
                    </div>
                    <div className="d-flex detail_person pb-2">
                      <span className="user_name">Reserved Credits</span>
                      <span className="part_name fs-16">:</span>
                      <span className="detail_name fs-16">{customerCreditDetail?.reserve_credits}</span>
                    </div>
                    <div className="d-flex detail_person pb-2">
                      <span className="user_name">Total Recharge Amount</span>
                      <span className="part_name fs-16">:</span>
                      <span className="detail_name fs-16"> ₹{customerCreditDetail?.total_recharge_amount}</span>
                    </div>
                    <div className="d-flex detail_person pb-2">
                      <span className="user_name">Last Credit alloted</span>
                      <span className="part_name fs-16">:</span>
                      <span className="detail_name fs-16">{
                              customerCreditDetail?.last_recharge_history
                                ?.credits ?(
                                  <td>{customerCreditDetail?.last_recharge_history?.credits}</td>
                                ):
                                (
                                  <td>-</td>
                                )
                            }</span>
                    </div>
                    <div className="d-flex detail_person pb-2">
                      <span className="user_name">Last Recharge Date</span>
                      <span className="part_name fs-16">:</span>
                      <span className="detail_name fs-16">{moment(
                              customerCreditDetail?.last_recharge_history
                                ?.created_at
                            ).format("DD/MM/YYYY hh:mm A")}</span>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </div>

          <div className="col-8">
            <Col xl={12} lg={12} md={12} xs={12} className="mt-6">
              <div className="tablecontainer">
                <Tabs
                  defaultActiveKey="clientlist"
                  onSelect={handleTabSelect}
                  className="mb-5 border-0 tablist_data"
                  id="uncontrolled-tab-example"
                >
                  <Tab
                    className="row customer_data"
                    eventKey="clientlist"
                    title="Customer Events"
                  >
                    <div className="input_head position-relative d-flex align-items-center justify-content-between">
                      <h5 className="float-start m-0">Customer Events</h5>
                      <input
                        type="search"
                        placeholder="Search"
                        className="searchbar float-end"
                        style={{ cursor: 'pointer' }}
                        value={searchTerm}
                        onChange={(e) => {
                          setSearchTerm(e.target.value);
                          setCurrentPage(0);
                        }}
                      />
                    </div>
                    <div className="table-responsive">
                      <div className="row">
                      <Table className="text-nowrap text-start ">
                        <thead className="table-light">
                          <tr>
                            <th scope="col">
                              <div className="d-flex justify-content-between align-items-center">
                              <span>Name {renderSortCustomerIcons('name')}</span>
                              </div>
                            </th>
                            <th scope="col">
                              <div className="d-flex justify-content-between align-items-center">
                              <span>Mobile Number {renderSortCustomerIcons('mobile_number')}</span>
                              </div>
                            </th>
                            <th scope="col">
                              <div className="d-flex justify-content-between align-items-center">
                              <span>Campaigns</span>
                              </div>
                            </th>
                            <th scope="col">
                              <div className="d-flex justify-content-between align-items-center">
                              <span>Created On</span>
                              </div>
                            </th>
                            <th scope="col">
                              <div className="d-flex justify-content-between align-items-center">
                                <span>WhatsApp Status</span>
                              </div>
                            </th>
                            {/* <th scope="col">
                              <div className="d-flex justify-content-between align-items-center">
                                <span>Action</span>
                              </div>
                            </th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {events?.length > 0 ? (
                            events.map((event, i) => (
                              <tr
                                key={i}
                                className="table-row"
                                onClick={() => handleRowClick(event)}
                              >
                                <td>{event.name}</td>
                                {event.contact_number ? (
                                    <td>
                                      {event.contact_number}
                                    </td>
                                  ) : (
                                    <td>-</td>
                                  )}                                
                                <td>{event.campaigns_count}</td>    
                                <td>
                                    {moment(event.created_at).format(
                                      "DD/MM/YYYY hh:mm A"
                                    )}
                                  </td>                           
                               <td>
                                  {event.whatsapp_client.is_active ? (
                                    <Badge pill bg="success">
                                      Connected
                                    </Badge>
                                  ) : (
                                    <Badge pill bg="danger">
                                      Not Connected
                                    </Badge>
                                  )}
                                </td>
                                {/* <td>
                                  <ActionMenu event={event} />
                                </td> */}
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="7" className="text-center">
                                No data available
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                      </div>
                    </div>
                     {total > limit && (    
                         <PaginationComponent
                          totalItems={total}
                          itemsPerPage={limit}
                          currentPage={currentPage + 1}
                          onPageChange={handlePageChange}
                        />
                     )}
                  </Tab>
                  <Tab
                    className="row customer_data"
                    eventKey="rechargeCreditHistory"
                    title="Recharge History"
                  >
                    <div className="input_head position-relative d-flex align-items-center justify-content-between">
                      <h5 className="float-start m-0">Recharge History</h5>
                    </div>
                    <div style={{marginBottom: "-35px"}}>
                      <Button variant="primary" onClick={handleShow} style={{position: "relative", left: "950px", bottom: "68px"}}>
                        Add Credit
                      </Button>
                    </div>
                    <div className="table-responsive">
                    <div className="row">                      
                      <Table className="text-nowrap text-start ">
                        <thead className="table-light">
                          <tr>
                            <th scope="col">
                              <div className="d-flex justify-content-between align-items-center">
                              <span>Purchase Date {renderSortCreditIcons('created_at')}</span>
                              </div>
                            </th>
                            <th scope="col">
                              <div className="d-flex justify-content-between align-items-center">
                                <span>Credit Rate</span>
                              </div>
                            </th>
                            <th scope="col">
                              <div className="d-flex justify-content-between align-items-center">
                              <span>Credits {renderSortCreditIcons('credits')}</span>
                              </div>
                            </th>
                            <th scope="col">
                              <div className="d-flex justify-content-between align-items-center">
                              <span>Total Price {renderSortCreditIcons('price')}</span>
                              </div>
                            </th>
                            <th scope="col">
                              <div className="d-flex justify-content-between align-items-center">
                                <span>Status</span>
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody className="credit_data">
                          {credits?.length > 0 ? (
                            credits.map((credit, i) => (
                              <tr key={i} className="data_row">
                                <td>{ formatDate(credit.created_at) }</td>
                                <td>{`₹${credit.credit_rate}`}</td>
                                <td>{credit.credits}</td>
                                <td>{`₹${credit.price}`}</td>
                                <td>
                                  {credit.status === "completed" ? (
                                    <Badge pill bg="success" >
                                      Completed
                                    </Badge>
                                  ) : credit.status === "rejected" ? (
                                    <Badge pill bg="danger" >
                                      Rejected
                                    </Badge>
                                  ) : (
                                    <Badge pill bg="primary">
                                    Requested
                                  </Badge>
                                  )}
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="7" className="text-center">
                                No data available
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                      </div>
                    </div>
                    {totalCredit > limit  && (    
                          <PaginationComponent
                          totalItems={totalCredit}
                          itemsPerPage={limit}
                          currentPage={currentCreditPage + 1}
                          onPageChange={handleCreditPageChange}
                        />
                     )}
                  </Tab>
                  <Tab
                      eventKey="creditHistory"
                      title={
                        <>
                          <div className="d-flex align-items-center gap-2">
                            <Cardholder
                              size={22}
                              color="#637381"
                              weight="regular"
                            />
                            <p className="mb-0">Credit History</p>
                          </div>
                        </>
                      }
                    >
                      <div className="d-flex align-items-center justify-content-between my-4 table-tit-head">
                        <h3 className="m-0">Credit History</h3>

                        <div className="mr-5">
                        <DateRangePicker
                          initialSettings={{
                            startDate: dateRange.startDate.toDate(),
                            endDate: dateRange.endDate.toDate(),
                            ranges: {
                              Today: [moment(), moment()],
                              Yesterday: [
                                moment().subtract(1, "days"),
                                moment().subtract(1, "days"),
                              ],
                              "Last 7 Days": [
                                moment().subtract(6, "days"),
                                moment(),
                              ],
                              "Last 30 Days": [
                                moment().subtract(29, "days"),
                                moment(),
                              ],
                              "This Month": [
                                moment().startOf("month"),
                                moment().endOf("month"),
                              ],
                              "Last Month": [
                                moment().subtract(1, "month").startOf("month"),
                                moment().subtract(1, "month").endOf("month"),
                              ],
                              "Last 3 Months": [
                                moment().subtract(3, "month").startOf("month"),
                                moment().subtract(1, "month").endOf("month"),
                              ],
                              "Last 6 Months": [
                                moment().subtract(6, "month").startOf("month"),
                                moment().subtract(1, "month").endOf("month"),
                              ],
                              "This Year": [
                                moment().startOf("year"),
                                moment().endOf("year"),
                              ]
                            },
                            locale: {
                              format: "YYYY-MM-DD",
                            },
                          }}
                          onApply={(e, _) => handleEvent(e, _)}
                        >
                          <button className="btn btn-primary">
                            {dateRange.startDate.format("DD/MM/YYYY")} -{" "}
                            {dateRange.endDate.format("DD/MM/YYYY")}
                          </button>
                        </DateRangePicker>
                        </div>
                      </div>
                      <div className="">
                        <Row className="my-5 g-4 cerdit-history-content">
                          <Col xxl={2} xl={2} lg={2} md={2} xs={12} >
                            <div className="p-4 rounded-3 cedit-h-card h-100" style={{borderStyle: "groove", width:"246px"}}>
                              <div className="d-flex align-items-start justify-content-between gap-3">
                                <div>
                                  <p className="mb-0 text-capitalize">
                                    Opening Balance
                                  </p>
                                  <span>
                                    {creditLogBooks.opening_balance || 0}
                                  </span>
                                </div>
                                <div className="credit-h-icon d-flex align-items-center justify-content-center rounded-1">
                                  <Coins
                                    size={20}
                                    color="#624BFF"
                                    weight="duotone"
                                  />
                                </div>
                              </div>
                            </div>
                          </Col>
                          <Col xxl={1} xl={1} lg={1} md={1} xs={1} className="d-flex justify-content-center align-items-center">
                          <i className="fas fa-plus-circle fa-1x" style={{ color: "#624BFF", marginLeft:"82px" }}></i>
                        </Col>
                          <Col xxl={2} xl={2} lg={2} md={2} xs={12}>
                            <div className="p-4 rounded-3 cedit-h-card  h-100" style={{borderStyle: "groove", width:"246px"}}>
                              <div className="d-flex align-items-start justify-content-between gap-3">
                                <div>
                                  <p className="text-capitalize">
                                    Purchase Credit
                                  </p>
                                  <span>
                                    {creditLogBooks.total_credits || 0}
                                  </span>
                                </div>
                                <div className="credit-h-icon d-flex align-items-center justify-content-center rounded-1">
                                  <StackPlus
                                    size={20}
                                    color="#624BFF"
                                    weight="duotone"
                                  />
                                </div>
                              </div>
                            </div>
                          </Col>
                          <Col xxl={1} xl={1} lg={1} md={1} xs={1} className="d-flex justify-content-center align-items-center">
                          <i className="fas fa-minus-circle fa-1x" style={{ color: "#624BFF", marginLeft:"82px" }}></i>
                        </Col>
                          <Col xxl={2} xl={2} lg={2} md={2} xs={12}>
                            <div className="p-4 rounded-3 cedit-h-card  h-100" style={{borderStyle: "groove", width:"246px"}}>
                              <div className="d-flex align-items-start justify-content-between gap-3">
                                <div>
                                  <p className="text-capitalize">Selling Credit</p>
                                  <span>
                                    {creditLogBooks.total_debits || 0}
                                  </span>
                                </div>
                                <div className="credit-h-icon d-flex align-items-center justify-content-center rounded-1">
                                  <StackMinus
                                    size={20}
                                    color="#624BFF"
                                    weight="duotone"
                                  />
                                </div>
                              </div>
                            </div>
                          </Col>
                          <Col xxl={1} xl={1} lg={1} md={1} xs={1} className="d-flex justify-content-center align-items-center">
                            <i className="fas fa-equals fa-1x" style={{ color: "#624BFF", marginLeft:"82px" }}></i>
                          </Col>
                          <Col xxl={2} xl={2} lg={2} md={2} xs={12}>
                            <div className="p-4 rounded-3 cedit-h-card  h-100" style={{borderStyle: "groove", width:"246px"}}>
                              <div className="d-flex align-items-start justify-content-between gap-3">
                                <div>
                                  <p className="text-capitalize">
                                    Closing Balance
                                  </p>
                                  <span>
                                    {creditLogBooks.closing_balance || 0}
                                  </span>
                                </div>
                                <div className="credit-h-icon d-flex align-items-center justify-content-center rounded-1">
                                  <MoneyWavy
                                    size={20}
                                    color="#624BFF"
                                    weight="duotone"
                                  />
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                   <div className="table-responsive rounded-2">
                      <Table className="text-nowrap text-start ">
                        <thead className="table-light">
                          <tr>
                            <th scope="col">
                              <div className="d-flex justify-content-between align-items-center">
                              <span>Date </span>
                              </div>
                            </th>
                            <th scope="col">
                              <div className="d-flex justify-content-between align-items-center">
                                <span>Description</span>
                              </div>
                            </th>
                            <th scope="col">
                              <div className="d-flex justify-content-between align-items-center">
                              <span>Message Credit</span>
                              </div>
                            </th>
                            <th scope="col">
                              <div className="d-flex justify-content-between align-items-center">
                              <span>Message Debit</span>
                              </div>
                            </th>
                            <th scope="col">
                              <div className="d-flex justify-content-between align-items-center">
                              <span>Balance</span>
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody className="credit_data">
                        {creditLogBooksData?.length > 0 ? (
                                creditLogBooksData.map((credit, i) => (
                                  <tr key={i} className="align-middle data_row">
                                    <td>
                                      {moment(credit.created_at).format(
                                        "DD/MM/YYYY hh:mm A"
                                      )}
                                    </td>
                                    <td>
                                      {credit?.description}
                                    </td>
                                    {credit.is_credited && credit.credits ? (
                                      <td>
                                        {credit.is_credited && credit.credits}
                                      </td>
                                    ) : (
                                      <td>0</td>
                                    )}
                                    {!credit.is_credited && credit.credits ? (
                                      <td>
                                        {!credit.is_credited && credit.credits}
                                      </td>
                                    ) : (
                                      <td>0</td>
                                    )}
                                    <td>
                                    {credit?.opening_balance
                                          // ? `${credit?.is_credited? credit.opening_balance + credit.credits : credit.opening_balance - credit.credits}`
                                          ?credit.opening_balance
                                          : "-"}
                                      </td>                                  
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td
                                    colSpan="6"
                                    className="text-center border-bottom-0"
                                  >
                                    <p className="my-5  no-data text-capitalize">
                                      No data available
                                    </p>
                                  </td>
                                </tr>
                              )}
                        </tbody>
                         <tfoot className="table-light">
                              <tr>
                                <td>
                                  <div className="d-flex align-items-center gap-th">
                                    <p className="mb-0"><strong>Total</strong></p>
                                  </div>
                                </td>
                                <td></td>
                                <td>
                                  <div className="d-flex align-items-center gap-th">
                                    <p className="mb-0">
                                      <strong>{creditLogBooks.total_credits}</strong>
                                    </p>
                                  </div>
                                </td>
                                <td>
                                  <div className="d-flex align-items-center gap-th">
                                    <p className="mb-0">
                                      <strong>{creditLogBooks.total_debits}</strong>
                                    </p>
                                  </div>
                                </td>
                                <td>
                                  <div className="d-flex align-items-center gap-th">
                                    <p className="mb-0">
                                      <strong>{}</strong>
                                    </p>
                                  </div>
                                </td>
                              </tr>
                            </tfoot>
                      </Table>
                      {
                            creditLogBooks?.total > limit && (
                              <div className="pt-4">
                                <PaginationComponent
                                  totalItems={creditLogBooks?.total}
                                  itemsPerPage={limit}
                                  currentPage={currentCreditHistoryPage + 1}
                                  onPageChange={handleCreditHistoryPageChange}
                                />
                              </div>
                            )
                          }
                      </div>
                    </div>
                    </Tab>
                </Tabs>
              </div>
            </Col>
          </div>
        </div>
        </ModalBody>
      </Modal>
      {currentEvent && (
        <ClientDetailModal
          show={showClientDetailModal}
          handleClose={() => setShowClientDetailModal(false)}
          event={currentEvent}
        />
      )}
      {customerDetails && customerDetails.company_id && (
          <Credit
            show={showModal}
            handleClose={handleClose}
            companyId={customerDetails.company_id}
            customerId={customerDetails.id}
            itemsPerPage={limit}
          />
      )}
    </>
  );
}
