import React from 'react';
// import { Menu } from 'react-feather';
// import { Link } from 'react-router-dom';
import { Nav, Navbar } from 'react-bootstrap';
import { useSelector } from "react-redux";

import NavbarTopMenu from './NavbarTopMenu';

const NavbarTop = (props) => {
	const credits = useSelector((state) => state.auth.user?.credits)
	return (
		<Navbar expanded="lg" className="navbar-classic navbar navbar-expand-lg">
			<div className='d-flex justify-content-between w-100'>
				<div className="d-flex align-items-center">
					{/* <Link
						to="#"
						id="nav-toggle"
						className="nav-icon me-2 icon-xs"
						onClick={() => props.data.SidebarToggleMenu(!props.data.showMenu)}>
						<Menu size="18px" />
					</Link> */}
					{/* <div className="ms-lg-3 d-none d-md-none d-lg-block">
						<Form className="d-flex align-items-center">
							<Form.Control type="search" placeholder="Search" />
						</Form>
					</div> */}
				</div>
				<div className="d-flex align-items-center justify-content-center">
					{credits < 100 && (
						<div className="alert alert-warning mb-0 me-3 p-2" role="alert" style={{ padding: '4px 8px', fontSize: '14px' }}>
						Your credits are below 100. Please top up soon!
						</div>	
					)}
					<Nav className="navbar-right-wrap ms-2 d-flex nav-top-wrap">
						<NavbarTopMenu />
					</Nav>
					</div>
			</div>
		</Navbar>
	);
};

export default NavbarTop;
