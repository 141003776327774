import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Table,
  Toast,
  Container,
  Col,
  Row,
  // Dropdown,
  Badge, Tabs, Tab, Modal
} from "react-bootstrap";
import {
  fetchRequestedCredits,
} from "../../../store/customerSlice";
// import View from "./View";
// import { MoreVertical } from "react-feather";
import PaginationComponent from "../pagination/pagination";
import "../customer/style.css";
import Edit from "./Edit";
// import { createSelector } from 'reselect';
import { CheckCircle } from "react-feather";
import moment from "moment";
import { useLoader } from "../../../context/LoaderContext";
import CommonLayout from "../../layouts/master/index"

export default function List() {
  const { setLoading } = useLoader();
  const dispatch = useDispatch();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarVariant, setSnackbarVariant] = useState("success");
  const [currentPage, setCurrentPage] = useState(0);
  const limit = 10;
  const [searchTerm, setSearchTerm] = useState("");
  const [activeTab, setActiveTab] = useState(null);
  const { total, requestedCredits } = useSelector((state) => ({
    total: state.customer.requestedCredits?.total || 0,
    requestedCredits: state.customer?.requestedCredits.data || [],
  }));

  const [showEditModal, setShowEditModal] = useState(false);
  const [currentRequestedCreditId, setCurrentRequestedCreditId] = useState(null);
  const [descriptionModalShow, setDescriptionModalShow] = useState(false);
  const [fullDescription, setFullDescription] = useState("");

  // const handleEditOpen = (id) => {
  //   setCurrentRequestedCreditId(id);
  //   setShowEditModal(true);
  // };

  const handleRowClick = (requestedCredit) => {
    setCurrentPage(0);
    setSearchTerm("");
    setCurrentRequestedCreditId(requestedCredit);
    setShowEditModal(true);
  };

  const toastMessage = localStorage.getItem("toastMessage");

  useEffect(() => {
    if (toastMessage) {
      setShowSnackbar(true);
      setSnackbarMessage(toastMessage);
      setSnackbarVariant("success" || "danger");
      localStorage.removeItem("toastMessage");
      if (toastMessage === "Validation failed") {
        setShowSnackbar(true);
        setSnackbarMessage(toastMessage);
        setSnackbarVariant("danger");
        localStorage.removeItem("toastMessage");
      }
    }
  }, [toastMessage]);

  const getRequestedCreditList = useCallback(() => {
    setLoading(true);
    Promise.all([
        dispatch(
          fetchRequestedCredits({
          offset: currentPage,
          limit,
          search: searchTerm,
          status:activeTab,
          sortOrder: "desc",
          sortKey: "id",
        })),
    ])
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    dispatch,
    currentPage,
    limit,
    searchTerm,
    setLoading,
    activeTab,
  ]);

  useEffect(() => {
    getRequestedCreditList();
  }, [getRequestedCreditList]);

  const handlePageChange = (newPageNumber) => {
    setCurrentPage(newPageNumber - 1);
  };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onClick(e);
      }}
      className="text-muted text-primary-hover"
      role="button"
    >
      {children}
    </div>
  ));

  CustomToggle.displayName = "CustomToggle";

  // const ActionMenu = ({ requestedCredit }) => {
  //   return (
  //     <Dropdown drop="up" className=" position-static">
  //       <Dropdown.Toggle as={CustomToggle}>
  //         <MoreVertical size="15px" className="text-muted" />
  //       </Dropdown.Toggle>
  //       <Dropdown.Menu align={"end"}>
  //         <Dropdown.Item
  //           eventKey="1"
  //           onClick={(e) => {
  //               e.stopPropagation();
  //               handleEditOpen(requestedCredit?.id)
  //             }}
  //         >
  //           Edit
  //         </Dropdown.Item>
  //       </Dropdown.Menu>
  //     </Dropdown>
  //   );
  // };

  const handleTabSelect = (key) => {
    if (key === "") {
      setCurrentPage(0);
      setActiveTab(null); 
    } else {
      setCurrentPage(0);
      setActiveTab(key);
    }
  
    setCurrentPage(0); 
  };
  

  const handleClose = ()=>{
    setShowEditModal(false);
  }

  const handleReadMore = (description) => {
    setFullDescription(description);
    setDescriptionModalShow(true);
  };

  return (
    <>
    <CommonLayout>
      <Toast
        onClose={() => setShowSnackbar(false)}
        show={showSnackbar}
        delay={3000}
        autohide
        bg={snackbarVariant}
        style={{ position: "fixed", top: 20, right: 20, zIndex: 1000 }}
      >
        <Toast.Body
          style={{ color: "white", display: "flex", alignItems: "center" }}
        >
          <CheckCircle size={20} color="white" style={{ marginRight: 8 }} />
          {snackbarMessage}
        </Toast.Body>
      </Toast>

      <div className="bg-primary pt-10 pb-21"></div>
      <Container fluid className="mt-n22 px-6">
        <Row>
          <Col lg={12} md={12} xs={12}>
            <div>
              <div className="d-flex justify-content-between align-items-center top_title">
                <div className="mb-2 mb-lg-0">
                  <h3 className="mb-0 subhead_title text-white">
                    Requested Credits
                  </h3>
                </div>
              </div>
            </div>
          </Col>

          <Col xl={12} lg={12} md={12} xs={12} className="mt-6">
            <div className="position-relative overflow-hidden border-radius-8 tablecontainer">
              <div className="input_btn">
                <input
                  type="text"
                  value={searchTerm}
                  placeholder="Search "
                  className="searchbar float-start w-20"
                  onChange={(e) => {
                    setSearchTerm(e.target.value);
                    setCurrentPage(0);
                  }}
                />
              </div>

              <div className="position-relative overflow-hidden rounded-2 bg-white table-tabs mb-3">
                    <Tabs
                      defaultActiveKey=""
                      className="pb-0 table-tabs-items"
                      id="uncontrolled-tab-example"
                      onSelect={handleTabSelect}
                    >
                      <Tab
                        eventKey=""
                        title={
                          <>
                            <div className="d-flex align-items-center gap-2">
                              <p className="mb-0">All</p>
                            </div>
                          </>
                        }
                      ></Tab>
                      <Tab
                        eventKey="placed"
                        title={
                          <>
                            <div className="d-flex align-items-center gap-2">
                              <p className="mb-0">Requested</p>
                            </div>
                          </>
                        }
                      ></Tab>
                      <Tab
                        eventKey="completed"
                        title={
                          <>
                            <div className="d-flex align-items-center gap-2">
                              <p className="mb-0">Completed</p>
                            </div>
                          </>
                        }
                      ></Tab>
                      <Tab
                        eventKey="rejected"
                        title={
                          <>
                            <div className="d-flex align-items-center gap-2">
                              <p className="mb-0">Rejected</p>
                            </div>
                          </>
                        }
                      ></Tab>
                    </Tabs>
                  </div>

              <div className="table-responsive">
                <div className="row">
                  <Table className="text-nowrap text-start">
                    <thead className="table-light">
                      <tr>
                        <th scope="col" className="fw-500">
                          <div className="d-flex justify-content-between align-items-center">
                            <span>Organization Name </span>
                          </div>
                        </th>
                        <th scope="col" className="fw-500">
                          <div className="d-flex justify-content-between align-items-center">
                            <span>Credits </span>
                          </div>
                        </th>                       
                        {/* <th scope="col" className="fw-500">
                          <div className="d-flex justify-content-between align-items-center">
                            <span>Country Code</span>
                          </div>
                        </th> */}
                        <th scope="col" className="fw-500">
                          <div className="d-flex justify-content-between align-items-center">
                            <span>
                            Credit Rate
                            </span>
                          </div>
                        </th>
                        <th scope="col" className="fw-500">
                          <div className="d-flex justify-content-between align-items-center">
                            <span>Total Price</span>
                          </div>
                        </th>
                        <th scope="col" className="fw-500">
                          <div className="d-flex justify-content-between align-items-center">
                            <span>Description</span>
                          </div>
                        </th>
                        <th scope="col">
                          <div className="d-flex justify-content-between align-items-center">
                            <span>Status</span>
                          </div>
                        </th>
                        <th scope="col">
                          <div className="d-flex justify-content-between align-items-center">
                            <span>Create On</span>
                          </div>
                        </th>
                        {/* <th scope="col">
                          <div className="d-flex justify-content-between align-items-center">
                            <span>Action</span>
                          </div>
                        </th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {requestedCredits?.length === 0 ? (
                        <tr>
                          <td colSpan="12" style={{ paddingLeft: "650px" }}>
                            No data available
                          </td>
                        </tr>
                      ) : (
                        requestedCredits.map((requestedCredit) => (
                            <tr
                            key={requestedCredit?.id}
                            className={`position-relative ${
                              requestedCredit.status === "placed" ? "cursor-pointer" : ""
                            }`}                           
                            onClick={() => {
                              if (requestedCredit.status === "placed") {
                                handleRowClick(requestedCredit.id); 
                              }
                            }}                          >
                            <td>{requestedCredit?.customer_details?.organization_name}</td>
                            <td>{requestedCredit.credits}</td>
                            <td>{`₹${requestedCredit.credit_rate}`}</td>
                            <td>{`₹${requestedCredit.price}`}</td>
                            <td>
                              {requestedCredit.description?.length > 30 ? (
                                <>
                                  {requestedCredit.description.substring(0, 30)}...
                                  <span style={{ color: 'blue', cursor: 'pointer' }} onClick={(e) => {
                                      e.stopPropagation();
                                      handleReadMore(requestedCredit.description);
                                    }}>
                                      Read More
                                    </span>
                                </>
                              ) : (
                                requestedCredit.description || "-"
                              )}
                              </td>                             
                              <td>
                              {requestedCredit.status === "completed" ? (
                                <Badge pill bg="success">
                                  Completed
                                </Badge>
                              ) : requestedCredit.status === "rejected" ? (
                                <Badge pill bg="danger">
                                  Rejected
                                </Badge>
                              ) : (
                                <Badge pill bg="primary">
                                  Requested
                                </Badge>
                              )}
                            </td>
                            <td>
                                {moment(requestedCredit.created_at).format("DD/MM/YYYY hh:mm A")}
                            </td>                        
                            <td>
                            {/* {(requestedCredit.status === "placed") ? (
                              <ActionMenu requestedCredit={requestedCredit} />
                             ) : (
                            <span className="text-muted">No actions available</span>
                          )} */}
                            
                            </td>
                           
                          </tr>
                        ))
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
              {total > limit && (
                <PaginationComponent
                  totalItems={total}
                  itemsPerPage={limit}
                  currentPage={currentPage + 1}
                  onPageChange={handlePageChange}
                />
              )}
            </div>
          </Col>
        </Row>
      </Container>

      <Modal show={descriptionModalShow} onHide={() => setDescriptionModalShow(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Description</Modal.Title>
        </Modal.Header>
        <Modal.Body>{fullDescription}</Modal.Body>
      </Modal>

      <Edit
          show={showEditModal}
          handleModalClose={()=>{
            getRequestedCreditList();
            handleClose();
          }}
          requestedCreditId={currentRequestedCreditId}
          handleClose={handleClose}
        />
        </CommonLayout>
    </>
  );
}
