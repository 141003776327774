import React, { useEffect, useState, useCallback } from "react";
import "./style.css";
import { Col, Modal, Tab, Tabs, Button, Row } from "react-bootstrap";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCampaignDetail,
  fetchGuestDetails,
} from "../../../store/customerSlice";
import PaginationComponent from "../pagination/pagination";
import { createSelector } from "reselect";
import { ArrowUp, ArrowDown } from "react-feather";
import { useLoader } from "../../../context/LoaderContext";
import moment from "moment";
import axios from "axios";
import { pdfjs, Document, Page } from "react-pdf";
import { ChevronLeft, ChevronRight } from "react-feather";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function GuestDetailModal({ show, campaign, handleClose }) {
  const { setLoading } = useLoader();
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(0);
  const limit = 10;
  const [searchTerm, setSearchTerm] = useState("");
  // const firstRender = useRef(true);
  // const clientDetail = useSelector((state) => state.customer.currentClient);
  const campaignDetail = useSelector((state) => state.customer.currentCampaign);
  const [sortKey, setSortKey] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [activeTab, setActiveTab] = useState("guests");
  const [documentUrl, setDocumentUrl] = useState(null);
  const [pdfTotalPages, setPdfTotalPages] = useState(0);
  const [pdfCurrentPage, setPdfCurrentPage] = useState(1);

  const campaignDetailData = useSelector((state) => state.customer?.guests);

  const selectGuestState = (state) => state.customer?.guests;

  const selectGuestData = createSelector([selectGuestState], (guests) => {
    return {
      total: guests.total,
      guests: guests.data,
    };
  });

  const { total, guests } = useSelector(selectGuestData);

  // useEffect(() => {
  //   if (firstRender.current) {
  //     firstRender.current = false;
  //   } else {
  //     dispatch(fetchCampaignDetail(campaign.id));
  //     dispatch(
  //       fetchGuestDetails({
  //         id: campaign.id,
  //         offset: currentPage,
  //         limit,
  //         search: searchTerm,
  //         sort_by_key: sortKey,
  //         sort_by_value: sortOrder
  //       })
  //     );
  //   }
  // }, [dispatch, campaign, id, currentPage, limit, searchTerm, sortKey, sortOrder]);

  const getGuestList = useCallback(() => {
    setLoading(true);
    Promise.all([
      dispatch(fetchCampaignDetail(campaign.id)),
      dispatch(
        fetchGuestDetails({
          id: campaign.id,
          offset: currentPage,
          limit,
          search: searchTerm,
          sort_by_key: sortKey,
          sort_by_value: sortOrder,
          is_test: activeTab === "guests" ? "false" : "true",
        })
      ),
    ])
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    dispatch,
    campaign,
    currentPage,
    limit,
    searchTerm,
    sortKey,
    sortOrder,
    setLoading,
    activeTab,
  ]);

  useEffect(() => {
    getGuestList();
  }, [getGuestList]);

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="text-muted text-primary-hover"
    >
      {children}
    </Link>
  ));

  CustomToggle.displayName = "CustomToggle";

  const handlePageChange = (newPageNumber) => {
    setCurrentPage(newPageNumber - 1);
  };

  const handleSort = (key) => {
    const isAsc = sortKey === key && sortOrder === "asc";
    setSortKey(key);
    setSortOrder(isAsc ? "desc" : "asc");
  };

  const renderSortIcons = (key) => {
    const isActive = sortKey === key;
    return (
      <>
        <ArrowUp
          size={16}
          onClick={() => handleSort(key, "asc")}
          style={{
            cursor: "pointer",
            color: isActive && sortOrder === "asc" ? "black" : "#ccc",
          }}
        />
        <ArrowDown
          size={16}
          onClick={() => handleSort(key, "desc")}
          style={{
            cursor: "pointer",
            color: isActive && sortOrder === "desc" ? "black" : "#ccc",
          }}
        />
      </>
    );
  };

  const handleCloseModal = () => {
    setSearchTerm("");
    setCurrentPage(0);
    handleClose();
    setLoading(false);
    setActiveTab("guests");
  };

  const handleTabSelect = (key) => {
    setActiveTab(key);

    switch (key) {
      case "guests":
        // setSearchTerm("");
        setCurrentPage(0);
        // setCurrentCreditPage(0);
        // fetchCustomerDetailsTab();
        break;

      case "test":
        // setCurrentCreditPage(0);
        // fetchCreditDetailsTab();
        break;

      default:
        break;
    }
  };

  const fetchDocument = useCallback(
    async (url) => {
      try {
        setLoading(true);
        const response = await axios.get(url, { responseType: "blob" });
        const blobUrl = URL.createObjectURL(response.data);

        setDocumentUrl(blobUrl);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching document: ", error);
        setLoading(false);
      }
    },
    [setLoading]
  );

  const fetchCampaignByIdData = useCallback(() => {
    setLoading(true);
    dispatch(fetchCampaignDetail(campaign.id))
      .then((response) => {
        if (response.payload && response.payload?.template_url) {
          fetchDocument(response.payload?.new_template_url);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch, campaign.id, setLoading, fetchDocument]);

  useEffect(() => {
    fetchCampaignByIdData();
  }, [fetchCampaignByIdData]);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setPdfTotalPages(numPages);
    setPdfCurrentPage(1);
  };

  const goToNextPage = () => {
    setPdfCurrentPage((prevPage) => Math.min(prevPage + 1, pdfTotalPages));
  };

  const goToPreviousPage = () => {
    setPdfCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleStatusClick = (status) => {
    setLoading(true);
    // setIsFailedSelected(status === "failed");
    const fetchParams = {
      offset: currentPage,
      limit,
      search: searchTerm,
      is_test: activeTab === "guests" ? "false" : "true",
      id: campaign.id,
    };

    if (status !== "all") {
      fetchParams.status = status;
    }

    dispatch(fetchGuestDetails(fetchParams)).finally(() => {
      setLoading(false);
    });
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleCloseModal}
        fullscreen={true}
        className="modal-fullscreen bg-lightgray"
      >
        <Modal.Header closeButton>
          <Modal.Title>Campaign Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={4} md={4} lg={4} xl={4} className="mt-6">
              <div className="d-flex  flex-column gap-4">
                <div className="customer_details rounded-3 w-100">
                  <div className="personal_details pb-3">
                    <h5 className="">Campaign Information</h5>
                    <div className="border-1 separtor line_small"></div>
                    <div className="pt-3">
                      <div className="d-flex detail_person pb-2">
                        <span className="user_name">Name </span>
                        <span className="part_name">:</span>
                        <span className="detail_name">
                          {campaignDetail.name}
                        </span>
                      </div>
                      <div className="d-flex detail_person pb-2">
                        <span className="user_name">Message</span>
                        <span className="part_name">:</span>
                        <span className="detail_name">
                          {campaignDetail.message}
                        </span>
                      </div>
                      <div className="d-flex detail_person pb-2">
                        <span className="user_name">Status</span>
                        <span className="part_name">:</span>
                        <span className="detail_name">
                          {campaignDetail.status}
                        </span>
                      </div>
                      {/* <div className="d-flex detail_person pb-2">
                        <span className="user_name">Mobile No.</span>
                        <span className="part_name">:</span>
                        <span className="detail_name">
                          {clientDetail?.whatsapp_client?.mobile_number}
                        </span>
                      </div> */}
                      <div className="d-flex detail_person pb-2">
                        <span className="user_name">Created on</span>
                        <span className="part_name fs-16">:</span>
                        <span className="detail_name fs-16">
                          {moment(campaignDetail.created_at).format(
                            "DD/MM/YYYY hh:mm A"
                          )}
                        </span>
                      </div>
                      <div className="d-flex detail_person pb-2">
                        <span className="user_name">Updated on</span>
                        <span className="part_name fs-16">:</span>
                        <span className="detail_name fs-16">
                          {moment(campaignDetail.updated_at).format(
                            "DD/MM/YYYY hh:mm A"
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="customer_details rounded-3 w-100">
                  <div className="personal_details pb-3">
                    <h5 className="">Document</h5>
                    <div className="border-1 separtor line_small"></div>
                    <div className="document-viewer mt-3">
                      {documentUrl ? (
                        campaign?.template_type === "pdf" ? (
                          <>
                            <Document
                              file={documentUrl}
                              onLoadSuccess={onDocumentLoadSuccess}
                              onLoadError={(error) =>
                                console.log(
                                  "Error while loading document:",
                                  error
                                )
                              }
                              onSourceError={(error) =>
                                console.log(
                                  "Error while fetching document source:",
                                  error
                                )
                              }
                              className="d-flex justify-content-center"
                            >
                              <Page
                                pageNumber={pdfCurrentPage}
                                renderTextLayer={false}
                                renderAnnotationLayer={false}
                              />
                            </Document>
                            <div className="d-flex justify-content-center mb-3">
                              <Button
                                variant="outline-primary"
                                className="me-2"
                                onClick={goToPreviousPage}
                                disabled={pdfCurrentPage === 1}
                              >
                                <ChevronLeft />
                              </Button>
                              <div className="d-flex align-items-center">
                                Page {pdfCurrentPage} of {pdfTotalPages}
                              </div>
                              <Button
                                variant="outline-primary"
                                className="ms-2"
                                onClick={goToNextPage}
                                disabled={pdfCurrentPage === pdfTotalPages}
                              >
                                <ChevronRight />
                              </Button>
                            </div>
                          </>
                        ) : campaignDetail?.template_type?.startsWith(
                            "image"
                          ) ? (
                          <img
                            src={documentUrl}
                            alt="Campaign Document"
                            style={{ maxWidth: "100%" }}
                          />
                        ) : (
                          <p>Unsupported document type</p>
                        )
                      ) : (
                        <p>No document available</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Col>

            <Col xs={8} md={8} lg={8} xl={8} className="mt-6">
              <div className="tablecontainer m-0 rounded-3">
                <div className="input_head position-relative d-flex align-items-center justify-content-between">
                  <h3 className="float-start m-5">User Guest List</h3>
                  <input
                    type="search"
                    placeholder="Search"
                    className="searchbar float-end"
                    style={{ cursor: 'pointer' }}
                    onChange={(e) => {
                      setSearchTerm(e.target.value);
                      setCurrentPage(0);
                    }}
                  />
                </div>

                <Row className="my-5 g-4 cerdit-logs-content">
                  <Col xxl={2} xl={4} lg={4} md={6} xs={12}>
                    <div
                      className="p-4 rounded-3 cedit-h-card h-100 cursor-pointer"
                      style={{
                        borderStyle: "groove",
                      }}
                      onClick={() => handleStatusClick("all")}
                    >
                      <div className="d-flex align-items-start justify-content-between gap-3">
                        <div>
                          <p className="mb-0 text-capitalize">
                            Total Recipients
                          </p>
                          <span>
                            {activeTab === "guests"
                              ? campaignDetailData?.total_non_tested
                              : campaignDetailData?.total_tested || 0}
                          </span>
                        </div>
                        <div className="credit-h-icon d-flex align-items-center justify-content-center rounded-1"></div>
                      </div>
                    </div>
                  </Col>
                  <Col xxl={2} xl={4} lg={4} md={6} xs={12}>
                    <div
                      className="p-4 rounded-3 cedit-h-card  h-100 cursor-pointer"
                      style={{
                        borderStyle: "groove",
                      }}
                      onClick={() => handleStatusClick("pending")}
                    >
                      <div className="d-flex align-items-start justify-content-between gap-3">
                        <div>
                          <p className="text-capitalize">In Queue</p>
                          <span>{campaignDetailData?.total_pending || 0}</span>
                        </div>
                        <div className="credit-h-icon d-flex align-items-center justify-content-center rounded-1"></div>
                      </div>
                    </div>
                  </Col>
                  <Col xxl={2} xl={4} lg={4} md={6} xs={12}>
                    <div
                      className="p-4 rounded-3 cedit-h-card  h-100 cursor-pointer"
                      style={{
                        borderStyle: "groove",
                      }}
                      onClick={() => handleStatusClick("sent")}
                    >
                      <div className="d-flex align-items-start justify-content-between gap-3">
                        <div>
                          <p className="text-capitalize">Sent</p>
                          <span>{campaignDetailData?.total_sent || 0}</span>
                        </div>
                        <div className="credit-h-icon d-flex align-items-center justify-content-center rounded-1"></div>
                      </div>
                    </div>
                  </Col>
                  <Col xxl={2} xl={4} lg={4} md={6} xs={12}>
                    <div
                      className="p-4 rounded-3 cedit-h-card  h-100 cursor-pointer"
                      style={{
                        borderStyle: "groove",
                      }}
                      onClick={() => handleStatusClick("delivered")}
                    >
                      <div className="d-flex align-items-start justify-content-between gap-3">
                        <div>
                          <p className="text-capitalize">Delivered</p>
                          <span>
                            {campaignDetailData?.total_delivered || 0}
                          </span>
                        </div>
                        <div className="credit-h-icon d-flex align-items-center justify-content-center rounded-1"></div>
                      </div>
                    </div>
                  </Col>
                  <Col xxl={2} xl={4} lg={4} md={6} xs={12}>
                    <div
                      className="p-4 rounded-3 cedit-h-card  h-100 cursor-pointer"
                      style={{
                        borderStyle: "groove",
                      }}
                      onClick={() => handleStatusClick("seen")}
                    >
                      <div className="d-flex align-items-start justify-content-between gap-3">
                        <div>
                          <p className="text-capitalize">Seen</p>
                          <span>{campaignDetailData?.total_read || 0}</span>
                        </div>
                        <div className="credit-h-icon d-flex align-items-center justify-content-center rounded-1"></div>
                      </div>
                    </div>
                  </Col>
                  <Col xxl={2} xl={4} lg={4} md={6} xs={12}>
                    <div
                      className="p-4 rounded-3 cedit-h-card  h-100 cursor-pointer"
                      style={{
                        borderStyle: "groove",
                      }}
                      onClick={() => handleStatusClick("failed")}
                    >
                      <div className="d-flex align-items-start justify-content-between gap-3">
                        <div>
                          <p className="text-capitalize">Failed</p>
                          <span>{campaignDetailData?.total_failed || 0}</span>
                        </div>
                        <div className="credit-h-icon d-flex align-items-center justify-content-center rounded-1"></div>
                      </div>
                    </div>
                  </Col>
                  <Col xxl={2} xl={4} lg={4} md={6} xs={12}>
                    <div
                      className="p-4 rounded-3 cedit-h-card  h-100 cursor-pointer"
                      style={{
                        borderStyle: "groove",
                      }}
                      onClick={() => handleStatusClick("invalid")}
                    >
                      <div className="d-flex align-items-start justify-content-between gap-3">
                        <div>
                          <p className="text-capitalize">Invalid</p>
                          <span>{campaignDetailData?.total_invalid || 0}</span>
                        </div>
                        <div className="credit-h-icon d-flex align-items-center justify-content-center rounded-1"></div>
                      </div>
                    </div>
                  </Col>
                </Row>

                <div className="position-relative overflow-hidden rounded-2 bg-white table-tabs mb-3">
                  <Tabs
                    defaultActiveKey="guests"
                    className="pb-0 table-tabs-items"
                    id="uncontrolled-tab-example"
                    onSelect={handleTabSelect}
                  >
                    <Tab
                      eventKey="guests"
                      title={
                        <>
                          <div className="d-flex align-items-center gap-2">
                            {/* <UserList
                              size={22}
                              color="#637381"
                              weight="regular"
                            /> */}
                            <p className="mb-0">Guest</p>
                          </div>
                        </>
                      }
                    ></Tab>
                    <Tab
                      eventKey="test"
                      title={
                        <>
                          <div className="d-flex align-items-center gap-2">
                            {/* <ClockCounterClockwise
                              size={22}
                              color="#637381"
                              weight="regular"
                            /> */}
                            <p className="mb-0">Test</p>
                          </div>
                        </>
                      }
                    ></Tab>
                  </Tabs>
                </div>

                <div className="table-responsive">
                  <div className="row">
                    <Table className="text-nowrap text-start">
                      <thead className="table-light">
                        <tr>
                          <th scope="col">
                            <div className="d-flex justify-content-between align-items-center">
                              <span>Name {renderSortIcons("name")}</span>
                            </div>
                          </th>
                          <th scope="col">
                            <div className="d-flex justify-content-between align-items-center">
                              <span>
                                Mobile Number {renderSortIcons("mobile_number")}
                              </span>
                            </div>
                          </th>
                          <th scope="col">
                            <div className="d-flex justify-content-between align-items-center">
                              <span>Date</span>
                            </div>
                          </th>
                          <th scope="col">
                            <div className="d-flex justify-content-between align-items-center">
                              <span>Schedule Date</span>
                            </div>
                          </th>
                          <th scope="col">
                            <div className="d-flex justify-content-between align-items-center">
                              <span>Status</span>
                            </div>
                          </th>
                          <th scope="col">
                            <div className="d-flex justify-content-between align-items-center">
                              <span>Failed Reason</span>
                            </div>
                          </th>
                          <th scope="col">
                            <div className="d-flex justify-content-between align-items-center">
                              <span>Credits</span>
                            </div>
                          </th>
                          <th scope="col">
                            <div className="d-flex justify-content-between align-items-center">
                              <span>Delivered</span>
                            </div>
                          </th>
                          <th scope="col">
                            <div className="d-flex justify-content-between align-items-center">
                              <span>Seen</span>
                            </div>
                          </th>
                          
                        </tr>
                      </thead>
                      <tbody>
                        {guests?.length > 0 ? (
                          guests.map((guest, i) => (
                            <tr key={i} className="data_row">
                              <td className=" text-capitalize">
                                {guest?.name}
                              </td>
                              <td>{guest.mobile_number}</td>
                              <td>{moment(guest?.created_at).format("DD/MM/YYYY hh:mm A")}</td>
                              <td>{moment(guest?.scheduler_date).format("DD/MM/YYYY hh:mm A")}</td>
                              <td>{guest?.status}</td>
                              <td>{guest?.failed_reason}</td>
                                {guest.credits ? (
                                  <td>{guest.credits}</td>
                                ) : (
                                  <td>0</td>
                                )}
                              <td>
                                {moment(
                                  parseInt(guest.whatsapp_deliver) * 1000
                                ).format("DD/MM/YYYY hh:mm A")}
                              </td>
                              <td>
                                {moment(
                                  parseInt(guest.whatsapp_seen) * 1000
                                ).format("DD/MM/YYYY hh:mm A")}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="7" className="text-center">
                              No data available
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </div>
                {total > limit && (
                  <PaginationComponent
                    totalItems={total}
                    itemsPerPage={limit}
                    currentPage={currentPage + 1}
                    onPageChange={handlePageChange}
                  />
                )}
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}
