import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button, Toast, ToastContainer, Spinner } from 'react-bootstrap';
import { addCreditCustomer, fetchOrganization, fetchCreditLogBooks } from "../../../store/customerSlice";
import { fetchMyCredit } from "../../../store/walletSlice";
import { useAuth } from "../../../context/AuthContext";
import { Formik, Form, Field } from "formik";
import './style.css';
import { creditHistorySchema } from "../../../schema/validationSchemas";
import { CheckCircle } from "react-feather";

const Credit = ({ show, handleClose, customerId, itemsPerPage, currentPage, dateRange, currentCreditHistoryPage }) => {
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarVariant, setSnackbarVariant] = useState('success');
  const dispatch = useDispatch();
  const { user } = useAuth();
  const organizations = useSelector(state => state.customer.organizations.data); 

  useEffect(() => {
    if (show) {
        dispatch(fetchMyCredit(user.id));
      dispatch(fetchOrganization());
    }
  }, [show, dispatch, user.id, itemsPerPage, currentPage, dateRange]);

  const initialCreditState = {
    credits: '',
    price_per_credits: '',
    price: '',
    organizationId: '',
  };

//   const getCurrentFormattedDate = () => {
//     const today = new Date();
//     const year = today.getFullYear();
//     const month = (today.getMonth() + 1).toString().padStart(2, "0");
//     const day = today.getDate().toString().padStart(2, "0");
//     return `${year}/${month}/${day}`;
//   };

  const userCredits = useSelector(state => state.wallet.myCredit.available_credits);

  return (
    <>
      <ToastContainer position="top-end" className="p-3" style={{ zIndex: 1060 }}>
        <Toast
          onClose={() => setShowSnackbar(false)}
          show={showSnackbar}
          delay={3000}
          autohide
          bg={snackbarVariant}
        >
         <Toast.Body style={{ color: "white", display: 'flex', alignItems: 'center' }}>
          <CheckCircle size={20} color="white" style={{ marginRight: 8 }} />
          {snackbarMessage}
        </Toast.Body>
        </Toast>
      </ToastContainer>
    
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Add Credit</Modal.Title>
        </Modal.Header>
        <Formik
            initialValues={{ ...initialCreditState, available_credits: userCredits }}            
            validationSchema={creditHistorySchema}
            onSubmit={(values, { setSubmitting, resetForm, setErrors }) => {
            const formData = new FormData();

            formData.append('credits', values.credits);
            formData.append('credit_rate', values.price_per_credits);
            formData.append('price', values.price);
            formData.append('reseller_id', user.id);
            formData.append('customer_id', values.organizationId);

            formData.append('_method', 'POST');
            dispatch(addCreditCustomer(formData))
                .then((res) => {
                  if (res.payload && res.payload.success) {
                    dispatch(fetchCreditLogBooks({
                        start_date: dateRange?.startDate.format("YYYY/MM/DD"),
                        offset: currentCreditHistoryPage,
                        end_date: dateRange?.endDate.format("YYYY/MM/DD"),
                      }))
                    setSnackbarMessage(res.payload.message);
                    setSnackbarVariant("success");
                    setShowSnackbar(true);
                    resetForm();
                    handleClose();
                  } else {
                    setSnackbarMessage(res.payload.message);
                    setErrors(res.payload.data);
                    setSnackbarVariant('danger');
                    setShowSnackbar(true);
                  }
                })
                .catch((error) => {
                  setSnackbarMessage('Failed to add credit: ' + error.message);
                  setSnackbarVariant('danger');
                  setShowSnackbar(true);
                })
                .finally(() => {
                  setSubmitting(false);
                })
          }}
        >
          {({ values, setFieldValue, isSubmitting, errors, touched }) => (
            <Form encType="multipart/form-data">
              <Modal.Body>
              <div className="modal-body">
              <div className="d-flex align-items-center gap-2 credit-visible mb-3">
                      <span><strong>Available Credits: </strong></span>
                      <p className="mb-0">
                        {userCredits}
                      </p>
                    </div>

                <label className="form-label"><b>Organization</b></label>
                <Field as="select" name="organizationId" className="form-control">
                    <option value="">Select Organization</option>
                    {organizations?.map(org => (
                      <option key={org.id} value={org.id}>{org.organization_name}</option>
                    ))}
                </Field>
                {errors.organizationId && touched.organizationId && <div className="text-danger">{errors.organizationId}</div>}

                <label className="form-label mt-5"><b>Credits</b></label>
                <Field
                  name="credits"
                  type="number"
                  placeholder=" "
                  className={`form-control ${errors.credits && touched.credits ? "is-invalid" : ""}`}
                  min="0"
                  onChange={e => {
                    setFieldValue('credits', e.target.value);
                    if (values.price_per_credits) {
                      setFieldValue('price', e.target.value * values.price_per_credits);
                    }
                  }}
                />
                {errors.credits && touched.credits && <div className="text-danger">{errors.credits}</div>}
                <br/>
                <label className="form-label"><b>Price per credit</b></label>
                <div className="rupee-input-container">
                  <span className="rupee-symbol">₹</span>
                  <Field 
                    name="price_per_credits"
                    type="number"
                    step="0.01"
                    placeholder=" "
                    className={`form-control form-input ${errors.price_per_credits && touched.price_per_credits ? "is-invalid" : ""}`}
                    min="0"
                    onChange={e => {
                      setFieldValue('price_per_credits', e.target.value);
                      if (values.credits) {
                        setFieldValue('price', e.target.value * values.credits);
                      }
                    }}
                  />
                </div>
                {errors.price_per_credits && touched.price_per_credits && <div className="text-danger">{errors.price_per_credits}</div>}
                <div className="my-4">
                    <strong className="pe-2">Total Credit Price: </strong>
                    {values.credits && values.price_per_credits ? "₹"  +  (values.credits * values.price_per_credits).toFixed(2) : '₹0'}
                </div>
              </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
                <Button type="submit" variant="primary" disabled={isSubmitting}>
                  {isSubmitting ? (
                    <>
                      <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                      Adding...
                    </>
                  ) : (
                    "Add"
                  )}
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
}

export default Credit;