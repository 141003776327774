import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button, Toast, ToastContainer, Spinner } from 'react-bootstrap';
import {
    addCreditRequestAction,
  } from "../../../store//customerSlice";
import { useAuth } from "../../../context/AuthContext";
import { Formik, Form, Field } from "formik";
import { creditSchema } from "../../../schema/validationSchemas";
import { CheckCircle } from "react-feather";
import { fetchMyCredit } from "../../../store/walletSlice";

const Edit = ({ show, handleModalClose, requestedCreditId, handleClose }) => {
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarVariant, setSnackbarVariant] = useState('success');
  const dispatch = useDispatch();
  const { user } = useAuth();
  const [requestedCredit, setRequestedCredit] = useState({
    credits:"",
  });

  useEffect(() => {
    if (show) {
        dispatch(fetchMyCredit(user.id));

    }
  }, [show, dispatch, user.id]);

  const initialCreditState = useMemo(() => ({
    credits: "",
    price_per_credits: "",
    price: "",
  }), []);

  const requestedCreditDetail = useSelector(( state ) =>
    state.customer?.requestedCredits?.data?.find(
      (o) => o.id === requestedCreditId
    )
  );

  useEffect(() => {
    if (show && requestedCreditDetail) {
      setRequestedCredit({
        credits: requestedCreditDetail.credits || "",
        price_per_credits: requestedCreditDetail.price_per_credits || "",
        price: requestedCreditDetail.price || "",
      });
    } else if (!requestedCreditDetail) {
      setRequestedCredit(initialCreditState);
    }
  }, [show, requestedCreditDetail, initialCreditState]);

  const handleSubmit = (accept) => (values, { setSubmitting, resetForm, setErrors }) => {
    const formData = new FormData();

    formData.append("credit_request_id", requestedCreditId);
    formData.append("action", accept);
    formData.append('credits', values.credits);
    formData.append("credit_rate", values?.price_per_credits || 0);
    formData.append('price', values.price);
    formData.append('customer_id', requestedCreditDetail.customer_id);

    formData.append('_method', 'POST');
    dispatch(addCreditRequestAction(formData))
      .then((res) => {
        if (res.payload && res.payload.success) {
          console.log("if", res.payload);
          handleModalClose();
          handleClose();
          setSnackbarMessage(res.payload.message);
          setSnackbarVariant("success");
          setShowSnackbar(true);
          resetForm();
          handleClose();
        } else {
          console.log("else", res.payload.message);
          setSnackbarMessage(res.payload.message);
          setErrors(res.payload.data);
          setSnackbarVariant('danger');
          setShowSnackbar(true);
        }
      })
      .catch((error) => {
        console.log("else", error.message);
        setSnackbarMessage('Failed to add credit: ' + error.message);
        setSnackbarVariant('danger');
        setShowSnackbar(true);
      })
      .finally(() => {
        setSubmitting(false);
      })
  };

  const userCredits = useSelector(state => state.wallet.myCredit.available_credits);

  return (
    <>
      <ToastContainer position="top-end" className="p-3" style={{ zIndex: 1060 }}>
        <Toast
          onClose={() => setShowSnackbar(false)}
          show={showSnackbar}
          delay={3000}
          autohide
          bg={snackbarVariant}
        >
         <Toast.Body style={{ color: "white", display: 'flex', alignItems: 'center' }}>
          <CheckCircle size={20} color="white" style={{ marginRight: 8 }} />
          {snackbarMessage}
        </Toast.Body>
        </Toast>
      </ToastContainer>
    
      <Modal show={show} onHide={handleClose} centered >
        <Modal.Header closeButton>
          <Modal.Title>Add Credit</Modal.Title>
        </Modal.Header>
        <Formik
            initialValues={requestedCredit}
            validationSchema={creditSchema}
            enableReinitialize
            onSubmit={handleSubmit(true)}
        >
          {({ values, setFieldValue, isSubmitting, errors, touched, setSubmitting, resetForm, setErrors, isValid }) => (
            <Form encType="multipart/form-data">
              <Modal.Body>
              <div className="modal-body">
              <div className="d-flex align-items-center gap-2 credit-visible mb-3">
                      <span><strong>Available Credits: </strong></span>
                      <p className="mb-0">
                        {userCredits}
                      </p>
                    </div>
                <label className="form-label"><b>Credits</b></label>
                <Field
                        name="credits"
                        type="number"
                        placeholder="Enter Credits"
                        className={`form-control shadow-none ${
                          errors.credits && touched.credits ? "is-invalid" : ""
                        }`}
                        min="0"
                        onChange={(e) => {
                          setFieldValue("credits", e.target.value);
                          if (values.price_per_credits) {
                            setFieldValue(
                              "price",
                              e.target.value * values.price_per_credits
                            );
                          }
                        }}
                      />
                      {errors.credits && touched.credits && (
                        <div className="invalid-feedback ms-1 d-block">
                          {errors.credits}
                        </div>
                      )}
                <br/>
                <label className="form-label"><b>Price per credit</b></label>
                <div className="rupee-input-container">
                  <span className="rupee-symbol">₹</span>
                  <Field
                          name="price_per_credits"
                          type="number"
                          step="0.01"
                          placeholder="Enter Price Per Credit"
                          className={`form-control shadow-none ${
                            errors.price_per_credits && touched.price_per_credits ? "is-invalid" : ""
                          }`}
                          min="0"
                          onChange={(e) => {
                            // Convert string to float and update Formik's state
                            const convertedValue = parseFloat(e.target.value);
                            setFieldValue("price_per_credits", convertedValue);
                            // Update the total price calculation
                            if (values.credits) {
                              setFieldValue(
                                "price",
                                convertedValue * values.credits
                              );
                            }
                          }}
                        />
                </div>
                {errors.price_per_credits && touched.price_per_credits && <div className="text-danger">{errors.price_per_credits}</div>}
                <div className="my-4">
                    <strong className="pe-2">Total Credit Price: </strong>
                    {values.credits && values.price_per_credits ? "₹"  +  (values.credits * values.price_per_credits).toFixed(2) : '₹0'}
                </div>
              </div>
              </Modal.Body>
              <Modal.Footer>
              <Button variant="danger" onClick={() => handleSubmit(false)(values, { setSubmitting, resetForm, setErrors })}
                  disabled={!!values.price_per_credits}>
                  Reject
                </Button>                
                
                <Button type="submit" variant="primary" disabled={isSubmitting}>
                  {isSubmitting ? (
                    <>
                      <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                      Adding...
                    </>
                  ) : (
                    "Accept"
                  )}
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
}

export default Edit;