import React, { useEffect, useState, useCallback } from "react";
import "./style.css";
import { Col, 
  // Dropdown, 
  Modal, Tab, Table, Tabs, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
// import { MoreVertical } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchClientDetail,
  fetchCampaignDetails,
} from "../../../store/customerSlice";
import PaginationComponent from "../pagination/pagination";
import CampaignDetailModal from "./CampaignDetail";
import { createSelector } from "reselect";
import { ArrowUp, ArrowDown } from "react-feather";
import { useLoader } from "../../../context/LoaderContext";
import moment from "moment";

export default function ClientDetailModal({ show, event, handleClose }) {
  const { setLoading } = useLoader();
  const dispatch = useDispatch();
  const [currentCampaign, setCurrentCampaign] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const limit = 10;
  const [showCampaignDetailModal, setShowCampaignDetailModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortKey, setSortKey] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");

  const [activeTab, setActiveTab] = useState("all");

  const clientDetail = useSelector((state) => state.customer.currentClient);

  const selectCampaignState = (state) => state.customer.campaigns;

  const selectCampaignData = createSelector(
    [selectCampaignState],
    (customer) => {
      return {
        total: customer.total,
        campaigns: customer.data,
      };
    }
  );

  const { total, campaigns } = useSelector(selectCampaignData);

  // const firstRender = useRef(true);

  // useEffect(() => {
  //   if (firstRender.current) {
  //     firstRender.current = false;
  //   } else {
  //     dispatch(fetchClientDetail(event.id));
  //     dispatch(
  //       fetchCampaignDetails({
  //         id: event.id,
  //         offset: currentPage,
  //         limit,
  //         search: searchTerm,
  //         sort_by_key: sortKey,
  //         sort_by_value: sortOrder
  //       })
  //     );
  //   }
  // }, [dispatch, event, id, currentPage, limit, searchTerm, sortKey, sortOrder]);

  const getCampaignList = useCallback(() => {
    setLoading(true);
    Promise.all([
      dispatch(fetchClientDetail(event.id)),
      dispatch(
        fetchCampaignDetails({
          id: event.id,
          offset: currentPage,
          limit,
          search: searchTerm,
          sort_by_key: sortKey,
          sort_by_value: sortOrder,
          status: activeTab,
        })
      ),
    ])
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    dispatch,
    event,
    currentPage,
    limit,
    searchTerm,
    sortKey,
    sortOrder,
    setLoading,
    activeTab,
  ]);

  useEffect(() => {
    getCampaignList();
  }, [getCampaignList]);

  const handleRowClick = (campaign) => {
    setCurrentPage(0);
    setSearchTerm("");
    setCurrentCampaign(campaign);
    setShowCampaignDetailModal(true);
  };

  // const ActionMenu = ({ campaign }) => {
  //   return (
  //     <Dropdown drop="up" className=" position-static">
  //       <Dropdown.Toggle as={CustomToggle}>
  //         <MoreVertical size="15px" className="text-muted" />
  //       </Dropdown.Toggle>
  //       <Dropdown.Menu align={"end"}>
  //         <Dropdown.Item eventKey="1" onClick={() => handleRowClick(campaign)}>
  //           View
  //         </Dropdown.Item>
  //       </Dropdown.Menu>
  //     </Dropdown>
  //   );
  // };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onClick(e);
      }}
      className="text-muted text-primary-hover"
    >
      {children}
    </Link>
  ));

  CustomToggle.displayName = "CustomToggle";

  const handlePageChange = (newPageNumber) => {
    setCurrentPage(newPageNumber - 1);
  };

  const handleSort = (key) => {
    const isAsc = sortKey === key && sortOrder === "asc";
    setSortKey(key);
    setSortOrder(isAsc ? "desc" : "asc");
  };

  const renderSortIcons = (key) => {
    const isActive = sortKey === key;
    return (
      <>
        <ArrowUp
          size={16}
          onClick={() => handleSort(key, "asc")}
          style={{
            cursor: "pointer",
            color: isActive && sortOrder === "asc" ? "black" : "#ccc",
          }}
        />
        <ArrowDown
          size={16}
          onClick={() => handleSort(key, "desc")}
          style={{
            cursor: "pointer",
            color: isActive && sortOrder === "desc" ? "black" : "#ccc",
          }}
        />
      </>
    );
  };

  const handleCloseModal = () => {
    setSearchTerm("");
    setCurrentPage(0);
    handleClose();
    setLoading(false);
    setActiveTab("all");
  };

  const handleTabSelect = (key) => {
    setActiveTab(key);

    switch (key) {
      case "all":
        // setSearchTerm("");
        setCurrentPage(0);
        // setCurrentCreditPage(0);
        // fetchCustomerDetailsTab();
        break;

      case "pending":
        // setCurrentCreditPage(0);
        // fetchCreditDetailsTab();
        break;

      case "scheduled":
        // setCurrentCreditPage(0);
        // fetchCreditHistoryTab();
        break;

      case "drafted":
        // setCurrentCreditPage(0);
        // fetchCreditHistoryTab();
        break;

      case "in-process":
        // setCurrentCreditPage(0);
        // fetchCreditHistoryTab();
        break;

      case "completed":
        // setCurrentCreditPage(0);
        // fetchCreditHistoryTab();
        break;

      case "failed":
        // setCurrentCreditPage(0);
        // fetchCreditHistoryTab();
        break;

      default:
        break;
    }
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleCloseModal}
        fullscreen={true}
        className="modal-fullscreen bg-lightgray"
      >
        <Modal.Header closeButton>
          <Modal.Title>Customer Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="row">
          <div className="col-4">
            <Col xl={12} lg={12} md={12} xs={12} className="mt-6">
              <div className="customer_details">
                <div className="personal_details pb-3">
                  <h5 className="">Customer Information</h5>
                  <div className="border-1 separtor line_small"></div>
                  <div className="pt-3">
                    <div className="d-flex detail_person pb-2">
                      <span className="user_name">Name </span>
                      <span className="part_name">:</span>
                      <span className="detail_name">{clientDetail.name}</span>
                    </div>
                    <div className="d-flex detail_person pb-2">
                      <span className="user_name">Email </span>
                      <span className="part_name">:</span>
                      <span className="detail_name">{clientDetail?.email}</span>
                    </div>
                    <div className="d-flex detail_person pb-2">
                      <span className="user_name">Mobile No.</span>
                      <span className="part_name">:</span>
                      <span className="detail_name">
                        {clientDetail?.contact_number}
                      </span>
                    </div>
                    <div className="d-flex detail_person pb-2">
                      <span className="user_name">Address</span>
                      <span className="part_name">:</span>
                      <span className="detail_name">
                        {clientDetail?.address}
                      </span>
                    </div>
                    <div className="d-flex detail_person pb-2">
                      <span className="user_name">Created on</span>
                      <span className="part_name fs-16">:</span>
                      <span className="detail_name fs-16">
                        {moment(clientDetail.created_at).format("DD/MM/YYYY hh:mm A")}
                      </span>
                    </div>
                    <div className="d-flex detail_person pb-2">
                      <span className="user_name">Updated on</span>
                      <span className="part_name fs-16">:</span>
                      <span className="detail_name fs-16">
                        {moment(clientDetail.updated_at).format("DD/MM/YYYY hh:mm A")}
                      </span>
                    </div>
                  </div>
                </div>
                </div>
              </Col>

              <Row className="mt-4">
                <Col xl={12} lg={12} md={12} xs={12}>
                  <div className="bg-white rounded-3 p-4 h-100">
                    <div>
                      <div className="d-flex align-items-center gap-2 fw-semibold sub-heading">
                        <span className="rounded-circle"></span>
                        <h4 className="">WhatsApp Details</h4>
                      </div>
                      <div className="d-flex flex-column mt-3 reseller-de-card">
                        <div className="d-flex align-items-center gap-2">
                          <div className="d-flex align-items-center re-title ">
                            {/* <Phone size={20} color="#212b36" weight="regular" /> */}
                            <p className="mb-0 text-capitalize white-space-nowrap">
                              Status :
                            </p>
                          </div>
                          <p
                          className={`mb-0 ${
                            event?.whatsapp_client?.is_active
                              ? "text-success"
                              : "text-danger"
                          }`}
                        > 
                          {event?.whatsapp_client?.is_active
                            ? "Connected"
                            : "Not Connected"}
                        </p>
                        </div>

                        <div className="d-flex align-items-center gap-2">
                          <div className="d-flex align-items-center re-title ">
                            {/* <Phone size={20} color="#212b36" weight="regular" /> */}
                            <p className="mb-0 text-capitalize white-space-nowrap">
                              Mobile No. :
                            </p>
                          </div>
                          <p className="mb-0">
                          {event?.whatsapp_client?.is_active
                            ? event?.whatsapp_client?.mobile_number
                            : "-"}                          </p>
                        </div>


                        <div className="d-flex align-items-center gap-2">
                          <div className="d-flex align-items-center re-title ">
                            {/* <Phone size={20} color="#212b36" weight="regular" /> */}
                            {event?.whatsapp_client?.is_active ? (
                            <p className="mb-0 text-capitalize white-space-nowrap">
                              Connected At :
                            </p>
                          ) : (
                            <p className="mb-0 text-capitalize white-space-nowrap">
                              Disconnected At :
                            </p>
                          )}
                          </div>
                          <p className="mb-0">
                          {event?.whatsapp_client?.last_sync_at
                            ? moment
                                .unix(event?.whatsapp_client?.last_sync_at)
                                .format("DD/MM/YYYY hh:mm A")
                            : "-"}                      
                            </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>

            <div className="col-8">
              <Col xl={12} lg={12} md={12} xs={12} className="mt-6">
                <div className="tablecontainer">
                  <div className="input_head position-relative d-flex align-items-center justify-content-between">
                    <h3 className="float-start m-5">Campaign List</h3>
                    <input
                      type="search"
                      placeholder="Search"
                      className="searchbar float-end"
                      style={{ cursor: 'pointer' }}
                      value={searchTerm}
                      onChange={(e) => {
                        setSearchTerm(e.target.value);
                        setCurrentPage(0);
                      }}
                    />
                  </div>
                  <div className="position-relative overflow-hidden rounded-2 bg-white table-tabs mb-3">
                    <Tabs
                      defaultActiveKey="all"
                      className="pb-0 table-tabs-items"
                      id="uncontrolled-tab-example"
                      onSelect={handleTabSelect}
                    >
                      <Tab
                        eventKey="all"
                        title={
                          <>
                            <div className="d-flex align-items-center gap-2">
                              {/* <UserList
                              size={22}
                              color="#637381"
                              weight="regular"
                            /> */}
                              <p className="mb-0">All</p>
                            </div>
                          </>
                        }
                      ></Tab>
                      <Tab
                        eventKey="pending"
                        title={
                          <>
                            <div className="d-flex align-items-center gap-2">
                              {/* <ClockCounterClockwise
                              size={22}
                              color="#637381"
                              weight="regular"
                            /> */}
                              <p className="mb-0">Pending</p>
                            </div>
                          </>
                        }
                      ></Tab>
                      <Tab
                        eventKey="scheduled"
                        title={
                          <>
                            <div className="d-flex align-items-center gap-2">
                              {/* <Cardholder
                              size={22}
                              color="#637381"
                              weight="regular"
                            /> */}
                              <p className="mb-0">Scheduled</p>
                            </div>
                          </>
                        }
                      ></Tab>
                      <Tab
                        eventKey="drafted"
                        title={
                          <>
                            <div className="d-flex align-items-center gap-2">
                              {/* <Cardholder
                              size={22}
                              color="#637381"
                              weight="regular"
                            /> */}
                              <p className="mb-0">Drafted</p>
                            </div>
                          </>
                        }
                      ></Tab>
                      <Tab
                        eventKey="in-process"
                        title={
                          <>
                            <div className="d-flex align-items-center gap-2">
                              {/* <Cardholder
                              size={22}
                              color="#637381"
                              weight="regular"
                            /> */}
                              <p className="mb-0">In-process</p>
                            </div>
                          </>
                        }
                      ></Tab>
                      <Tab
                        eventKey="completed"
                        title={
                          <>
                            <div className="d-flex align-items-center gap-2">
                              {/* <Cardholder
                              size={22}
                              color="#637381"
                              weight="regular"
                            /> */}
                              <p className="mb-0">Completed</p>
                            </div>
                          </>
                        }
                      ></Tab>
                      <Tab
                        eventKey="failed"
                        title={
                          <>
                            <div className="d-flex align-items-center gap-2">
                              {/* <Cardholder
                              size={22}
                              color="#637381"
                              weight="regular"
                            /> */}
                              <p className="mb-0">Failed</p>
                            </div>
                          </>
                        }
                      ></Tab>
                    </Tabs>
                  </div>
                  <div className="table-responsive">
                    <div className="row">
                      <Table className="text-nowrap text-start ">
                        <thead className="table-light">
                          <tr>
                            <th scope="col">
                              <div className="d-flex justify-content-between align-items-center">
                                <span>Name {renderSortIcons("name")}</span>
                              </div>
                            </th>
                            <th scope="col">
                              <div className="d-flex justify-content-between align-items-center">
                                <span>Send Type</span>
                              </div>
                            </th>
                            {/* <th scope="col">
                          <div className="d-flex justify-content-between align-items-center">
                            <span>Message</span>
                          </div>
                        </th> */}
                            {/* <th scope="col">
                          <div className="d-flex justify-content-between align-items-center">
                            <span>Mobile Number</span>
                          </div>
                        </th> */}
                            <th scope="col">
                              <div className="d-flex justify-content-between align-items-center">
                                <span>Status</span>
                              </div>
                            </th>
                            <th scope="col">
                              <div className="d-flex justify-content-between align-items-center">
                                <span>Recipients</span>
                              </div>
                            </th>
                            <th scope="col">
                              <div className="d-flex justify-content-between align-items-center">
                                <span>Credits Used</span>
                              </div>
                            </th>
                            <th scope="col">
                              <div className="d-flex justify-content-between align-items-center">
                                <span>Created On</span>
                              </div>
                            </th>

                            {/* <th scope="col">
                              <div className="d-flex justify-content-between align-items-center">
                                <span>Action</span>
                              </div>
                            </th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {campaigns?.length > 0 ? (
                            campaigns.map((campaign, i) => (
                              <tr key={i}
                                className="table-row"
                               onClick={() => handleRowClick(campaign)}
                              >
                                <td>{campaign?.name}</td>
                                <td>{campaign?.template_type === "text" ? "Only Message"
                                  : "With Invitation Card"}</td>
                                <td>{campaign?.status}</td>
                                <td>{campaign?.client_guests_not_test_count}</td>
                                <td>{campaign?.total_credits || 0}</td>
                                <td>{moment(campaign.created_at).format("DD/MM/YYYY hh:mm A")}</td>
                                {/* <td>{campaign?.message}</td> */}
                                {/* <td>
                              {
                                campaign?.client_event?.whatsapp_client
                                  ?.mobile_number
                              }
                            </td> */}
                                {/* <td>
                                  <ActionMenu campaign={campaign} />
                                </td> */}
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="7" className="text-center">
                                No data available
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                  {total > limit && (
                    <PaginationComponent
                      totalItems={total}
                      itemsPerPage={limit}
                      currentPage={currentPage + 1}
                      onPageChange={handlePageChange}
                    />
                  )}
                </div>
              </Col>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {currentCampaign && (
        <CampaignDetailModal
          show={showCampaignDetailModal}
          handleClose={() => setShowCampaignDetailModal(false)}
          campaign={currentCampaign}
        />
      )}
    </>
  );
}
