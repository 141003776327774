import React from 'react'

export const ProfileIcon = () => {
    return (
        <>
            <svg width="20" height="20" viewBox="0 0 14 19" fill="none" xmlns="http://www.w3.org/2000/svg" className="me-2">
                <path opacity="0.4" d="M7.13407 9.05841C7.05074 9.05008 6.95074 9.05008 6.85907 9.05841C4.87578 8.99175 3.30078 7.36675 3.30078 5.36675C3.30078 3.32508 4.95078 1.66675 7.00074 1.66675C9.04241 1.66675 10.7007 3.32508 10.7007 5.36675C10.6924 7.36675 9.11741 8.99175 7.13407 9.05841Z" stroke="#161C24" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M2.96758 12.1335C0.950911 13.4835 0.950911 15.6835 2.96758 17.0252C5.25924 18.5585 9.01758 18.5585 11.3092 17.0252C13.3259 15.6752 13.3259 13.4752 11.3092 12.1335C9.02591 10.6085 5.26758 10.6085 2.96758 12.1335Z" stroke="#161C24" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </>
    )
}
