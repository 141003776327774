import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosapi from "../utilities/axiosApi";


export const fetchAllOrganizationTypes = createAsyncThunk(
    "organizationType/fetchType",
    async ()=>{
        try {
            const response = await axiosapi.get("/api/reseller/all-organization-types");
            return response.data.data;  
          } catch (error) {
            console.error("Error fetching organization types:", error);
          }
    }
  )


const organizationTypeSlice = createSlice({
    name:"organizationType",
    initialState:{
        allOrganizationTypes:[],
    },
    reducers:{},
    extraReducers: (builder) => {
        builder
          .addCase(fetchAllOrganizationTypes.fulfilled, (state, action) => {
            state.allOrganizationTypes = action.payload;
          })
        }
    })



export default organizationTypeSlice.reducer;    